import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { db } from "../../firebase";
import { collection, addDoc, getDocs } from "firebase/firestore";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDButton from "../../ui-components/MDButton";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import DataTable_Generic from "../Tables/DataTable_Generic";
import { useDBManager } from "../../contexts/DBManager_Context";
import { useAuth } from "../../contexts/AuthContext";
import inventoryDashDataTemplate from "../data/inventoryDashDataTemplate";
import HandleLogout from "../HandleLogout";

export default function Inventory_Dashboard() {
  const { currentUser } = useAuth();
  const { updateFields } = useDBManager();
  const { state, fetchCollection } = useDBManager();
  const [availableMachineObjects, setAvailableMachineObjects] = useState([]);
  let { columns, rows } = inventoryDashDataTemplate(
    availableMachineObjects,
    state.products
  );

  useEffect(() => {
    updateFields(["regions", "machines", "warehouses", "products"]);
    fetchInventory();
  }, []);

  useEffect(() => {
    fetchInventory();
  }, [state.machines]);

  async function fetchInventory() {
    let x = [];
    const requests = state.machines.map(async (machine) => {
      if (machine.active) {
        return fetchCollection(
          collection(db, "Machines", machine.id, "plans")
        ).then((data) => {
          let f = assembleSMLabels(data[0]);
          f.name = machine.name;
          f.number = machine.number;
          state.regions.forEach((region, rndex) => {
            if (region.name == machine.region.name) {
              f.region = region;
            }
          });
          x.push(f);
        });
      }
    });
    return Promise.all(requests).then((values) => {
      x.sort((a, b) => (a.region.name > b.region.name ? 1 : -1));
      setAvailableMachineObjects((prev) => [...x]);
    });
  }

  function assembleSMLabels(p) {
    let h = {
      products: [],
      mapping: [],
      maxQuantities: [],
      totalMapping: 0,
      totalMax: 0,
    };

    p.shelves?.forEach((item, index) => {
      if (!h.products.includes(item.productCode)) {
        h.products.push(item.productCode);
        h.mapping.push(item.currentStock);
        h.maxQuantities.push(item.maxStock);
        h.totalMapping = h.totalMapping + item.currentStock;
        h.totalMax = h.totalMax + item.maxStock;
      } else {
        let x = h.mapping[h.products.indexOf(item.productCode)];
        x = x + item.currentStock;
        h.mapping[h.products.indexOf(item.productCode)] = x;

        let y = h.maxQuantities[h.products.indexOf(item.productCode)];
        y = y + item.maxStock;
        h.maxQuantities[h.products.indexOf(item.productCode)] = y;

        h.totalMapping = h.totalMapping + item.currentStock;
        h.totalMax = h.totalMax + item.maxStock;
      }
    });

    h.products.forEach((item, index) => {
      state.products.forEach((entry, kndex) => {
        if (item == entry.bud) {
          h.products[index] = entry.name;
        }
      });
    });

    let list = []; //Merges doc data and ids, sorts them, then separates them back out into different arrays
    for (let j = 0; j < h.products.length; j++) {
      list.push({
        localProducts: h.products[j],
        localMapping: h.mapping[j],
        localMax: h.maxQuantities[j],
      });
    }
    list.sort(function (a, b) {
      return a.localProducts > b.localProducts
        ? -1
        : a.localProducts == b.localProducts
        ? 0
        : 1;
    });
    for (var k = 0; k < list.length; k++) {
      h.products[k] = list[k].localProducts;
      h.mapping[k] = list[k].localMapping;
      h.maxQuantities[k] = list[k].localMax;
    }

    return h;
  }

  return (
    <>
      {currentUser ? (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox pt={6} pb={5}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Machine Inventories
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable_Generic
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          <Footer />
        </DashboardLayout>
      ) : (
        <HandleLogout />
      )}
    </>
  );
}
