import React, { useState, useEffect } from "react";
import {
  Select,
  Grid,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  DialogTitle,
  DialogContentText,
  Fab
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  addDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import MDTypography from "../../ui-components/MDTypography";
import MDAlert from "../../ui-components/MDAlert";
import MDBox from "../../ui-components/MDBox";
import MDInput from "../../ui-components/MDInput";
import MDButton from "../../ui-components/MDButton";
import DefaultInfoCard from "../Cards/InfoCards/DefaultInfoCard";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { useDBManager } from "../../contexts/DBManager_Context";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import { Add } from "@mui/icons-material";

export default function Admin_Run_Overview() {
  const { docID } = useParams();
  let date = new Date();
  const [month, setMonth] = useState(String(date.getMonth() + 1));
  const [day, setDay] = useState(String(date.getDate()));
  const [year] = useState(String(date.getFullYear()));

  const { updateFields, state, fetchCollection, fetchDocument, renderWriteOffDialog, handleWriteOffClickOpen } = useDBManager();

  let todaysDate = year + "-" + month + "-" + day;
  const [runDoc, setRunDoc] = useState({});
  const [runData, setRunData] = useState([]);
  const navigate = useNavigate();
  const imageStyle = { width: "40px", height: "40px" };

  const [warehouseDoc, setWarehouseDoc] = useState({});
  const [warehouseProducts, setWarehouseProducts] = useState([]);

  const [successOpen, setSuccessOpen] = React.useState(false);
  const handleSuccessClickOpen = () => {
    setSuccessOpen(true);
  };
  const handleSuccessClose = () => {
    setSuccessOpen(false);
  };

  useEffect(() => {
    updateFields(["users", "machines", "products", "warehouses", "reasons", "availableLocations"]);

    let t_month = "";
    let t_day = "";
    if (parseInt(month) < 10) {
      t_month = "0" + String(month);
      setMonth(t_month);
    }
    if (parseInt(day) < 10) {
      t_day = "0" + String(day);
      setDay(t_day);
    }
  }, []);

  useEffect(() => {
    fetchAllRunData();
  }, [state]);

  function fetchAllRunData() {
    fetchDocument(doc(db, "Run Data", docID)).then((data1) => {
      setRunDoc(data1);
      fetchCollection(collection(db, "Run Data", docID, "machines")).then(
        (data2) => {
          setRunData(data2);
          fetchCollection(
            collection(db, "Warehouses", data1.warehouse.id, "products")
          ).then((data3) => {
            setWarehouseProducts(data3);
            fetchDocument(doc(db, "Warehouses", data1.warehouse.id)).then(
              (data4) => {
                setWarehouseDoc(data4);
              }
            );
          });
        }
      );
    });
  }

  function matchImages(entry) {
    let x = "";
    state.products.forEach((item, index) => {
      if (entry.name == item.name)
        if (
          item.name == entry.name ||
          item.altName1 == entry.name ||
          item.altName2 == entry.name ||
          item.altName3 == entry.name
        ) {
          x = item.url;
        }
    });
    return x;
  }

  async function handleNavigateToMachine(e, machID) {
    e.preventDefault();
    navigate("/admin-run-restock/" + docID + "-" + machID);
  }

  async function handleContinueRun(e) {
    e.preventDefault();
    let done = true;
    runData.forEach((item, index) => {
      if (!item.filled) {
        done = false;
      }
    });
    if (done) {
      handleSuccessClickOpen();
    } else {
      alert("All machines have not been finished");
    }
  }

  async function handleSubmitRun() {
    let x = runData;
    let collRef = collection(
      db,
      "Run Records",
      runDoc.region,
      year,
      month,
      day
    );

    let leftovers = runDoc.driverInventory.filter((item) => item.quantity > 0);
    let overstock = runDoc.driverInventory.filter((item) => item.quantity < 0);
    await addDoc(collRef, {
      author: runDoc.author,
      driver: runDoc.driver,
      region: runDoc.region,
      stage: "complete",
      warehouse: runDoc.warehouse,
      leftoverProducts: leftovers,
      overstock: overstock,
      startTime: runDoc.startTime,
      endDate: new Date(),
      endTime: new Date().toLocaleTimeString(),
      runBeginDate: runDoc.runBeginDate,
      runBeginTime: runDoc.runBeginTime,
    }).then((response) => {
      let id = response.id;
      let newCollRef = collection(
        db,
        "Run Records",
        runDoc.region,
        year,
        month,
        day,
        id,
        "machines"
      );
      x.map(async (item, index) => {
        await addDoc(newCollRef, item);
      });
    });

    runData.map(async (item, index) => {
      deleteDoc(doc(db, "Run Data", docID, "machines", item.id));
    });
    deleteDoc(doc(db, "Run Data", docID));

    let w = warehouseProducts;
    w.forEach((item, index) => {
      runDoc.driverInventory.forEach((entry, endex) => {
        if (item.name == entry.name) {
          w[index].quantity =
            parseInt(w[index].quantity) + parseInt(entry.quantity);
        }
      });
    });

    w.forEach((item, index) => {
      let wDocRef = doc(db, "Warehouses", warehouseDoc.id, "products", item.id);
      updateDoc(wDocRef, item);
    });

    navigate("/admin-runs");
  }

  function renderRestockCard(item) {
    if (item.filled) {
      return (
        <>
          <DefaultInfoCard
            icon={<CheckBox />}
            title={item.machineID.name}
            color="success"
            value=""
          />
        </>
      );
    } else {
      return (
        <>
          <DefaultInfoCard
            icon={<CheckBoxOutlineBlank />}
            title={item.machineID.name}
            value={
              <MDButton
                onClick={(e) => handleNavigateToMachine(e, item.machineID.id)}
              >
                Fill
              </MDButton>
            }
          />
        </>
      );
    }
  }

  function renderSubmitDialog() {
    return (
      <>
        <div>
          <Dialog
            open={successOpen}
            onClose={handleSuccessClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Submit Run"}</DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{
                  fontWeight: "bold",
                  color: "#42424a",
                }}
                align="center"
                id="alert-dialog-description"
              >
                Leftover Inventory
              </DialogContentText>
              {runDoc.driverInventory?.map((item, index) => {
                if (item.quantity > 0) {
                  return (
                    <DialogContentText align="center">
                      {item.name} - {item.quantity}
                    </DialogContentText>
                  );
                }
              })}
              <DialogContentText
                sx={{
                  fontWeight: "bold",
                  color: "#42424a",
                }}
                align="center"
                id="alert-dialog-description"
              >
                Overstock
              </DialogContentText>
              {runDoc.driverInventory?.map((item, index) => {
                if (item.quantity < 0) {
                  return (
                    <DialogContentText align="center">
                      {item.name} - ({item.quantity})
                    </DialogContentText>
                  );
                }
              })}
              <DialogContentText
                align="center"
                sx={{
                  fontWeight: "bold",
                  color: "#42424a",
                }}
                id="alert-dialog-description"
              >
                Are you ready to submit this run? Leftover inventory will be
                allocated back to {runDoc.warehouse?.name}. Overstock inventory
                will be counted as taken from {runDoc.warehouse?.name}. You will
                not be able to make changes after you submit.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleSuccessClose}>No</MDButton>
              <MDButton onClick={handleSubmitRun} autoFocus>
                Yes
              </MDButton>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container spacing={2} justifyContent="center">
            {runData.map((item, index) => (
              <>
                <Grid container justifyContent="center">
                  <Grid item xs={6}>
                    {renderRestockCard(item)}
                  </Grid>
                </Grid>
              </>
            ))}
            <MDButton
              justifyContent="center"
              variant="text"
              onClick={(e) => handleContinueRun(e)}
            >
              <MDAlert color="error">
                <MDTypography
                  justifycontent="center"
                  variant="h6"
                  color="white"
                >
                  Submit Run
                </MDTypography>
              </MDAlert>
            </MDButton>
          </Grid>
        </MDBox>
        <Fab
          color="info"
          aria-label="add"
          sx={{ position: "absolute", bottom: 20, right: 20 }}
          onClick={handleWriteOffClickOpen}
        >
          <Add />
        </Fab>
        {renderWriteOffDialog()}
      </DashboardLayout>
      {renderSubmitDialog()}
    </>
  );
}
