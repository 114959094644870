import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Fab,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import BasicLayout from "../../layouts/BasicLayout";
import MDTypography from "../../ui-components/MDTypography";
import MDAlert from "../../ui-components/MDAlert";
import MDBox from "../../ui-components/MDBox";
import MDButton from "../../ui-components/MDButton";
import MDInput from "../../ui-components/MDInput";
import { useDBManager } from "../../contexts/DBManager_Context";
import { Add } from "@mui/icons-material";

export default function Run_Pick() {
  const { docID } = useParams();
  const navigate = useNavigate();
  const [runData, setRunData] = useState([]);
  const [runDoc, setRunDoc] = useState({});

  const { updateFields, state, handleWriteOffClickOpen, renderWriteOffDialog } =
    useDBManager();

  const [warehouseProducts, setWarehouseProducts] = useState([]);
  const [warehouseProductIDs, setWarehouseProductIDs] = useState([]);

  const imageStyle = { width: "50px", height: "50px" };
  const [newQuantity, setNewQuantity] = useState(0);
  const [editIndex1, setEditIndex1] = useState("");
  const [editIndex2, setEditIndex2] = useState("");

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e, num) => {
    e.preventDefault();
    setOpen(false);
    if (num == 1) {
      callbackFunc_updatePicked(num)
    }
  };

  const callbackFunc_updatePicked = async (num, callback) => {
    let x = runData;
    x[editIndex2].pickedProducts[editIndex1].quantity = parseInt(newQuantity);
    const docRef = doc(
      db,
      "Run Data",
      docID,
      "machines",
      runData[editIndex2].id
    );
    updateDoc(docRef, x[editIndex2]).then(async (response) => {
      callback();
    });
  };

  useEffect(() => {
    updateFields([
      "users",
      "machines",
      "products",
      "reasons",
      "availableLocations",
    ]);
    fetchRunDoc();
    fetchRun();
  }, []);

  useEffect(() => {
    fetchWarehouseProducts();
  }, [runData]);

  function openDialog(e, index, xndex) {
    e.preventDefault();
    setEditIndex1(index);
    setEditIndex2(xndex);
    handleClickOpen();
  }

  async function fetchRunDoc() {
    let data = {};
    await getDoc(doc(db, "Run Data", docID)).then((response) => {
      let id = response.id;
      data = { id, ...response.data() };
    });

    return Promise.resolve(data).then(() => {
      //Set plan data
      let x = data;
      setRunDoc((prev) => x);
      fetchWarehouseProducts(x);
    });
  }

  async function fetchWarehouseProducts() {
    let querySnapshot = await getDocs(
      collection(db, "Warehouses", runDoc.warehouse.id, "products")
    );
    let pdata = [];
    let pids = [];
    querySnapshot.forEach((doc) => {
      pdata.push(doc.data());
      pids.push(doc.id);
    });
    return Promise.resolve(pdata, pids).then(() => {
      let x = pdata;
      let y = pids;
      setWarehouseProducts((prev) => x);
      setWarehouseProductIDs((prev) => y);
    });
  }

  async function fetchRun() {
    let querySnapshot = await getDocs(
      collection(db, "Run Data", docID, "machines")
    );
    let data = [];
    querySnapshot.forEach((doc) => {
      // data.push(doc.data());
      let id = doc.id;
      data.push({ id, ...doc.data() });
    });
    return Promise.resolve(data).then(() => {
      let x = JSON.parse(JSON.stringify(data));
      setRunData((prev) => x);
    });
  }

  function buildProduct2(item, index, kndex, itemObj) {
    let url = "";
    let caseSize = 0;
    let altCaseSize = 48;
    for (let i = 0; i < state.products.length; i++) {
      if (item.header.name == state.products[i].name) {
        url = state.products[i].url;
        caseSize = state.products[i].caseSize;
        break;
      }
    }
    let cases =
      Math.round(
        (parseInt(item.header.quantity) / parseInt(caseSize) + Number.EPSILON) *
          100
      ) / 100;
    let caseOverflow = Math.round((cases - Math.floor(cases)) * caseSize);

    let altCases =
      Math.round(
        (parseInt(item.header.quantity) / parseInt(altCaseSize) +
          Number.EPSILON) *
          100
      ) / 100;
    let altCaseOverflow = Math.round(
      (altCases - Math.floor(altCases)) * altCaseSize
    );

    let pickedProduct = {};
    let pickedIndex = 0;
    itemObj.pickedProducts?.forEach((ting, yndex) => {
      if (ting.name == item.header.name) {
        pickedProduct = ting;
        pickedIndex = yndex;
      }
    });

    return (
      <>
        <Grid item xs={1} xl={1} />
        <Grid item xs={2} xl={2}>
          <img align="center" style={imageStyle} src={url} alt="" />
        </Grid>
        <Grid item xs={8} xl={8}>
          <MDTypography variant="h6">{item.header.name}</MDTypography>
          <MDTypography variant="h6">
            Needed: {item.header.quantity} / {Math.floor(cases)} Cases and{" "}
            {caseOverflow} Slices
          </MDTypography>
          {/* <MDTypography variant="h6">
            Needed (48 cases): {item.header.quantity} / {Math.floor(altCases)}{" "}
            Cases and {altCaseOverflow} Slices
          </MDTypography> */}

          {item.batches.map((batch, bndex) => {
            return (
              <MDButton
                variant="outlined"
                color={pickedProduct.quantity == 0 ? "primary" : "secondary"}
                onClick={(e) => openDialog(e, pickedIndex, index)}
              >
                Picked: {pickedProduct.quantity}
              </MDButton>
            );
          })}
        </Grid>
        <Grid item xs={1} xl={1} />
      </>
    );
  }

  function buildMachine(item, index) {
    let segmentedProducts = [];
    item.orderedProducts?.forEach((entry, kndex) => {
      let exists = false;
      segmentedProducts.forEach((mach, tndex) => {
        if (mach.name == entry.name) {
          exists = true;
        }
      });
      if (!exists) {
        segmentedProducts.push({
          header: {
            name: entry.name,
            quantity: entry.quantity,
          },
          batches: [],
        });
      }
    });
    warehouseProducts.forEach((entry, kndex) => {
      segmentedProducts.forEach((obj, ondex) => {
        if (obj.header.name == entry.name) {
          obj.batches.push(entry);
        }
      });
    });

    return (
      <>
        <MDBox mt={8}>
          <Grid item xs={12}>
            <Card>
              <Grid container spacing={2} justifyContent="center">
                <Grid align="center" item xs={12}>
                  <Card>
                    <MDBox
                      mx={1}
                      mt={0}
                      py={2}
                      px={4}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h5" color="white">
                        {item.machineID.name}
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
                {needsForks(item)}
                {segmentedProducts.map((entry, kndex) =>
                  buildProduct2(entry, index, kndex, item)
                )}
                <Grid item xs={12} xl={12} />
              </Grid>
            </Card>
          </Grid>
        </MDBox>
      </>
    );
  }

  function buildTotalProduct(entry, index) {
    let url = "";
    let caseSize = 0;
    for (let i = 0; i < state.products.length; i++) {
      if (
        entry.name == state.products[i].name ||
        entry.name == state.products[i].altName1 ||
        entry.name == state.products[i].altName2 ||
        entry.name == state.products[i].altName3
      ) {
        url = state.products[i].url;
        caseSize = state.products[i].caseSize;
        break;
      }
    }
    let cases =
      Math.round(
        (parseInt(entry.quantity) / parseInt(caseSize) + Number.EPSILON) * 100
      ) / 100;
    let caseOverflow = Math.round((cases - Math.floor(cases)) * caseSize);
    return (
      <>
        <Grid item xs={1} xl={1} />
        <Grid item xs={2} xl={2}>
          <img align="center" style={imageStyle} src={url} alt="" />
        </Grid>
        <Grid item xs={8} xl={8}>
          <MDTypography sx={"margin-top: 15px"} variant="h6">
            {entry.name}
          </MDTypography>
          <MDTypography variant="h6">
            Total Needed: {entry.quantity} / {Math.floor(cases)} Cases and{" "}
            {caseOverflow} Slices
          </MDTypography>
        </Grid>

        <Grid item xs={1} xl={1} />
      </>
    );
  }

  function buildTotalCard(item) {
    return (
      <>
        <MDBox mt={8}>
          <Grid item xs={12}>
            <Card>
              <Grid container spacing={2} justifyContent="center">
                <Grid align="center" item xs={12}>
                  <Card>
                    <MDBox
                      mx={1}
                      mt={0}
                      py={2}
                      px={4}
                      variant="gradient"
                      bgColor="error"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h5" color="white">
                        Total
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
                {item.map((entry, index) => buildTotalProduct(entry, index))}
                <Grid item xs={12} xl={12} />
              </Grid>
            </Card>
          </Grid>
        </MDBox>
      </>
    );
  }

  function calculateTotalItems() {
    let x = [];
    runData.forEach((item, index) => {
      item.orderedProducts.forEach((entry, jndex) => {
        let exists = false;
        let edex = 0;
        x.forEach((thing, tndex) => {
          if (thing.name == entry.name) {
            exists = true;
            edex = tndex;
          }
        });
        if (!exists) {
          x.push(entry);
        } else {
          let y = x[edex].quantity + entry.quantity;
          x[edex] = { name: x[edex].name, quantity: y };
        }
      });
    });
    return x;
  }

  function renderItems() {
    if (runData) {
      return (
        <>
          {runData?.map((item, index) => buildMachine(item, index))}
          {buildTotalCard(calculateTotalItems())}
        </>
      );
    }
  }

  async function handleContinueRun(e) {
    e.preventDefault();
    let querySnapshot = await getDocs(
      collection(db, "Run Data", docID, "machines")
    );
    let data = [];
    let ids = [];
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
      ids.push(doc.id);
    });
    return Promise.resolve(data, ids).then(() => {
      let x = warehouseProducts;

      let q = { machines: [] };
      runData.forEach((item, index) => {
        q.machines.push(item.pickedProducts);
      });

      let remainingInv = [];
      q.machines.forEach((item, index) => {
        item.forEach((thing, tndex) => {
          warehouseProducts.forEach((entry, endex) => {
            if (entry.name == thing.name && entry.quantity > 0) {
              if (entry.quantity - thing.quantity > 0) {
                x[endex].quantity =
                  parseInt(x[endex].quantity) - parseInt(thing.quantity);
              } else {
                x[endex].quantity = 0;
                remainingInv.push({
                  name: x[endex].name,
                  quantity:
                    -1 *
                    (parseInt(x[endex].quantity) - parseInt(thing.quantity)),
                });
              }
            }
          });
        });
      });

      remainingInv.forEach((item, index) => {
        warehouseProducts.forEach((entry, endex) => {
          if (entry.name == item.name) {
            x[endex].quantity =
              parseInt(x[endex].quantity) + parseInt(item.quantity);
          }
        });
      });

      x.forEach((item, index) => {
        let docRef = doc(
          db,
          "Warehouses",
          runDoc.warehouse.id,
          "products",
          warehouseProductIDs[index]
        );
        updateDoc(docRef, item);
      });

      let inventory = [];
      q.machines.forEach((item, index) => {
        item.forEach((thing, tndex) => {
          let exists = false;
          let edex = 0;
          inventory.forEach((entry, endex) => {
            if (entry.name == thing.name) {
              exists = true;
              edex = endex;
            }
          });
          if (!exists) {
            inventory.push(thing);
          } else {
            let y = inventory[edex].quantity;
            y = y + thing.quantity;
            inventory[edex].quantity = y;
          }
        });
      });

      updateDoc(doc(db, "Run Data", docID), {
        stage: 1,
        driverInventory: inventory,
      });
      navigate("/run-overview/" + docID);
    });
  }

  function renderDialog() {
    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Enter Picked Quantity</DialogTitle>
          <DialogContent>
            <DialogContentText>
              How much of this flavor did you pick for this machine?
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="New Quantity"
              type="email"
              fullWidth
              variant="standard"
              onChange={(e) => setNewQuantity(parseInt(e.target.value))}
            />
          </DialogContent>
          <DialogActions>
            <MDButton onClick={(e) => handleClose(e, 0)}>Cancel</MDButton>
            <MDButton onClick={(e) => handleClose(e, 1)}>Enter</MDButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  function needsForks(item) {
    if (item.machineID.needsForks) {
      return (
        <>
          <Grid item xs={3} xl={3} />
          <Grid item xs={6} xl={6}>
            <MDBox bgColor="error" variant="gradient" borderRadius="15px" p={1}>
              <MDTypography align="center" color="white" variant="h6" noWrap>
                Needs Forks
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={3} xl={3} />
        </>
      );
    }
  }

  return (
    <>
      <BasicLayout>
        <Grid container justifyContent="center">
          {renderItems()}
          <MDButton
            justifyContent="center"
            variant="text"
            onClick={(e) => handleContinueRun(e)}
          >
            <MDAlert color="error">
              <MDTypography justifycontent="center" variant="h6" color="white">
                Finish Pick/Pack Stage
              </MDTypography>
            </MDAlert>
          </MDButton>
        </Grid>
        <Fab
          color="info"
          aria-label="add"
          sx={{ position: "absolute", bottom: 40, right: 20 }}
          onClick={handleWriteOffClickOpen}
        >
          <Add />
        </Fab>
        {renderWriteOffDialog()}
      </BasicLayout>
      {renderDialog()}
    </>
  );
}
