import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Card,
  TextField,
  Divider,
} from "@mui/material";
import { CSVLink } from "react-csv";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { collection, getDocs } from "firebase/firestore";
import HandleLogout from "../HandleLogout";
import MDButton from "../../ui-components/MDButton";
import { useDBManager } from "../../contexts/DBManager_Context";
import { useNavigate } from "react-router-dom";
import salesbymachine_data from "../reportData/salesbymachine_data";
import DataTable_Generic from "../Tables/DataTable_Generic";
import Moment from "moment";

export default function Sales() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };
  const { state, fetchCollection } = useDBManager();
  const { updateFields } = useDBManager();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [saleCsvData, setSaleCsvData] = useState([]);
  const [startDateValue, setStartDateValue] = useState(new Date());
  const [endDateValue, setEndDateValue] = useState(new Date());
  const [selectedMachines, setSelectedMachines] = useState([]);

  useEffect(() => {
    updateFields(["machines", "products"]);
  }, []);
  
  const handleSetChecked = (event, obj) => {
    let z = selectedMachines;
    if (!selectedMachines.includes(obj)) {
      z.push(obj);
    } else {
      z.splice(selectedMachines.indexOf(obj), 1);
      setSelectedMachines((prev) => z);
    }
  };

  const handleSelectAll = (event) => {
    if (selectedMachines.length == 0) {
      setSelectedMachines(prev => state.machines)
    } else {
      setSelectedMachines(prev => [])
    }
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    fetchRequestedSales();
  };

  async function fetchRequestedSales() {
    let x_dates = getDates(new Date(startDateValue), new Date(endDateValue));
    let data = [];
    const globalRequests = selectedMachines.map(async (machine) => {
      let localData = [];
      const localRequests = x_dates.map(async (item, index) => {
        let x_year = String(item.getFullYear());
        let x_month = String(parseInt(item.getMonth()) + 1);
        let x_day = String(item.getDate());
        if (parseInt(x_month) < 10) {
          x_month = "0" + String(x_month);
        }
        if (parseInt(x_day) < 10) {
          x_day = "0" + String(x_day);
        }
        return await getDocs(
          collection(db, "Sales", machine.number, x_year, x_month, x_day)
        ).then((response) => {
          response.forEach((doc) => {
            localData.push(doc.data());
          });
        });
      });
      return Promise.all(localRequests).then((promisedData) => {
        data.push(localData);
      });
    });
    return Promise.all(globalRequests).then(() => {
      processSales(data);
    });
  }

  function getDates(start, stop) {
    let dateArray = new Array();
    let currentDate = start;
    while (currentDate <= stop) {
      currentDate.setDate(currentDate.getDate() + 1);
      dateArray.push(new Date(currentDate));
    }
    return dateArray;
  }

  function processSales(data) {
    let homogenizedSales = [];
    data.forEach((item, index) => {
      let dataStructure = {
        products: [],
        quantities: [],
      };
      item.forEach((sale) => {
        if (!dataStructure.products.includes(sale.productName)) {
          dataStructure.products.push(sale.productName);
          dataStructure.quantities.push(1);
        } else {
          dataStructure.quantities[
            dataStructure.products.indexOf(sale.productName)
          ] += 1;
        }
      });
      homogenizedSales.push(dataStructure);
    });
    let reportedProducts = []
    homogenizedSales.forEach((item, index) => {
      item.products.forEach((product, pndex) => {
        if (!reportedProducts.includes(product)) {
          reportedProducts.push(product);
        }
      });
    })
    setColumns(
      salesbymachine_data(selectedMachines, homogenizedSales, reportedProducts)
        .columns
    );
    setRows(
      salesbymachine_data(selectedMachines, homogenizedSales, reportedProducts)
        .rows
    );
    prepareSaleDataForCSV(homogenizedSales);
  }

  function prepareSaleDataForCSV(f) {
    let lRows = [];
    let b = [];
    b.push("Product");
    selectedMachines.map((machine, index) => {
      b.push(machine.number + " - " + machine.name);
    });
    b.push("Total");
    lRows.push(b);

    state.products.map((item, pndex) => {
      let a = [];
      a.push(item.name);
      f.map((machine, index) => {
        let y = 0;
        machine.products.forEach((thing, tndex) => {
          if (thing === item.name) {
            y = y + machine.quantities[tndex];
          }
        });
        a.push(y);
      });
      let total = 0;
      a.forEach((item, index) => {
        if (index != 0) {
          total = total + parseInt(item);
        }
      });
      if (total != 0) {
        a.push(total);
        lRows.push(a);
      }
    });
    setSaleCsvData(lRows);
  }

  function renderTable() {
    if (columns != undefined) {
      return (
        <>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Report
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable_Generic
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                />
              </MDBox>
              <MDButton>
                <CSVLink
                  filename={startDateValue + "---" + endDateValue}
                  data={saleCsvData}
                >
                  Download CSV
                </CSVLink>
                ;
              </MDButton>
            </Card>
          </Grid>
        </>
      );
    }
  }

  return (
    <>
      {currentUser ? (
        <Container>
          <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={5}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={12} lg={12}>
                  <Card>
                    <MDBox
                      mx={0}
                      mt={0}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="dark"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Sales Analysis
                      </MDTypography>
                    </MDBox>
                    <Grid container>
                      <Grid item xs={1} />
                      <Grid item xs={10}>
                        <FormControlLabel
                          control={
                            <Checkbox style={{ "margin-left": "20px"}} onChange={(e) => handleSelectAll(e)} />
                          }
                          label="All Machines"
                        />
                        <Divider />
                        <div
                          style={{
                            maxHeight: 400,
                            overflow: "auto",
                            "background-color": "white",
                          }}
                        >
                          <FormGroup>
                            {state.machines.map((machine) => (
                              <FormControlLabel
                                control={
                                  <Checkbox style={{ "margin-left": "20px" }} onChange={(e) => handleSetChecked(e, machine)}/>
                                }
                                label={machine.number + " - " + machine.name}
                                style={{}}
                              />
                            ))}
                          </FormGroup>
                        </div>

                        <TextField
                          sx={textboxStyle}
                          id="start-date"
                          label="Start Date"
                          type="date"
                          defaultValue={Moment().format()}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => setStartDateValue(e.target.value)}
                        />
                        <TextField
                          sx={textboxStyle}
                          id="end-date"
                          label="End Date"
                          type="date"
                          defaultValue={Moment().format()}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => setEndDateValue(e.target.value)}
                        />
                        <MDButton
                          sx={textboxStyle}
                          align="center"
                          color="dark"
                          variant="contained"
                          onClick={(e) => handleOnClick(e)}
                        >
                          Analyze
                        </MDButton>
                      </Grid>
                    </Grid>
                    <MDBox
                      mx={2}
                      mt={0}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="white"
                      borderRadius="lg"
                    ></MDBox>
                  </Card>
                </Grid>
                {renderTable()}
              </Grid>
            </MDBox>
            <Footer />
          </DashboardLayout>
        </Container>
      ) : (
        <HandleLogout />
      )}
    </>
  );
}
