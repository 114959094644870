import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { useParams } from "react-router-dom";
import { collection, updateDoc, doc } from "firebase/firestore";
import {
  Grid,
  Container,
  Card,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputLabel,
} from "@mui/material";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDButton from "../../ui-components/MDButton";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import { useDBManager } from "../../contexts/DBManager_Context";
import AddIcon from "@mui/icons-material/Add";

export default function User_View() {
  const { docID } = useParams();
  const { updateFields } = useDBManager();
  const { state, fetchDocument, fetchCollection } = useDBManager();
  const [user, setUser] = useState({});
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newActive, setNewActive] = useState(false);
  const [newAdmin, setNewAdmin] = useState(false);
  const [newRegions, setNewRegions] = useState([]);
  const [newRole, setNewRole] = useState("");

  useEffect(() => {
    updateFields(["regions"]);
    //Get user
    fetchDocument(doc(db, "Users", docID)).then((data) => {
      setUser(data);
      setNewActive(data.active);
      setNewAdmin(data.admin);
      setNewEmail(data.email);
      setNewName(data.name);
      setNewRegions(data.regions);
      setNewRole(data.role);
    });
  }, []);

  const callbackFunc_updateUser = async (callback) => {
    if (newRole == "admin") {
      setNewAdmin(true);
    } else {
      setNewAdmin(false);
    }
    const userDocRef = doc(db, "Users", docID);
    updateDoc(userDocRef, {
      name: newName,
      active: newActive,
      admin: newAdmin,
      email: newEmail,
      regions: newRegions,
      role: newRole,
    }).then(async (response) => {
      alert("User Updated");
      //Get user
      fetchDocument(doc(db, "Users", docID)).then((data) => {
        setUser(data);
        setNewActive(data.active);
        setNewAdmin(data.admin);
        setNewEmail(data.email);
        setNewName(data.name);
        setNewRegions(data.regions);
        setNewRole(data.role);
      });
      callback();
    });
  };

  const updateRegions = async (region, item, index) => {
    const localRegions = newRegions;
    localRegions[index] = region;
    setNewRegions(localRegions);
    setUser({ ...user, regions: localRegions });
  };

  const addRegion = async (e) => {
    e.preventDefault();
    const localRegions = newRegions;
    localRegions.push("");
    setNewRegions(localRegions);
    setUser({ ...user, regions: localRegions });
  };

  return (
    <Container>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={3}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h5" color="white">
                {user.name}
              </MDTypography>
            </MDBox>
            <TextField
              align="center"
              sx={{
                "margin-top": "10px",
                "margin-left": "30px",
                "margin-right": "30px",
              }}
              size="small"
              id="newName"
              variant="outlined"
              placeholder={`Name - ${user.name}`}
              onChange={(e) => {
                setNewName(e.target.value);
              }}
            ></TextField>
            <TextField
              sx={{
                "margin-top": "10px",
                "margin-left": "30px",
                "margin-right": "30px",
              }}
              size="small"
              id="newNumber"
              variant="outlined"
              placeholder={`Email - ${user.email}`}
              onChange={(e) => {
                setNewEmail(e.target.value);
              }}
            ></TextField>

            {user.regions?.map((item, index) => (
              <>
                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                >
                  <InputLabel id="region-label">
                    Region {parseInt(index + 1) + " - " + item}
                  </InputLabel>
                  <Select
                    label="Region"
                    value={item}
                    onChange={(e) =>
                      updateRegions(
                        state.regions[e.target.value].name,
                        item,
                        index
                      )
                    }
                  >
                    {state.regions?.map((otem, ondex) => (
                      <MenuItem value={ondex}>{otem.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            ))}
            <MDButton onClick={(e) => addRegion(e)}>
              <AddIcon />
            </MDButton>
            <FormControl
              variant="standard"
              sx={{
                m: 1,
                minWidth: 120,
                "margin-left": "30px",
                "margin-right": "30px",
              }}
            >
              <InputLabel id="active-label">Active</InputLabel>
              <Select
                labelId="active-label"
                id="active-label"
                label="Active"
                value={newActive}
                onChange={(e) => setNewActive(e.target.value)}
              >
                <MenuItem value={true}>True</MenuItem>
                <MenuItem value={false}>False</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="standard"
              sx={{
                m: 1,
                minWidth: 120,
                "margin-left": "30px",
                "margin-right": "30px",
              }}
            >
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                id="role-label"
                label="Role"
                value={newRole}
                onChange={(e) => setNewRole(e.target.value)}
              >
                <MenuItem value={"driver"}>Driver</MenuItem>
                <MenuItem value={"admin"}>Admin</MenuItem>
              </Select>
            </FormControl>
            <MDButton
              align="center"
              sx={{
                "margin-top": "10px",
                "margin-left": "30px",
                "margin-right": "30px",
              }}
              color="success"
              variant="contained"
              onClick={callbackFunc_updateUser}
            >
              Update User
            </MDButton>
            <MDBox
              mx={2}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="white"
              borderRadius="lg"
            ></MDBox>
          </Card>
        </Grid>
        <Footer />
      </DashboardLayout>
    </Container>
  );
}
