// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar";
import MDBadge from "../../ui-components/MDBadge";
import MDButton from "../../ui-components/MDButton";

export default function data(warehouseData, products, warehouses, type) {
  const Mapping = ({ mapping }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {mapping}
      </MDTypography>
      <MDTypography variant="caption"></MDTypography>
    </MDBox>
  );

  const Product = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption"></MDTypography>
    </MDBox>
  );

  if (type === "warehouse") {
    let rows = [];
    let columns = [
      {
        Header: "Product",
        accessor: "product",
        width: "45%",
        align: "left",
      },
    ];
    products.map((item, pndex) => {
      let a = [];
      rows.push({
        product: <Product title={item.name} />,
      });
      warehouseData.map((warehouse, index) => {
        let x = warehouse.filter((product) => product.name === item.name);
        let y = 0;
        x.forEach((product) => {
          y = y + product.quantity;
        });
        a.push(y);
      });
      let total = 0;
      a.forEach((item, index) => {
        if (index != 0) {
          total = total + parseInt(item);
        }
      });
      if (total != 0) {
        a.forEach((entry, index) => {
          rows[pndex][warehouses[index].name] = <Mapping mapping={entry} />;
        });
      }
    });

    warehouses.map((warehouse, index) => {
      columns.push({
        Header: warehouse.name,
        accessor: warehouse.name,
        align: "left",
      });
    });

    return {
      columns,
      rows,
    };
  } else if (type === "machine") {
    let w_rows = [];
    let w_columns = [
      {
        Header: "Product",
        accessor: "product",
        width: "45%",
        align: "left",
      },
    ];

    products.map((item, pndex) => {
      let a = [];
      w_rows.push({
        product: <Product title={item.name} />,
      });
      warehouseData.map((warehouse, index) => {
        let x = warehouse.products.filter((product) => product === item.name);
        let y = 0;
        warehouse.products.forEach((thing, tndex) => {
          if (thing === item.name) {
            y = y + warehouse.mapping[tndex];
          }
        });
        a.push(y);
      });
      let total = 0;
      a.forEach((item, index) => {
        if (index != 0) {
          total = total + parseInt(item);
        }
      });
      if (total != 0) {
        a.forEach((entry, index) => {
          w_rows[pndex][warehouses[index].name] = <Mapping mapping={entry} />;
        });
      }
    });

    warehouses.map((warehouse, index) => {
      w_columns.push({
        Header: warehouse.number + " - " + warehouse.name,
        accessor: warehouse.name,
        align: "left",
      });
    });

    return {
      w_columns,
      w_rows,
    };
  }
}
