import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
} from "@mui/material";
import { db } from "../../firebase";
import { collection, doc, updateDoc, addDoc } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDButton from "../../ui-components/MDButton";
import HandleLogout from "../HandleLogout";
import DataTable_Generic from "../Tables/DataTable_Generic";

import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React example components
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";

import driversTableData from "../data/driverData";
import carriersTableData from "../data/carrierData";

import { useDBManager } from "../../contexts/DBManager_Context";

export default function User_Manager() {
  const navigate = useNavigate();
  const { updateFields } = useDBManager();
  const { state } = useDBManager();
  const { currentUser } = useAuth();
  const [unclaimedDrivers, setUnclaimedDrivers] = useState([]);
  const [admins, setAdmins] = useState([]);

  const [selectedRegion, setSelectedRegion] = useState("");

  const [newCarrierName, setNewCarrierName] = useState("");
  const [newCarrierContact, setNewCarrierContact] = useState("");

  const [users, setUsers] = useState([]);

  const { columns, rows } = driversTableData(users, openUserPage);
  const { columns: c_columns, rows: c_rows } = carriersTableData(
    state.carriers
  );

  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };

  useEffect(() => {
    updateFields(["regions", "carriers", "users"]);
  }, []);

  useEffect(() => {
    let temp = state.users.sort((a, b) => (a.name > b.name ? 1 : -1));
    setUsers((prev) => [...temp]);
    findDrivers();
  }, [state]);

  function findDrivers() {
    let tempArray = [];
    let tempUnclaimed = [];
    let tempAdmins = [];
    state.users.forEach((user) => {
      if (user.admin) {
        tempAdmins.push(user);
      } else {
        user.regions.forEach((region) => {
          if (region != "") {
            tempArray.push(user);
          } else {
            tempUnclaimed.push(user);
          }
        });
      }
    });
    tempUnclaimed.sort((a, b) => (a.name > b.name ? 1 : -1));

    setUnclaimedDrivers((prev) => [...tempUnclaimed]);
    setAdmins((prev) => [...tempAdmins]);
  }

  function openUserPage(docID) {
    navigate("/users/" + docID);
  }

  async function deleteDriver(index) {
    updateFields(["users"]);
  }

  const handleRegionChange = (e, item) => {
    e.preventDefault();
    state.users.forEach((entry, index) => {
      if (entry.email == item.email) {
        let region = selectedRegion.name;
        let y = item;
        if (y.regions[0] == "") {
          y.regions[0] = region;
        } else {
          y.regions.push(region);
        }
        y.role = "driver"
        updateDoc(doc(db, "Users", entry.id), y);
      }
    });
    setSelectedRegion("");
    updateFields(["users"]);
  };

  // function renderUnclaimedTable() {
  //   return (
  //     <>
  //       <Table size="small">
  //         <TableBody>
  //           {unclaimedDrivers?.map((item) => (
  //             <>
  //               <TableRow>
  //                 <TableCell size="small">
  //                   <MDTypography
  //                     display="block"
  //                     variant="caption"
  //                     color="text"
  //                     fontWeight="medium"
  //                   >
  //                     {item.name}
  //                   </MDTypography>
  //                 </TableCell>
  //                 <TableCell size="small">
  //                   <MDTypography
  //                     display="block"
  //                     variant="caption"
  //                     color="text"
  //                     fontWeight="medium"
  //                   >
  //                     {item.email}
  //                   </MDTypography>
  //                 </TableCell>
  //                 <TableCell>
  //                   <FormControl
  //                     variant="standard"
  //                     sx={{
  //                       m: 1,
  //                       minWidth: 120,
  //                       "margin-left": "30px",
  //                       "margin-right": "30px",
  //                     }}
  //                   >
  //                     <InputLabel id="product-label">
  //                       Region - {item.regions[0]}
  //                     </InputLabel>
  //                     <Select
  //                       labelId="region-label"
  //                       id="region-label"
  //                       label="Region"
  //                       value={
  //                         state.regions[state.regions.indexOf(selectedRegion)]
  //                       }
  //                       onChange={(e) =>
  //                         setSelectedRegion(state.regions[e.target.value])
  //                       }
  //                     >
  //                       {state.regions.map((region, index) => (
  //                         <MenuItem value={index}>{region.name}</MenuItem>
  //                       ))}
  //                     </Select>
  //                   </FormControl>
  //                 </TableCell>
  //                 <TableCell>
  //                   <MDButton
  //                     sx={"margin-top: 2px"}
  //                     color="success"
  //                     variant="contained"
  //                     onClick={(e) => handleRegionChange(e, item)}
  //                   >
  //                     Update
  //                   </MDButton>
  //                 </TableCell>
  //               </TableRow>
  //             </>
  //           ))}
  //         </TableBody>
  //       </Table>
  //     </>
  //   );
  // }

  // function renderAdminTable() {
  //   return (
  //     <>
  //       <Table size="small">
  //         <TableBody>
  //           {admins?.map((item) => (
  //             <>
  //               <TableRow>
  //                 <TableCell size="small">
  //                   <MDTypography
  //                     display="block"
  //                     variant="caption"
  //                     color="text"
  //                     fontWeight="medium"
  //                   >
  //                     {item.name}
  //                   </MDTypography>
  //                 </TableCell>
  //                 <TableCell size="small">
  //                   <MDTypography
  //                     display="block"
  //                     variant="caption"
  //                     color="text"
  //                     fontWeight="medium"
  //                   >
  //                     {item.email}
  //                   </MDTypography>
  //                 </TableCell>
  //                 <TableCell size="small">
  //                   {item.regions.map((region, rndex) => (
  //                     <MDTypography
  //                       display="block"
  //                       variant="caption"
  //                       color="text"
  //                       fontWeight="medium"
  //                     >
  //                       {region}
  //                     </MDTypography>
  //                   ))}
  //                 </TableCell>
  //               </TableRow>
  //             </>
  //           ))}
  //         </TableBody>
  //       </Table>
  //     </>
  //   );
  // }

  // const handleAddCarrier = (e) => {
  //   e.preventDefault();
  //   if (newCarrierName == "") {
  //     // setErrorNum(0)
  //     // setErrorSnackbarOpen(true)
  //   } else if (newCarrierContact == "") {
  //     // setErrorNum(1)
  //     // setErrorSnackbarOpen(true)
  //   } else {
  //     const carrier = {
  //       name: newCarrierName,
  //       contact: newCarrierContact,
  //       region: "Carrier",
  //     };
  //     const collRef = collection(db, "Carriers");
  //     addDoc(collRef, carrier);
  //     setNewCarrierName("");
  //     setNewCarrierContact("");
  //   }
  // };

  return (
    <>
    {currentUser ?
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Users
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable_Generic
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Carriers
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable_Generic
                    table={{ columns: c_columns, rows: c_rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid> */}
            {/* <Grid item xs={12} md={6} lg={6}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Unclaimed Drivers
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>{renderUnclaimedTable()}</MDBox>
              </Card>
            </Grid> */}
          </Grid>
        </MDBox>
        {/* <MDBox pt={6} pb={3}>
          <Grid item xs={6}>
            <Card>
              <MDBox
                mx={3}
                mt={0}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Add New Carrier
                </MDTypography>
              </MDBox>
              <TextField
                align="center"
                sx={textboxStyle}
                size="small"
                id="newCarrier"
                variant="outlined"
                placeholder="Carrier Name"
                onChange={(e) => {
                  setNewCarrierName(e.target.value);
                }}
              ></TextField>
              <TextField
                sx={textboxStyle}
                size="small"
                id="newCarrierContant"
                variant="outlined"
                placeholder="Number"
                onChange={(e) => {
                  setNewCarrierContact(e.target.value);
                }}
              ></TextField>
              <MDButton
                align="center"
                sx={textboxStyle}
                color="success"
                variant="contained"
                onClick={handleAddCarrier}
              >
                Create Carrier
              </MDButton>
              <MDBox
                mx={2}
                mt={0}
                py={3}
                px={2}
                variant="gradient"
                bgColor="white"
                borderRadius="lg"
              ></MDBox>
            </Card>
          </Grid>
        </MDBox> */}
        <Footer />
      </DashboardLayout>
       : <HandleLogout/>}
    </>
  );
}
