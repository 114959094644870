import '../App.css'
import { AuthProvider } from "../contexts/AuthContext"
import DBManager_Provider from '../contexts/DBManager_Context'
import {BrowserRouter as Router, Routes, Route, Navigate, useLocation} from 'react-router-dom'
import LocalRoutes from "../LocalRoutes"

import { useState, useEffect, useMemo } from "react";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../ui-components/MDBox";

// Material Dashboard 2 React example components
import Configurator from "../components/Configurator";
import Sidenav from "../components/Sidenav"

// Material Dashboard 2 React themes
import theme from "../assets/theme";
import themeRTL from "../assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "../assets/theme-dark";
import themeDarkRTL from "../assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "../contexts";

// Images
import brandWhite from "../assets/images/logo-ct.png";
import brandDark from "../assets/images/logo-ct-dark.png";

import Login from './Login'
import ForgotPassword from './ForgotPassword'
import Signup from './Signup'
import Machine_View from './Admin/Machine_View'
import Warehouse_View from './Admin/Warehouse_View'
import User_View from './Admin/User_View'
import PrivateRoute from './PrivateRoute'
import Run_Pick from './Driver/Run_Pick'
import Run_Overview from './Driver/Run_Overview'
import Run_Restock from './Driver/Run_Restock'
import Product_View from './Admin/Product_View'
import Transfer_DriverView from './Driver/Transfer_DriverView'
import Admin_TransferView from './Admin/Admin_TransferView'
import Region_View from './Admin/Region_View'
import RunSheets_View from './Admin/RunSheets_View'
import Admin_Run_Pick from './Admin/Admin_Run_Pick'
import Admin_Run_Overview from './Admin/Admin_Run_Overview'
import Admin_Run_Restock from './Admin/Admin_Run_Restock'
import AdminTest from './Admin/AdminTest'
import Run_Schedule_View from './Admin/Run_Schedule_View'
import Machine_View_ReplenishmentAnalysis from './Admin/Machine_View_ReplenishmentAnalysis'
import InventoryPosition from './Reports/InventoryPosition'
import Sales from './Reports/Sales'
import WriteOffsRegion from './Reports/WriteOffsRegion'
import WriteOffs from './Reports/WriteOffs'
import Driver_Planogram from './Driver/Driver_Planogram'

function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      return <Route exact path={route.route} element={route.component} key={route.key} />;
    }
    return null;
  });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        {/* Insert icon */}
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <AuthProvider>
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
          <CssBaseline />
          <DBManager_Provider>
            {layout === "dashboard" && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                  brandName="ERG Vending Suite"
                  routes={LocalRoutes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
                {/* {configsButton} */}
              </>
            )}
            {layout === "vr" && <Configurator />}
            <Routes>
              {getRoutes(LocalRoutes)}
              <Route path="/forgot-password" element={<ForgotPassword/>} />
              <Route path="/signup" element={<Signup/>} />
              <Route path="/test" element={<AdminTest/>} />
              <Route path="/reports/inventory-position" element={<InventoryPosition/>} />
              <Route path="/reports/sales" element={<Sales/>} />
              <Route path="/reports/writeoffs" element={<WriteOffs/>} />
              <Route path="/reports/writeoffs-region" element={<WriteOffsRegion/>} />
              <Route path="/machines/:docID" element={<PrivateRoute><Machine_View /></PrivateRoute>}/>
              <Route path="/machines/replenishmentanalysis/:docID" element={<PrivateRoute><Machine_View_ReplenishmentAnalysis /></PrivateRoute>}/>
              <Route path="/warehouses/:docID" element={<PrivateRoute><Warehouse_View /></PrivateRoute>}/>
              <Route path="/regions/:docID" element={<PrivateRoute><Region_View /></PrivateRoute>}/>
              <Route path="/users/:docID" element={<PrivateRoute><User_View /></PrivateRoute>}/>
              <Route path="/runsheets/:docID" element={<PrivateRoute><RunSheets_View /></PrivateRoute>}/>
              <Route path="/products/:docID" element={<PrivateRoute><Product_View /></PrivateRoute>}/>
              <Route path="/run-pick/:docID" element={<PrivateRoute><Run_Pick /></PrivateRoute>}/>
              <Route path="/admin-run-pick/:docID" element={<PrivateRoute><Admin_Run_Pick /></PrivateRoute>}/>
              <Route path="/run-overview/:docID" element={<PrivateRoute><Run_Overview /></PrivateRoute>}/>
              <Route path="/admin-run-overview/:docID" element={<PrivateRoute><Admin_Run_Overview /></PrivateRoute>}/>
              <Route path="/run-restock/:docID" element={<PrivateRoute><Run_Restock /></PrivateRoute>}/>
              <Route path="/admin-run-restock/:docID" element={<PrivateRoute><Admin_Run_Restock /></PrivateRoute>}/>
              <Route path="/transfer-driver/:docID" element={<PrivateRoute><Transfer_DriverView /></PrivateRoute>}/>
              <Route path="/transfer-admin/:docID" element={<PrivateRoute><Admin_TransferView /></PrivateRoute>}/>
              <Route path="/scheduled-runs/:docID" element={<PrivateRoute><Run_Schedule_View /></PrivateRoute>}/>
              <Route path="/driver-planogram/:docID" element={<PrivateRoute><Driver_Planogram /></PrivateRoute>}/>
              <Route path="/login" element={<Login/>} />
              <Route path="/" element={<Login/>} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
          </DBManager_Provider>
        </ThemeProvider>
      </CacheProvider>
    </AuthProvider>
  ) : (
    <AuthProvider>
      <DBManager_Provider>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="ERG Vending Suite"
              routes={LocalRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(LocalRoutes)}
          <Route path="/forgot-password" element={<ForgotPassword/>} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/test" element={<AdminTest/>} />
          <Route path="/reports/inventory-position" element={<InventoryPosition/>} />
          <Route path="/reports/sales" element={<Sales/>} />
          <Route path="/reports/writeoffs" element={<WriteOffs/>} />
          <Route path="/reports/writeoffs-region" element={<WriteOffsRegion/>} />
          <Route path="/machines/:docID" element={<PrivateRoute><Machine_View /></PrivateRoute>}/>
          <Route path="/machines/replenishmentanalysis/:docID" element={<PrivateRoute><Machine_View_ReplenishmentAnalysis /></PrivateRoute>}/>
          <Route path="/warehouses/:docID" element={<PrivateRoute><Warehouse_View /></PrivateRoute>}/>
          <Route path="/regions/:docID" element={<PrivateRoute><Region_View /></PrivateRoute>}/>
          <Route path="/users/:docID" element={<PrivateRoute><User_View /></PrivateRoute>}/>
          <Route path="/products/:docID" element={<PrivateRoute><Product_View /></PrivateRoute>}/>
          <Route path="/runsheets/:docID" element={<PrivateRoute><RunSheets_View /></PrivateRoute>}/>
          <Route path="/run-pick/:docID" element={<PrivateRoute><Run_Pick /></PrivateRoute>}/>
          <Route path="/admin-run-pick/:docID" element={<PrivateRoute><Admin_Run_Pick /></PrivateRoute>}/>
          <Route path="/run-overview/:docID" element={<PrivateRoute><Run_Overview /></PrivateRoute>}/>
          <Route path="/admin-run-overview/:docID" element={<PrivateRoute><Admin_Run_Overview /></PrivateRoute>}/>
          <Route path="/run-restock/:docID" element={<PrivateRoute><Run_Restock /></PrivateRoute>}/>
          <Route path="/admin-run-restock/:docID" element={<PrivateRoute><Admin_Run_Restock /></PrivateRoute>}/>
          <Route path="/transfer-driver/:docID" element={<PrivateRoute><Transfer_DriverView /></PrivateRoute>}/>
          <Route path="/transfer-admin/:docID" element={<PrivateRoute><Admin_TransferView /></PrivateRoute>}/>
          <Route path="/scheduled-runs/:docID" element={<PrivateRoute><Run_Schedule_View /></PrivateRoute>}/>
          <Route path="/driver-planogram/:docID" element={<PrivateRoute><Driver_Planogram /></PrivateRoute>}/>
          <Route path="/login" element={<Login/>} />
          <Route path="/" element={<Login/>} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
      </DBManager_Provider>
    </AuthProvider>
  )
}

export default App;
