import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { useParams } from "react-router-dom";
import { collection, updateDoc, doc } from "firebase/firestore";
import {
  Grid,
  Container,
  Card,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDButton from "../../ui-components/MDButton";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import DataTable_Generic from "../Tables/DataTable_Generic";
import warehouseDataTable from "../data/warehouseViewBatchData";
import { useDBManager } from "../../contexts/DBManager_Context";

export default function Warehouse_View() {
  const { docID } = useParams();
  const { updateFields } = useDBManager();
  const { state, fetchDocument, fetchCollection } = useDBManager();

  const [warehouse, setWarehouse] = useState({});
  const [batches, setBatches] = useState([]);
  const [newName, setNewName] = useState("");
  const [newRegion, setNewRegion] = useState("");
  const [newQuantity, setNewQuantity] = useState(0);
  const [editIndex, setEditIndex] = useState("");

  let { columns, rows } = warehouseDataTable(
    batches,
    state.products,
    openDialog
  );

  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e, num) => {
    e.preventDefault();
    setOpen(false);
    if (num == 1) {
      let x = batches;
      x[editIndex].quantity = parseInt(newQuantity);
      const docRef = doc(
        db,
        "Warehouses",
        docID,
        "products",
        batches[editIndex].id
      );
      updateDoc(docRef, x[editIndex]);
    }
  };

  useEffect(() => {
    updateFields(["products", "regions"]);
    //Get machine
    fetchDocument(doc(db, "Warehouses", docID)).then((data) => {
      setWarehouse(data);
      setNewName(data.name);
      setNewRegion(data.servicing);
    });
    //Get batches
    fetchCollection(collection(db, "Warehouses", docID, "products")).then(
      (data) => {
        setBatches(data);
      }
    );
  }, []);

  const onUpdateWarehouse = () => {
    const wDocRef = doc(db, "Warehouses", docID);
    updateDoc(wDocRef, {
      name: newName,
      servicing: newRegion.name,
    });
    alert("Warehouse Updated");
    fetchDocument(doc(db, "Warehouses", docID)).then((data) => {
      setWarehouse(data);
      setNewName(data.name);
      setNewRegion(data.servicing);
    });
  };

  function openDialog(e, index) {
    e.preventDefault();
    setEditIndex(index);
    handleClickOpen();
  }

  function renderDialog() {
    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Enter New Quantity</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This will update the entry immediately.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="New Quantity"
              type="email"
              fullWidth
              variant="standard"
              onChange={(e) => setNewQuantity(parseInt(e.target.value))}
            />
          </DialogContent>
          <DialogActions>
            <MDButton onClick={(e) => handleClose(e, 0)}>Cancel</MDButton>
            <MDButton onClick={(e) => handleClose(e, 1)}>Enter</MDButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <Container>
        <DashboardLayout>
          <DashboardNavbar />
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={3}
                mt={0}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  {warehouse.name}
                </MDTypography>
              </MDBox>
              <TextField
                align="center"
                sx={textboxStyle}
                size="small"
                id="newName"
                variant="outlined"
                placeholder={`Name - ${warehouse.name}`}
                onChange={(e) => {
                  setNewName(e.target.value);
                }}
              ></TextField>
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  minWidth: 120,
                  "margin-left": "30px",
                  "margin-right": "30px",
                }}
              >
                <InputLabel id="product-label">
                  Region - {warehouse.servicing}
                </InputLabel>
                <Select
                  labelId="region-label"
                  id="region-label"
                  label="Region"
                  value={newRegion.name}
                  onChange={(e) => setNewRegion(state.regions[e.target.value])}
                >
                  {state.regions.map((item, index) => (
                    <MenuItem value={index}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <MDButton
                align="center"
                sx={textboxStyle}
                color="success"
                variant="contained"
                onClick={onUpdateWarehouse}
              >
                Update Warehouse Info
              </MDButton>
              <MDBox
                mx={2}
                mt={0}
                py={3}
                px={2}
                variant="gradient"
                bgColor="white"
                borderRadius="lg"
              ></MDBox>
            </Card>

            <MDBox pt={6} pb={5}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Inventory
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={3}>
                      <DataTable_Generic
                        table={{ columns, rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={true}
                      />
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          <Footer />
        </DashboardLayout>
      </Container>
      {renderDialog()}
    </>
  );
}
