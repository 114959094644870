import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  Step,
  StepLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  Stepper,
  InputLabel,
  TextField,
} from "@mui/material";
import { db } from "../../firebase";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDInput from "../../ui-components/MDInput";
import MDButton from "../../ui-components/MDButton";
import DataTable_Generic from "../Tables/DataTable_Generic";
import DataTable_Warehouses from "../Tables/DataTable_Warehouses";
import TimePicker from "react-time-picker";
import { useDBManager } from "../../contexts/DBManager_Context";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import transferProductData from "../data/transferProductData";
import transferConfirmProductData from "../data/transferConfirmProductData";
import HandleLogout from "../HandleLogout";

export default function Transfers() {
  let date = new Date();
  const { currentUser } = useAuth();
  const [dateValue, setDateValue] = useState("");
  const [timeValue, onChangeTime] = useState("10:00");

  const steps = ["Origin", "Product", "Destination", "Carrier", "Details"];

  const { updateFields } = useDBManager();
  const { state } = useDBManager();

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const [availableOriginProducts, setAvailableOriginProducts] = useState([]);

  const [availableOrigins, setAvailableOrigins] = useState([]);
  const [availableDestinations, setAvailableDestinations] = useState([]);

  const [availableCarriers, setAvailableCarriers] = useState([]);

  const [selectedOrigin, setSelectedOrigin] = useState({});
  const [selectedDestination, setSelectedDestination] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState({});

  const [transferPO, setTransferPO] = useState("");
  const [apptNum, setApptNum] = useState("");

  let { columns, rows } = transferProductData(
    availableOriginProducts,
    state.products,
    handleChangeTransferProduct
  );
  let { w_columns, w_rows } = transferConfirmProductData(
    selectedProducts,
    state.products
  );

  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };

  const isStepOptional = (step) => {
    // return step === 2;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (e, item) => {
    e.preventDefault();
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 0) {
    } else if (activeStep === 4) {
      submitTransfer();
    }

    if (activeStep !== 4) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setSelectedOrigin((prev) => {});
    setSelectedDestination((prev) => {});
    setSelectedProducts((prev) => []);
    setSelectedCarrier((prev) => {});
    setActiveStep(0);
  };

  useEffect(() => {
    updateFields([
      "regions",
      "products",
      "warehouses",
      "machines",
      "users",
      "carriers",
    ]);
  }, []);

  useEffect(() => {
    let x = [];
    state.warehouses?.forEach((item, index) => {
      x.push(item);
    });
    state.machines?.forEach((item, index) => {
      x.push(item);
    });
    setAvailableOrigins(x);
    let d = [];
    state.users?.forEach((item, index) => {
      d.push(item);
    });
    state.carriers?.forEach((item, index) => {
      d.push(item);
    });
    setAvailableCarriers(d);
  }, [state]);

  useEffect(() => {
    if (activeStep == 1) {
      let x = availableOrigins;
      x.splice(availableOrigins.indexOf(selectedOrigin), 1);
      setAvailableDestinations(x);
    } else if (activeStep == 2) {
      console.log("step 2");
    }
  }, [activeStep]);

  function handleChangeTransferProduct(quantity, item) {
    let exists = false;
    let x = selectedProducts;
    for (let i = 0; i < selectedProducts.length; i++) {
      if (
        selectedProducts[i].name == item.name &&
        selectedProducts[i].batch == item.batch
      ) {
        x[i].quantity = quantity;
        exists = true;
      }
    }
    if (!exists) {
      let y = item;
      y.quantity = quantity;
      x.push(y);
    }
    setSelectedProducts(x);
  }

  async function getWPDocs(warehouseID) {
    const querySnapshot = await getDocs(
      collection(db, "Warehouses", warehouseID, "products")
    );
    const productData = [];
    const IDs = [];
    querySnapshot.forEach((doc) => {
      let id = doc.id;
      productData.push({ id, ...doc.data() });
      IDs.push(id);
    });
    let list = []; //Merges doc data and ids, sorts them, then separates them back out into different arrays
    for (let j = 0; j < productData.length; j++) {
      list.push({ productData: productData[j], id: IDs[j] });
    }
    list.sort(function (a, b) {
      return a.productData.name < b.productData.name
        ? -1
        : a.productData.name == b.productData.name
        ? 0
        : 1;
    });
    for (var k = 0; k < list.length; k++) {
      productData[k] = list[k].productData;
      IDs[k] = list[k].id;
    }
    return productData;
  }

  async function getMPDocs(machID) {
    const querySnapshot = await getDocs(
      collection(db, "Machines", machID, "plans")
    );
    const productData = [];
    const IDs = [];
    querySnapshot.forEach((doc) => {
      let id = doc.id;
      productData.push({ id, ...doc.data() });
      IDs.push(id);
    });
    let list = []; //Merges doc data and ids, sorts them, then separates them back out into different arrays
    for (let j = 0; j < productData.length; j++) {
      list.push({ productData: productData[j], id: IDs[j] });
    }
    list.sort(function (a, b) {
      return a.productData.requestDateTime < b.productData.requestDateTime
        ? -1
        : a.productData.requestDateTime == b.productData.requestDateTime
        ? 0
        : 1;
    });
    for (var k = 0; k < list.length; k++) {
      productData[k] = list[k].productData;
      IDs[k] = list[k].id;
    }
    return assembleSMLabels(productData[0]);
  }

  function assembleSMLabels(p) {
    let h = [
      {
        name: [],
        batch: [],
        quantity: [],
      },
    ];

    p.shelves?.forEach((item, index) => {
      if (!h.products.includes(item.productCode)) {
        h.products.push(item.productCode);
        h.mapping.push(item.currentStock);
        h.maxQuantities.push(item.maxStock);
        h.totalMapping = h.totalMapping + item.currentStock;
        h.totalMax = h.totalMax + item.maxStock;
      } else {
        let x = h.mapping[h.products.indexOf(item.productCode)];
        x = x + item.currentStock;
        h.mapping[h.products.indexOf(item.productCode)] = x;

        let y = h.maxQuantities[h.products.indexOf(item.productCode)];
        y = y + item.maxStock;
        h.maxQuantities[h.products.indexOf(item.productCode)] = y;

        h.totalMapping = h.totalMapping + item.currentStock;
        h.totalMax = h.totalMax + item.maxStock;
      }
    });

    h.products.forEach((item, index) => {
      state.products?.forEach((entry, kndex) => {
        if (item == entry.bud) {
          h.products[index] = entry.name;
        }
      });
    });
    return h;
  }

  function fetchWarehouseProducts(id) {
    getWPDocs(id).then((z) => {
      setAvailableOriginProducts(z);
    });
  }

  function fetchMachineProducts(id) {
    getMPDocs(id).then((z) => {
      setAvailableOriginProducts(z);
    });
  }

  async function submitTransfer() {
    if (dateValue != "") {
      let promises = [];
      const locRef = collection(db, "Transfer Data");
      promises.push(
        await addDoc(locRef, {
          origin: selectedOrigin,
          originID: selectedOrigin.id,
          destination: selectedDestination,
          destinationID: selectedDestination.id,
          carrier: selectedCarrier,
          carrierID: selectedCarrier.id,
          products: selectedProducts,
          POnumber: transferPO,
          author: state.currentUserData,
          timestamp: date,
          apptNum: apptNum,
          pickupTime: timeValue,
          pickupDate: dateValue,
        })
      );
      return Promise.all(promises).then((x) => {
        console.log(x);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      });
    } else {
      alert("Please select a date and time");
    }
  }

  function renderStepper() {
    return (
      <>
        <MDBox sx={{ width: "100%" }}>
          <MDTypography>Transfer</MDTypography>
          <Stepper
            activeStep={activeStep}
            sx={{ "margin-bottom": "10px" }}
            alternativeLabel
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <MDTypography variant="caption">Optional</MDTypography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <MDTypography sx={{ mt: 2, mb: 1 }}>
                Transfer Order Placed
              </MDTypography>
              <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <MDBox sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </MDBox>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* PUT ALL CONTENT IN BETWEEN STEPPER HERE */}
              {activeStep === 0 ? (
                <Grid container spacing={3}>
                  {renderOrigins()}
                </Grid>
              ) : activeStep === 1 ? (
                <Grid container spacing={3}>
                  {renderProductCard()}
                </Grid>
              ) : activeStep === 2 ? (
                <Grid container spacing={3}>
                  {renderDestinations()}
                </Grid>
              ) : activeStep === 3 ? (
                <Grid container spacing={3}>
                  {renderCarriers()}
                </Grid>
              ) : activeStep === 4 ? (
                <Grid container spacing={3}>
                  {renderConfirmCard()}
                </Grid>
              ) : null}

              {/* END OF CONTENT ZONE */}
              <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleReset}
                  sx={{ mr: 1 }}
                >
                  Start Over
                </Button>
                <MDBox sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}

                <MDButton
                  variant="contained"
                  color="error"
                  onClick={(e) => handleNext(e)}
                >
                  <MDTypography
                    display="block"
                    variant="caption"
                    color="white"
                    fontWeight="medium"
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </MDTypography>
                </MDButton>
              </MDBox>
            </React.Fragment>
          )}
        </MDBox>
      </>
    );
  }

  function handleSetOrigin(e) {
    e.preventDefault();
    setSelectedOrigin(availableOrigins[e.target.value]);
    if (availableOrigins[e.target.value].number == "Warehouse") {
      let localCarriers = [];
      state.users?.forEach((user) => {
        user.regions?.forEach((region) => {
          if (region == availableOrigins[e.target.value].servicing) {
            localCarriers.push(user);
          }
        });
      });
      state.carriers?.forEach((carrier) => {
        localCarriers.push(carrier);
      });
      setAvailableCarriers(localCarriers);
    } else {
      let localCarriers = [];
      state.users?.forEach((user) => {
        user.regions?.forEach((region) => {
          if (region == availableOrigins[e.target.value].region) {
            localCarriers.push(user);
          }
        });
      });
      state.carriers.forEach((carrier) => {
        localCarriers.push(carrier);
      });
      setAvailableCarriers(localCarriers);
    }

    if (availableOrigins[e.target.value].number == "Warehouse") {
      fetchWarehouseProducts(availableOrigins[e.target.value].id);
    } else {
      fetchMachineProducts(availableOrigins[e.target.value].id);
    }
  }

  function handleSetDestination(e) {
    e.preventDefault();
    setSelectedDestination(availableDestinations[e.target.value]);
  }

  function handleSetCarrier(e) {
    e.preventDefault();
    setSelectedCarrier(availableCarriers[e.target.value]);
  }

  function renderOrigins() {
    return (
      <>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={3}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="dark"
              borderRadius="lg"
              coloredShadow="dark"
            >
              <MDTypography variant="h5" color="white">
                Origin
              </MDTypography>
            </MDBox>
            <FormControl
              variant="standard"
              sx={{
                m: 1,
                minWidth: 120,
                "margin-left": "30px",
                "margin-right": "30px",
              }}
            >
              <InputLabel id="product-label">Select</InputLabel>
              <Select
                labelId="region-label"
                id="region-label"
                label="Region"
                value={selectedOrigin.name}
                onChange={(e) => handleSetOrigin(e)}
              >
                {availableOrigins.map((item, index) => (
                  <MenuItem value={index}>
                    {item.number + " - " + item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <MDBox
              mx={2}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="white"
              borderRadius="lg"
            ></MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  function renderProductCard() {
    return (
      <>
        <Grid item xs={12}>
          <MDBox pt={6} pb={5}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="dark"
              >
                <MDTypography variant="h6" color="white">
                  {selectedOrigin.name} - Inventory
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable_Generic
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                />
              </MDBox>
            </Card>
          </MDBox>
        </Grid>
      </>
    );
  }

  function renderDestinations() {
    return (
      <>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={3}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="dark"
              borderRadius="lg"
              coloredShadow="dark"
            >
              <MDTypography variant="h5" color="white">
                Destination
              </MDTypography>
            </MDBox>
            <FormControl
              variant="standard"
              sx={{
                m: 1,
                minWidth: 120,
                "margin-left": "30px",
                "margin-right": "30px",
              }}
            >
              <InputLabel id="product1-label">Select</InputLabel>
              <Select
                labelId="region1-label"
                id="region1-label"
                label="Destination"
                value={selectedDestination.name}
                onChange={(e) => handleSetDestination(e)}
              >
                {availableDestinations.map((item, index) => (
                  <MenuItem value={index}>
                    {item.number + " - " + item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <MDBox
              mx={2}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="white"
              borderRadius="lg"
            ></MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  function renderCarriers() {
    return (
      <>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={3}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="dark"
              borderRadius="lg"
              coloredShadow="dark"
            >
              <MDTypography variant="h5" color="white">
                Carrier
              </MDTypography>
            </MDBox>
            <FormControl
              variant="standard"
              sx={{
                m: 1,
                minWidth: 120,
                "margin-left": "30px",
                "margin-right": "30px",
              }}
            >
              <InputLabel id="carrier-label">Select</InputLabel>
              <Select
                labelId="carrier-label"
                id="carrier-label"
                label="Carrier"
                value={selectedCarrier.name}
                onChange={(e) => handleSetCarrier(e)}
              >
                {availableCarriers.map((item, index) => (
                  <MenuItem value={index}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <MDBox
              mx={2}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="white"
              borderRadius="lg"
            ></MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  function renderConfirmCard() {
    return (
      <>
        <Grid item xs={12} md={12} lg={12}>
          <MDBox mb={1.5}>
            <Card>
              <MDBox pt={3}>
                <MDTypography
                  align="center"
                  display="block"
                  variant="h6"
                  color="dark"
                  fontWeight="medium"
                >
                  {selectedOrigin.name + " to " + selectedDestination.name}
                </MDTypography>
              </MDBox>
              <MDBox pt={3} sx={textboxStyle}>
                <DataTable_Warehouses
                  table={{ w_columns, w_rows }}
                  isSorted={false}
                />
              </MDBox>
              <MDBox pt={3}>
                <MDTypography
                  align="center"
                  display="block"
                  variant="h6"
                  color="dark"
                  fontWeight="medium"
                >
                  {"Carrier - " + selectedCarrier.name}
                </MDTypography>
              </MDBox>
              <MDInput
                sx={textboxStyle}
                label="P.O. #"
                onChange={(e) => setTransferPO(e.target.value)}
              ></MDInput>
              <MDInput
                sx={textboxStyle}
                label="Appointment #"
                onChange={(e) => setApptNum(e.target.value)}
              ></MDInput>
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  minWidth: 120,
                  "margin-left": "30px",
                  "margin-right": "30px",
                }}
              >
                <MDTypography variant="caption">Time</MDTypography>
                <TimePicker onChange={onChangeTime} value={timeValue} />
              </FormControl>

              <TextField
                sx={textboxStyle}
                id="date"
                label="Date"
                type="date"
                defaultValue={dateValue}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setDateValue(e.target.value)}
              />

              <MDBox
                mx={2}
                mt={0}
                py={3}
                px={2}
                variant="gradient"
                bgColor="white"
                borderRadius="lg"
              ></MDBox>
            </Card>
          </MDBox>
        </Grid>
      </>
    );
  }

  return (
    <>
      {currentUser ? (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox py={3}>{renderStepper()}</MDBox>
          <Footer />
        </DashboardLayout>
      ) : (
        <HandleLogout />
      )}
    </>
  );
}
