import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { collection, addDoc } from "firebase/firestore";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDButton from "../../ui-components/MDButton";
import ColorPicker from "material-ui-color-picker";
import DataTable_Generic from "../Tables/DataTable_Generic";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useDBManager } from "../../contexts/DBManager_Context";
import productsTableData from "../data/productData";
import HandleLogout from "../HandleLogout";

export default function Product_Manager() {
  let date = new Date();
  let [month, day, year] = [
    String(date.getMonth() + 1),
    String(date.getDate()),
    String(date.getFullYear()),
  ];

  const { updateFields } = useDBManager();
  const { state, fetchCollection } = useDBManager();
  const { currentUser } = useAuth();
  const [imageUpload, setImageUpload] = useState(null);
  const navigate = useNavigate();

  let [newProductName, setNewProductName] = useState("");
  let [newProductBudCode, setNewProductBudCode] = useState(0);

  let [newBatchNum, setNewBatchNum] = useState("");
  let [selectedProduct, setSelectedProduct] = useState(0);
  let [newPO, setNewPO] = useState("");

  let [selectedWarehouse, setSelectedWarehouse] = useState({});
  let [selectedQuantity, setSelectedQuantity] = useState(0);

  let [altName1, setAltName1] = useState("");
  let [altName2, setAltName2] = useState("");
  let [altName3, setAltName3] = useState("");
  let [altBudCode1, setAltBudCode1] = useState("");
  let [altBudCode2, setAltBudCode2] = useState("");
  let [altBudCode3, setAltBudCode3] = useState("");
  let [tcsMatCode, setTcsMatCode] = useState("");
  let [caseSize, setCaseSize] = useState(24);
  let [color, setColor] = useState("#000000");

  const [dateValue, setDateValue] = useState(new Date());

  const { columns, rows } = productsTableData(state.products, openProductPage);
  const imageStyle = { width: "50px", height: "50px" };
  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };

  useEffect(() => {
    updateFields(["products", "warehouses", "users"]);
  }, []);

  function openProductPage(docID) {
    navigate("/products/" + docID);
  }

  const onCreateProduct = () => {
    //Create new product
    if (
      newProductName != "" &&
      newProductBudCode != "" &&
      imageUpload != null
    ) {
      const imageRef = ref(storage, `images/products/${newProductBudCode}`);
      uploadBytes(imageRef, imageUpload).then((response) => {
        alert("Image uploaded");
        getDownloadURL(response.ref).then((link) => {
          const productsCollectionRef = collection(db, "Products");
          addDoc(productsCollectionRef, {
            name: newProductName,
            bud: newProductBudCode,
            url: link,
            altName1: altName1,
            altBudCode1: altBudCode1,
            altName2: altName2,
            altBudCode2: altBudCode2,
            altName3: altName3,
            altBudCode3: altBudCode3,
            caseSize: caseSize,
            tcsMatCode: tcsMatCode,
            color: color,
          });
        });
      });
      updateFields(["products"]);
      setNewProductName((prev) => "");
      setNewProductBudCode((prev) => "");
      setAltName1((prev) => "");
      setAltBudCode1((prev) => "");
      setAltName2((prev) => "");
      setAltBudCode2((prev) => "");
      setAltName3((prev) => "");
      setAltBudCode3((prev) => "");
      setColor((prev) => "#000000");
    }
  };

  function onAddBatch() {
    //Add Batch
    let date = new Date();
    date = Moment().format();
    date = date.slice(0, -15);
    let exists = false;

    fetchCollection(
      collection(db, "Warehouses", selectedWarehouse.id, "products")
    ).then((data) => {
      let warehouseProducts = data;

      warehouseProducts.forEach((item, index) => {
        if (item.name == selectedProduct.name && item.batch == newBatchNum) {
          console.log("Already exists");
          exists = true;
        }
      });

      if (selectedProduct != null && newBatchNum != "" && exists == false) {
        //Add Stock Adjustment record
        let collRef = collection(
          db,
          "Stock",
          "Adjustments",
          "Warehouses",
          selectedWarehouse.name,
          year,
          month,
          day
        );
        addDoc(collRef, {
          location: selectedWarehouse.name,
          product: {
            name: selectedProduct.name,
            batch: newBatchNum,
            new_quantity: selectedQuantity,
            old_quantity: 0,
            expiry: dateValue,
          },
          timestamp: date,
          type: "ADD",
        });
        console.log(state);

        //Add batch to warehouse inventory
        const product = {
          name: selectedProduct.name,
          batch: newBatchNum,
          quantity: selectedQuantity,
          expiry: dateValue,
        };
        let w_collRef = collection(
          db,
          "Warehouses",
          state.warehouses[state.warehouses.indexOf(selectedWarehouse)].id,
          "products"
        );
        addDoc(w_collRef, product);

        //Add Batch record to Product
        const productsCollRef = collection(
          db,
          "Products",
          state.products[state.products.indexOf(selectedProduct)].id,
          "Batches"
        );
        addDoc(productsCollRef, {
          batch_num: newBatchNum,
          quantity: selectedQuantity,
          dateAdded: date,
          expiry: dateValue,
          PO: newPO,
          name: selectedProduct.name,
        });
        alert("New Batch Added.");
      }
    });
  }

  return (
    <>
      {currentUser ? (
        <Container>
          <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={3}
                      mt={0}
                      py={2}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Products
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={3}>
                      <DataTable_Generic
                        table={{ columns, rows }}
                        isSorted={false}
                        entriesPerPage={true}
                        showTotalEntries={true}
                        noEndBorder
                      />
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>

              <br />
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={3}
                    mt={0}
                    py={2}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Add New Product
                    </MDTypography>
                  </MDBox>
                  <TextField
                    align="center"
                    sx={textboxStyle}
                    size="small"
                    id="newProductName"
                    variant="outlined"
                    placeholder="Product Name"
                    onChange={(e) => {
                      setNewProductName(e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    sx={textboxStyle}
                    size="small"
                    id="newProductBudCode"
                    variant="outlined"
                    placeholder="Bud Code"
                    onChange={(e) => {
                      setNewProductBudCode(e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    sx={textboxStyle}
                    size="small"
                    id="altName1"
                    variant="outlined"
                    placeholder="Alt Name 1"
                    onChange={(e) => {
                      setAltName1(e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    sx={textboxStyle}
                    size="small"
                    id="altBudCode1"
                    variant="outlined"
                    placeholder="Alt Bud Code 1"
                    onChange={(e) => {
                      setAltBudCode1(e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    sx={textboxStyle}
                    size="small"
                    id="altName2"
                    variant="outlined"
                    placeholder="Alt Name 2"
                    onChange={(e) => {
                      setAltName2(e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    sx={textboxStyle}
                    size="small"
                    id="altBudCode2"
                    variant="outlined"
                    placeholder="Alt Bud Code 2"
                    onChange={(e) => {
                      setAltBudCode2(e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    sx={textboxStyle}
                    size="small"
                    id="altName3"
                    variant="outlined"
                    placeholder="Alt Name 3"
                    onChange={(e) => {
                      setAltName3(e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    sx={textboxStyle}
                    size="small"
                    id="altBudCode3"
                    variant="outlined"
                    placeholder="Alt Bud Code 3"
                    onChange={(e) => {
                      setAltBudCode3(e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    sx={textboxStyle}
                    size="small"
                    id="tcsMatCode"
                    variant="outlined"
                    placeholder="TCS Material Code (Optional)"
                    onChange={(e) => {
                      setTcsMatCode(e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    sx={textboxStyle}
                    size="small"
                    id="caseSize"
                    variant="outlined"
                    placeholder="Case Size"
                    onChange={(e) => {
                      setCaseSize(e.target.value);
                    }}
                  ></TextField>
                  <MDBox sx={textboxStyle}>
                    <ColorPicker
                      name="color"
                      defaultValue="#00000"
                      value={color}
                      onChange={(value) => setColor(value)}
                    />
                  </MDBox>
                  <InputLabel sx={textboxStyle}>
                    <input
                      type="file"
                      onChange={(e) => {
                        setImageUpload(e.target.files[0]);
                      }}
                    />
                  </InputLabel>
                  <MDButton
                    align="center"
                    sx={textboxStyle}
                    color="success"
                    variant="contained"
                    onClick={onCreateProduct}
                  >
                    Create Product
                  </MDButton>
                  <MDBox
                    mx={2}
                    mt={0}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="white"
                    borderRadius="lg"
                  ></MDBox>
                </Card>
              </Grid>

              <br />
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={3}
                    mt={0}
                    py={2}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Add Batch
                    </MDTypography>
                  </MDBox>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel
                      sx={{
                        "margin-left": "30px",
                        "margin-right": "30px",
                      }}
                      id="product-label"
                    >
                      Select Product
                    </InputLabel>
                    <Select
                      sx={textboxStyle}
                      labelId="product-label"
                      id="product-label"
                      label="Product"
                      value={selectedProduct.name}
                      onChange={(e) =>
                        setSelectedProduct(state.products[e.target.value])
                      }
                    >
                      {state.products.map((item, index) => (
                        <MenuItem value={index}>
                          <img style={imageStyle} src={item.url} alt="" />{" "}
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel
                      sx={{
                        "margin-left": "30px",
                        "margin-right": "30px",
                      }}
                      id="warehouse-label"
                    >
                      Select Warehouse
                    </InputLabel>
                    <Select
                      sx={textboxStyle}
                      labelId="warehouse-label"
                      id="warehouse-label"
                      label="Warehouse"
                      value={selectedWarehouse.name}
                      onChange={(e) =>
                        setSelectedWarehouse(state.warehouses[e.target.value])
                      }
                    >
                      {state.warehouses.map((item, index) => (
                        <MenuItem value={index}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    sx={textboxStyle}
                    align="center"
                    size="small"
                    id="newBatchNumber"
                    variant="outlined"
                    placeholder="Batch Number"
                    onChange={(e) => {
                      setNewBatchNum(e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    sx={textboxStyle}
                    align="center"
                    size="small"
                    id="newQuantity"
                    variant="outlined"
                    placeholder="Quantity"
                    onChange={(e) => {
                      setSelectedQuantity(e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    sx={textboxStyle}
                    id="date"
                    label="Expiry Date"
                    type="date"
                    defaultValue={Moment().format()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setDateValue(e.target.value)}
                  />
                  <TextField
                    sx={textboxStyle}
                    align="center"
                    size="small"
                    id="newPO"
                    variant="outlined"
                    placeholder="P.O. #"
                    onChange={(e) => {
                      setNewPO(e.target.value);
                    }}
                  ></TextField>
                  <MDButton
                    sx={textboxStyle}
                    align="center"
                    color="success"
                    variant="contained"
                    onClick={onAddBatch}
                  >
                    Add Batch
                  </MDButton>
                  <MDBox
                    mx={2}
                    mt={0}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="white"
                    borderRadius="lg"
                  ></MDBox>
                </Card>
              </Grid>
            </MDBox>
            <Footer />
          </DashboardLayout>
        </Container>
      ) : (
        <HandleLogout />
      )}
    </>
  );
}
