import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Alert,
  CardContent,
  Step,
  StepLabel,
  CardMedia,
  Checkbox,
  FormControlLabel,
  Button,
  Stepper,
  FormControl,
  TextField,
} from "@mui/material";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { collection, addDoc } from "firebase/firestore";
import TimePicker from "react-time-picker";
import { useDBManager } from "../../contexts/DBManager_Context";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import DataTable_Generic from "../Tables/DataTable_Generic";
import warehouseProductData from "../../components/data/warehouseProductData";
import machineProductData from "../../components/data/machineProductData";
import driversTableData from "../../components/data/run_driverData";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import MDButton from "../../ui-components/MDButton";
import HandleLogout from "../HandleLogout";

export default function Run_Build() {
  let date = new Date();
  const { currentUser } = useAuth();
  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };
  const { updateFields } = useDBManager();
  const { state, fetchCollection } = useDBManager();

  const steps = ["Region", "Warehouse", "Machines", "Driver", "Confirm Run"];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState({});
  const [selectedDriver, setSelectedDriver] = useState({});
  const [dateValue, setDateValue] = useState("");
  const [timeValue, onChangeTime] = useState("10:00");

  const [availableMachines, setAvailableMachines] = useState([]);
  const [availableMachineObjects, setAvailableMachineObjects] = useState([]);

  const [availableWarehouses, setAvailableWarehouses] = useState([]);
  const [availableWarehouseObjects, setAvailableWarehouseObjects] = useState(
    []
  );
  const [availableDrivers, setAvailableDrivers] = useState([]);

  const [machinesInRun, setMachinesInRun] = useState([]);

  const isStepOptional = (step) => {
    // return step === 2;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (e, item) => {
    e.preventDefault();
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 0) {
      setSelectedRegion((prev) => item);
    } else if (activeStep === 1) {
      setSelectedWarehouse(item);
    } else if (activeStep === 4) {
      submitRun();
    }

    if (activeStep != 4) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setSelectedRegion((prev) => "");
    setSelectedWarehouse((prev) => {});
    setMachinesInRun((prev) => []);
    setAvailableMachineObjects((prev) => []);
    setSelectedDriver((prev) => "");
    setActiveStep(0);
  };

  const handleSetChecked = (event, obj) => {
    let z = machinesInRun;
    if (!machinesInRun.includes(obj)) {
      z.push(obj);
    } else {
      z.splice(machinesInRun.indexOf(obj), 1);
      setMachinesInRun((prev) => z);
    }
  };

  useEffect(() => {
    updateFields(["regions", "products", "warehouses", "machines", "users"]);
  }, []);

  useEffect(() => {
    if (activeStep == 1) {
      let x = [];
      state.warehouses?.forEach((item, index) => {
        if (item.servicing == selectedRegion) {
          x.push(item);
          getWPDocs(item.id);
        }
      });
      setAvailableWarehouses((prev) => [...x]);
    } else if (activeStep == 2) {
      let a = [];
      state.machines?.forEach((item, index) => {
        if (item.region.name == selectedRegion && item.active) {
          a.push(item);
          getMPDocs(item.id);
        }
      });
      setAvailableMachines((prev) => [...a]);
    }
  }, [activeStep]);

  useEffect(() => {
    filterDrivers();
  }, [selectedRegion]);

  function selectADriver(e, driver) {
    setSelectedDriver(driver);
    handleNext(e);
  }

  function filterDrivers() {
    let y = [];
    state.users.forEach((item, index) => {
      item.regions.forEach((region, rndex) => {
        if (region == selectedRegion) {
          y.push(item);
        }
      });
    });
    setAvailableDrivers((prev) => [...y]);
  }

  function getWPDocs(warehouseID) {
    fetchCollection(collection(db, "Warehouses", warehouseID, "products")).then(
      (data) => {
        let x = availableWarehouseObjects;
        x.push(data);
        setAvailableWarehouseObjects((prev) => [...x]);
      }
    );
  }

  function getMPDocs(machID) {
    fetchCollection(collection(db, "Machines", machID, "plans")).then(
      (data) => {
        let f = assembleSMLabels(data[0]);
        let x = availableMachineObjects;
        x.push(f);
        setAvailableMachineObjects((prev) => [...x]);
      }
    );
  }

  function assembleSMLabels(p) {
    let h = {
      products: [],
      mapping: [],
      maxQuantities: [],
      totalMapping: 0,
      totalMax: 0,
    };

    p.shelves?.forEach((item, index) => {
      if (!h.products.includes(item.productCode)) {
        h.products.push(item.productCode);
        h.mapping.push(item.currentStock);
        h.maxQuantities.push(item.maxStock);
        h.totalMapping = h.totalMapping + item.currentStock;
        h.totalMax = h.totalMax + item.maxStock;
      } else {
        let x = h.mapping[h.products.indexOf(item.productCode)];
        x = x + item.currentStock;
        h.mapping[h.products.indexOf(item.productCode)] = x;

        let y = h.maxQuantities[h.products.indexOf(item.productCode)];
        y = y + item.maxStock;
        h.maxQuantities[h.products.indexOf(item.productCode)] = y;

        h.totalMapping = h.totalMapping + item.currentStock;
        h.totalMax = h.totalMax + item.maxStock;
      }
    });

    h.products.forEach((item, index) => {
      state.products.forEach((entry, kndex) => {
        if (item == entry.bud) {
          h.products[index] = entry.name;
        }
      });
    });
    return h;
  }

  async function submitRun() {
    if (dateValue != "") {
      let promises = [];
      const locRef = collection(db, "PreRun Data");
      promises.push(
        await addDoc(locRef, {
          machines: machinesInRun,
          warehouse: selectedWarehouse,
          driver: selectedDriver,
          region: selectedRegion,
          author: state.currentUserData,
          timestamp: date,
          machineCount: machinesInRun.length,
          runBeginTime: timeValue,
          runBeginDate: dateValue,
        })
      );
      return Promise.all(promises).then((x) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      });
    } else {
      alert("Please select a date");
    }
  }

  function renderStepper() {
    return (
      <>
        <MDBox sx={{ width: "100%" }}>
          <Stepper
            activeStep={activeStep}
            sx={{ "margin-bottom": "10px" }}
            alternativeLabel
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <MDTypography variant="caption">Optional</MDTypography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <MDTypography sx={{ mt: 2, mb: 1 }}>
                Run has been sent to the driver
              </MDTypography>
              <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <MDBox sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </MDBox>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* PUT ALL CONTENT IN BETWEEN STEPPER HERE */}
              {activeStep === 0 ? (
                <Grid container spacing={3}>
                  {renderRegionCards()}
                </Grid>
              ) : activeStep === 1 ? (
                <Grid container spacing={3}>
                  {renderWarehouseCards()}
                </Grid>
              ) : activeStep === 2 ? (
                <Grid container spacing={3}>
                  {renderMachineCards()}
                </Grid>
              ) : activeStep === 3 ? (
                <Grid container spacing={3}>
                  {renderDriverCard()}
                </Grid>
              ) : activeStep === 4 ? (
                <Grid container spacing={3}>
                  {renderConfirmCard()}
                </Grid>
              ) : null}

              {/* END OF CONTENT ZONE */}
              <MDBox sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleReset}
                  sx={{ mr: 1 }}
                >
                  Start Over
                </Button>
                <MDBox sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
              </MDBox>
            </React.Fragment>
          )}
        </MDBox>
      </>
    );
  }

  function renderRegionCards() {
    return (
      <>
        {state.regions?.map((item, index) => (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Card>
                <CardHeader subheader={item.name} />
                <CardMedia
                  component="img"
                  height="140"
                  image={item.imageURL}
                  alt=""
                />
                <CardContent>
                  <Grid item xs={12} md={12} lg={12}>
                    <MDBox mb={0}>
                      <MDButton
                        color="error"
                        variant="contained"
                        fullWidth
                        onClick={(e) => handleNext(e, item.name)}
                      >
                        <MDTypography
                          display="block"
                          variant="caption"
                          color="white"
                          fontWeight="medium"
                        >
                          Next
                        </MDTypography>
                      </MDButton>
                    </MDBox>
                  </Grid>
                </CardContent>
              </Card>
            </MDBox>
          </Grid>
        ))}
      </>
    );
  }

  function renderWarehouseCards() {
    return (
      <>
        {availableWarehouseObjects?.map((item, index) => (
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <Card>
                <CardHeader subheader={availableWarehouses[index].name} />
                <CardContent>
                  <MDBox pt={3}>
                    <DataTable_Generic
                      table={warehouseProductData(item, state.products)}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                  <Grid item xs={12} md={12} lg={12}>
                    <MDBox mb={0}>
                      <MDButton
                        color="error"
                        variant="contained"
                        fullWidth
                        onClick={(e) =>
                          handleNext(e, availableWarehouses[index])
                        }
                      >
                        <MDTypography
                          display="block"
                          variant="caption"
                          color="white"
                          fontWeight="medium"
                        >
                          Next
                        </MDTypography>
                      </MDButton>
                    </MDBox>
                  </Grid>
                </CardContent>
              </Card>
            </MDBox>
          </Grid>
        ))}
      </>
    );
  }

  function renderMachineCards() {
    return (
      <>
        <Grid item xs={12} md={12} lg={12}>
          <MDBox mb={0}>
            <MDButton
              color="error"
              variant="contained"
              fullWidth
              onClick={(e) => handleNext(e)}
            >
              <MDTypography
                display="block"
                variant="caption"
                color="white"
                fontWeight="medium"
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </MDTypography>
            </MDButton>
          </MDBox>
        </Grid>
        {availableMachineObjects?.map((item, index) => (
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <Card>
                <CardHeader subheader={availableMachines[index].name} />
                <CardContent>
                  <MDBox pt={3}>
                    <DataTable_Generic
                      table={machineProductData(item, state.products)}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                  <MDTypography align="center" variant="h6" noWrap={true}>
                    {"Total --- " + item.totalMapping + " / " + item.totalMax}
                  </MDTypography>
                  <Alert severity="info">
                    <FormControlLabel
                      label="Add To Run"
                      control={
                        <Checkbox
                          onChange={(e) =>
                            handleSetChecked(e, availableMachines[index])
                          }
                        />
                      }
                    />
                  </Alert>
                </CardContent>
              </Card>
            </MDBox>
          </Grid>
        ))}
      </>
    );
  }

  function renderDriverCard() {
    return (
      <>
        <Grid item xs={0} md={3} lg={3} />
        <Grid item xs={12} md={6} lg={6}>
          <MDBox mb={1.5}>
            <Card>
              <CardHeader subheader={"Available Drivers"} />
              <CardContent>
                <MDBox pt={3}>
                  <DataTable_Generic
                    table={driversTableData(availableDrivers, selectADriver)}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </CardContent>
            </Card>
          </MDBox>
        </Grid>
      </>
    );
  }

  function renderConfirmCard() {
    return (
      <>
        <Grid item xs={12} md={12} lg={12}>
          <MDBox mb={1.5}>
            <Card>
              <MDBox pt={3}>
                <MDTypography
                  align="center"
                  display="block"
                  variant="h6"
                  color="dark"
                  fontWeight="medium"
                >
                  {selectedRegion}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <MDTypography
                  align="center"
                  display="block"
                  variant="h6"
                  color="dark"
                  fontWeight="medium"
                >
                  {selectedWarehouse.name}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <MDTypography
                  align="center"
                  display="block"
                  variant="h6"
                  color="dark"
                  fontWeight="medium"
                >
                  {"Driver - " + selectedDriver.name}
                </MDTypography>
              </MDBox>
              {machinesInRun?.map((item, index) => (
                <MDBox pt={3}>
                  <MDTypography
                    align="center"
                    display="block"
                    variant="h6"
                    color="dark"
                    fontWeight="medium"
                  >
                    {item.name}
                  </MDTypography>
                </MDBox>
              ))}
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  minWidth: 120,
                  "margin-left": "30px",
                  "margin-right": "30px",
                }}
              >
                <MDTypography variant="caption">Time</MDTypography>
                <TimePicker onChange={onChangeTime} value={timeValue} />
              </FormControl>

              <TextField
                sx={textboxStyle}
                id="date"
                label="Date"
                type="date"
                defaultValue={dateValue}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setDateValue(e.target.value)}
              />
              <MDBox
                mx={2}
                mt={0}
                py={3}
                px={2}
                variant="gradient"
                bgColor="white"
                borderRadius="lg"
              >
                <MDBox mb={0}>
                  <MDButton
                    fullWidth
                    color="error"
                    variant="contained"
                    onClick={(e) => handleNext(e)}
                  >
                    <MDTypography
                      display="block"
                      variant="caption"
                      color="white"
                      fontWeight="medium"
                    >
                      Confirm Run
                    </MDTypography>
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </MDBox>
        </Grid>
      </>
    );
  }

  return (
    <>
      {currentUser ? (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox py={3}>{renderStepper()}</MDBox>
          <Footer />
        </DashboardLayout>
      ) : (
        <HandleLogout />
      )}
    </>
  );
}
