// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar"
import MDBadge from "../../ui-components/MDBadge"
import MDButton from "../../ui-components/MDButton";
import { useNavigate } from "react-router-dom"

export default function data(warehouseData, openWarehousePage) {

  const Author = ({ region, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {region}
        </MDTypography>
        <MDTypography variant="caption">{name}</MDTypography>
      </MDBox>
    </MDBox>
  );

  let w_rows = []
  warehouseData.map((mach, index) => {
    // console.log(mach)
    w_rows.push({
        author: <Author region={mach.name} name={mach.servicing} />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <MDButton onClick={(e) => openWarehousePage(mach.id)} size="small">View</MDButton>
        ),
    })
    // console.log(w_rows)
  })

  return {
    w_columns: [
      { Header: "region", accessor: "author", width: "45%", align: "left" },
    //   { Header: "status", accessor: "author", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    w_rows
  };
}
