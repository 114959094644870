// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDBadge from "../../ui-components/MDBadge"
import MDButton from "../../ui-components/MDButton";

export default function data(warehouseData, products) {
    const imageStyle = { width: '50px', height: '50px'}

    const Author = ({ name, url }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <img style={imageStyle} src={url} />
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {name}
            </MDTypography>
          </MDBox>
        </MDBox>
      );
    
      const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {title}
          </MDTypography>
          <MDTypography variant="caption">{description}</MDTypography>
        </MDBox>
      );

      function matchImages(entry) {
        let x = ""
        products.forEach((item, index) => {
          if (entry.name == item.name )
          if (item.name == entry.name || item.altName1 == entry.name || item.altName2 == entry.name || item.altName3 == entry.name) {
            x = item.url
          }
        })
        return x
      }
    
      let mi_rows = []
      warehouseData?.map((item, index) => {
        mi_rows.push({
            author: <Author name={item.name} url={matchImages(item)}/>,
            function: <Job title={item.quantity} description="" />,
        })
      })
    
      return {
        mi_columns: [
          { Header: "Name", accessor: "author", width: "45%", align: "left" },
          { Header: "Quantity", accessor: "function", align: "left" },
        ],
        mi_rows
      };
}
