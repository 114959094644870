import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Card,
  TextField,
  Divider,
} from "@mui/material";
import { CSVLink } from "react-csv";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { collection, getDocs } from "firebase/firestore";
import HandleLogout from "../HandleLogout";
import MDButton from "../../ui-components/MDButton";
import { useDBManager } from "../../contexts/DBManager_Context";
import { useNavigate } from "react-router-dom";
import DataTable_Generic from "../Tables/DataTable_Generic";
import Moment from "moment";
import writeoffs_multiple from "../reportData/writeoffs_multiple";
import writeoffs_single from "../reportData/writeoffs_single";

export default function WriteOffs() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };
  const { state, fetchCollection } = useDBManager();
  const { updateFields } = useDBManager();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [woCsvData, setWOCsvData] = useState([]);
  const [startDateValue, setStartDateValue] = useState(new Date());
  const [endDateValue, setEndDateValue] = useState(new Date());
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [availableLocations, setAvailableLocations] = useState([]);

  useEffect(() => {
    updateFields(["machines", "products", "warehouses", "reasons"]);
  }, []);

  useEffect(() => {
    let x = [];
    state.machines.forEach((element) => {
      x.push(element);
    });
    state.warehouses.forEach((element) => {
      x.push(element);
    });
    setAvailableLocations((prev) => x);
  }, [state]);

  const handleSetChecked = (event, obj) => {
    let z = selectedLocations;
    if (!selectedLocations.includes(obj)) {
      z.push(obj);
    } else {
      z.splice(selectedLocations.indexOf(obj), 1);
      setSelectedLocations((prev) => z);
    }
  };

  const handleSelectAll = (event) => {
    if (selectedLocations.length == 0) {
      setSelectedLocations((prev) => availableLocations);
    } else {
      setSelectedLocations((prev) => []);
    }
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    fetchWriteOffs();
  };

  async function fetchWriteOffs() {
    let wRecords = [];
    const requests = selectedLocations.map(async (machine) => {
      let x_dates = getDates(new Date(startDateValue), new Date(endDateValue));
      let localData = [];
      const localRequests = x_dates.map(async (item, index) => {
        let x_year = String(item.getFullYear());
        let x_month = parseInt(item.getMonth()) + 1;
        let x_day = String(item.getDate());
        if (parseInt(x_month) < 10) {
          x_month = "0" + String(x_month);
        }
        if (parseInt(x_day) < 10) {
          x_day = "0" + String(x_day);
        }
        if (machine.number == "Warehouse") {
          return await getDocs(
            collection(db, "Write-Offs", machine.name, x_year, x_month, x_day)
          ).then((response) => {
            response.forEach((doc) => {
              let id = doc.id;
              let data = doc.data();
              data.loc = machine.number + " - " + machine.name;
              localData.push({ id, ...data });
            });
          });
        } else {
          return await getDocs(
            collection(db, "Write-Offs", machine.number, x_year, x_month, x_day)
          ).then((response) => {
            response.forEach((doc) => {
              let id = doc.id;
              let data = doc.data();
              data.loc = machine.number + " - " + machine.name;
              localData.push({ id, ...data });
            });
          });
        }
      });
      return Promise.all(localRequests).then(() => {
        localData.map((item, index) => {
          wRecords.push(item);
        });
      });
    });
    return Promise.all(requests).then(() => {
      processWriteOffs(wRecords);
    });
  }

  function processWriteOffs(data) {
    let homogenizedWriteOffs = [];
    console.log(data);
    if (selectedLocations.length > 1) {
      selectedLocations.forEach((location, index) => {
        let dataStructure = {
          location: {},
          products: [],
          quantities: [],
        };
        dataStructure.location = location;
        data.forEach((item, index) => {
          if (location.name == item.location.name) {
            if (!dataStructure.products.includes(item.product)) {
              dataStructure.products.push(item.product);
              dataStructure.quantities.push(item.quantity);
            } else {
              dataStructure.quantities[
                dataStructure.products.indexOf(item.product)
              ] += item.quantity;
            }
          }
        });
        homogenizedWriteOffs.push(dataStructure);
      });
      setColumns(writeoffs_multiple(homogenizedWriteOffs).columns);
      setRows(writeoffs_multiple(homogenizedWriteOffs).rows);
      setWOCsvData(writeoffs_multiple(homogenizedWriteOffs).csvData);
    } else {
      state.reasons.forEach((reason, index) => {
        let dataStructure = {
          reason: [],
          products: [],
          quantities: [],
        };
        dataStructure.reason = reason.text;
        data.forEach((item, index) => {
          if (reason.text == item.reason) {
            if (!dataStructure.products.includes(item.product)) {
              dataStructure.products.push(item.product);
              dataStructure.quantities.push(item.quantity);
            } else {
              dataStructure.quantities[
                dataStructure.products.indexOf(item.product)
              ] += item.quantity;
            }
          }
        });
        homogenizedWriteOffs.push(dataStructure);
      });
      setColumns(writeoffs_single(homogenizedWriteOffs).columns);
      setRows(writeoffs_single(homogenizedWriteOffs).rows);
      setWOCsvData(writeoffs_single(homogenizedWriteOffs).csvData);
    }
  }

  function getDates(start, stop) {
    let dateArray = new Array();
    let currentDate = start;
    while (currentDate <= stop) {
      currentDate.setDate(currentDate.getDate() + 1);
      dateArray.push(new Date(currentDate));
    }
    return dateArray;
  }

  function renderTable() {
    if (columns != undefined) {
      return (
        <>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Report
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable_Generic
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                />
              </MDBox>
              <MDButton>
                <CSVLink
                  filename={startDateValue + "---" + endDateValue}
                  data={woCsvData}
                >
                  Download CSV
                </CSVLink>
                ;
              </MDButton>
            </Card>
          </Grid>
        </>
      );
    }
  }

  return (
    <>
      {currentUser ? (
        <Container>
          <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={5}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={12} lg={12}>
                  <Card>
                    <MDBox
                      mx={0}
                      mt={0}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="dark"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Write Off Analysis
                      </MDTypography>
                    </MDBox>
                    <Grid container>
                      <Grid item xs={1} />
                      <Grid item xs={10}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{ "margin-left": "20px" }}
                              onChange={(e) => handleSelectAll(e)}
                            />
                          }
                          label="All Locations"
                        />
                        <Divider />
                        <div
                          style={{
                            maxHeight: 400,
                            overflow: "auto",
                            "background-color": "white",
                          }}
                        >
                          <FormGroup>
                            {availableLocations.map((machine) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    style={{ "margin-left": "20px" }}
                                    onChange={(e) =>
                                      handleSetChecked(e, machine)
                                    }
                                  />
                                }
                                label={machine.number + " - " + machine.name}
                                style={{}}
                              />
                            ))}
                          </FormGroup>
                        </div>

                        <TextField
                          sx={textboxStyle}
                          id="start-date"
                          label="Start Date"
                          type="date"
                          defaultValue={Moment().format()}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => setStartDateValue(e.target.value)}
                        />
                        <TextField
                          sx={textboxStyle}
                          id="end-date"
                          label="End Date"
                          type="date"
                          defaultValue={Moment().format()}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => setEndDateValue(e.target.value)}
                        />
                        <MDButton
                          sx={textboxStyle}
                          align="center"
                          color="dark"
                          variant="contained"
                          onClick={(e) => handleOnClick(e)}
                        >
                          Analyze
                        </MDButton>
                      </Grid>
                    </Grid>
                    <MDBox
                      mx={2}
                      mt={0}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="white"
                      borderRadius="lg"
                    ></MDBox>
                  </Card>
                </Grid>
                {renderTable()}
              </Grid>
            </MDBox>
            <Footer />
          </DashboardLayout>
        </Container>
      ) : (
        <HandleLogout />
      )}
    </>
  );
}
