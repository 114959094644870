import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  TextField,
  Container,
  Card,
  InputLabel,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import { db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDButton from "../../ui-components/MDButton";
import { storage } from "../../firebase";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import ColorPicker from "material-ui-color-picker";
import { useDBManager } from "../../contexts/DBManager_Context";

export default function Product_View() {
  let { docID } = useParams();
  const [product, setProduct] = useState({});

  const { fetchDocument } = useDBManager();

  const [newName, setNewName] = useState("");
  const [newBudCode, setNewBudCode] = useState("");
  const [newAltName1, setNewAltName1] = useState("");
  const [newAltBudCode1, setNewAltBudCode1] = useState("");
  const [newAltName2, setNewAltName2] = useState("");
  const [newAltBudCode2, setNewAltBudCode2] = useState("");
  const [newAltName3, setNewAltName3] = useState("");
  const [newAltBudCode3, setNewAltBudCode3] = useState("");
  const [newCaseSize, setNewCaseSize] = useState("");
  const [newTcsMatCode, setNewTcsMatCode] = useState("");
  const [newColor, setNewColor] = useState("#000000");
  const [newActive, setNewActive] = useState(false);

  const [imageUpload, setImageUpload] = useState(null);
  const imageStyle = { width: "100px", height: "100px" };
  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };

  useEffect(() => {
    //Get product
    fetchDocument(doc(db, "Products", docID)).then((data) => {
      setProduct(data);
      setNewName(data.name);
      setNewBudCode(data.bud);
      setNewAltName1(data.altName1);
      setNewAltBudCode1(data.altBudCode1);
      setNewAltName2(data.altName2);
      setNewAltBudCode2(data.altBudCode2);
      setNewAltName3(data.altName3);
      setNewAltBudCode3(data.altBudCode3);
      setNewCaseSize(data.caseSize);
      setNewColor(data.color);
      setNewTcsMatCode(data.tcsMatCode);
      setNewActive(data.active);
    });
  }, []);

  const onUpdateProduct = () => {
    //update product
    if (imageUpload != null) {
      const imageRef = ref(storage, `images/products/${product.bud}`);
      deleteObject(imageRef).then(() => {
        let newImageRef = ref(storage, `images/products/${newBudCode}`);
        uploadBytes(newImageRef, imageUpload).then((response) => {
          alert("Product Updated");
          getDownloadURL(response.ref).then((link) => {
            const productDocRef = doc(db, "Products", docID);
            updateDoc(productDocRef, {
              name: newName,
              bud: newBudCode,
              url: link,
              altName1: newAltName1,
              altBudCode1: newAltBudCode1,
              altName2: newAltName2,
              altBudCode2: newAltBudCode2,
              altName3: newAltName3,
              altBudCode3: newAltBudCode3,
              tcsMatCode: newTcsMatCode,
              caseSize: newCaseSize,
              color: newColor,
              active: newActive,
            });
          });
        });
      });
    } else {
      const productDocRef = doc(db, "Products", docID);
      updateDoc(productDocRef, {
        name: newName,
        bud: newBudCode,
        url: product.url,
        altName1: newAltName1,
        altBudCode1: newAltBudCode1,
        altName2: newAltName2,
        altBudCode2: newAltBudCode2,
        altName3: newAltName3,
        altBudCode3: newAltBudCode3,
        tcsMatCode: newTcsMatCode,
        caseSize: newCaseSize,
        color: newColor,
        active: newActive,
      });
      alert("Product Updated");
    }

    fetchDocument(doc(db, "Products", docID)).then((data) => {
      setProduct(data);
      setNewName(data.name);
      setNewBudCode(data.bud);
      setNewAltName1(data.altName1);
      setNewAltBudCode1(data.altBudCode1);
      setNewAltName2(data.altName2);
      setNewAltBudCode2(data.altBudCode2);
      setNewAltName3(data.altName3);
      setNewAltBudCode3(data.altBudCode3);
      newTcsMatCode(data.tcsMatCode);
      setNewCaseSize(data.caseSize);
      setNewColor(data.color);
      setNewActive(data.active);
    });
  };

  return (
    <>
      <Container>
        <DashboardLayout>
          <DashboardNavbar />
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={3}
                mt={0}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  Product Info
                </MDTypography>
              </MDBox>
              <TextField
                align="center"
                sx={textboxStyle}
                size="small"
                id="newProductName"
                variant="outlined"
                placeholder={`Name - ${product.name}`}
                onChange={(e) => {
                  setNewName(e.target.value);
                }}
              ></TextField>
              <TextField
                sx={textboxStyle}
                size="small"
                id="newProductBudCode"
                variant="outlined"
                placeholder={`Bud Code - ${product.bud}`}
                onChange={(e) => {
                  setNewBudCode(e.target.value);
                }}
              ></TextField>
              <TextField
                sx={textboxStyle}
                size="small"
                id="altName1"
                variant="outlined"
                placeholder={`Alternate Name 1 - ${product.altName1}`}
                onChange={(e) => {
                  setNewAltName1(e.target.value);
                }}
              ></TextField>
              <TextField
                sx={textboxStyle}
                size="small"
                id="altBudCode1"
                variant="outlined"
                placeholder={`Alternate Bud Code 1 - ${product.altBudCode1}`}
                onChange={(e) => {
                  setNewAltBudCode1(e.target.value);
                }}
              ></TextField>
              <TextField
                sx={textboxStyle}
                size="small"
                id="altName2"
                variant="outlined"
                placeholder={`Alternate Name 2 - ${product.altName2}`}
                onChange={(e) => {
                  setNewAltName2(e.target.value);
                }}
              ></TextField>
              <TextField
                sx={textboxStyle}
                size="small"
                id="altBudCode2"
                variant="outlined"
                placeholder={`Alternate Bud Code 2 - ${product.altBudCode2}`}
                onChange={(e) => {
                  setNewAltBudCode2(e.target.value);
                }}
              ></TextField>
              <TextField
                sx={textboxStyle}
                size="small"
                id="altName3"
                variant="outlined"
                placeholder={`Alternate Name 3 - ${product.altName3}`}
                onChange={(e) => {
                  setNewAltName3(e.target.value);
                }}
              ></TextField>
              <TextField
                sx={textboxStyle}
                size="small"
                id="altBudCode3"
                variant="outlined"
                placeholder={`Alternate Bud Code 3 - ${product.altBudCode3}`}
                onChange={(e) => {
                  setNewAltBudCode3(e.target.value);
                }}
              ></TextField>
              <TextField
                sx={textboxStyle}
                size="small"
                id="tcsMatCode"
                variant="outlined"
                placeholder={`TCS Material Code - ${product.tcsMatCode}`}
                onChange={(e) => {
                  setNewTcsMatCode(e.target.value);
                }}
              ></TextField>
              <TextField
                sx={textboxStyle}
                size="small"
                id="caseSize"
                variant="outlined"
                placeholder={`Case Size - ${product.caseSize}`}
                onChange={(e) => {
                  setNewCaseSize(e.target.value);
                }}
              ></TextField>
              <MDBox sx={textboxStyle}>
                <ColorPicker
                  name="color"
                  defaultValue="#000000"
                  value={newColor}
                  onChange={(value) => setNewColor(value)}
                />
              </MDBox>
              <MDBox sx={textboxStyle}>
                <FormControlLabel
                  control={
                    <Checkbox
                      type="checkbox"
                      checked={newActive}
                      onChange={(e) => {
                        setNewActive(e.target.checked);
                      }}
                    />
                  }
                  label="Active"
                />
              </MDBox>
              <InputLabel sx={textboxStyle}>
                <img style={imageStyle} src={product.url} alt="" />
                <input
                  type="file"
                  onChange={(e) => {
                    setImageUpload(e.target.files[0]);
                  }}
                />
              </InputLabel>
              <MDButton
                align="center"
                sx={textboxStyle}
                color="success"
                variant="contained"
                onClick={onUpdateProduct}
              >
                Update Product Info
              </MDButton>
              <MDBox
                mx={2}
                mt={0}
                py={3}
                px={2}
                variant="gradient"
                bgColor="white"
                borderRadius="lg"
              ></MDBox>
            </Card>
          </Grid>
        </DashboardLayout>
      </Container>
    </>
  );
}
