import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import MDBox from "../../ui-components/MDBox";
import MDButton from "../../ui-components/MDButton";
import MDTypography from "../../ui-components/MDTypography";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import DataTable_Generic from "../Tables/DataTable_Generic";
import Moment from "moment";
import writeOffData from "../data/writeOffData";
import { useDBManager } from "../../contexts/DBManager_Context";
import HandleLogout from "../HandleLogout";

export default function WriteOff_Manager() {
  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };
  const { state } = useDBManager();
  const [selectedRegion, setSelectedRegion] = useState({});
  const [records, setRecords] = useState([]);
  let { columns, rows } = writeOffData(records, state.products);
  const { updateFields } = useDBManager();
  const { currentUser } = useAuth();
  const [editIndex, setEditIndex] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e, num) => {
    e.preventDefault();
    setOpen(false);
    if (num == 1) {
      // const docRef = doc(
      //   db,
      //   unfinishedRuns[editIndex].path,
      //   unfinishedRuns[editIndex].id
      // );
      // deleteDoc(docRef);
    }
  };

  useEffect(() => {
    updateFields(["regions", "machines", "warehouses", "products"]);
  }, []);

  function renderDialog() {
    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Delete Write Off?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this write off? This action cannot
              be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={(e) => handleClose(e, 0)}>Cancel</MDButton>
            <MDButton onClick={(e) => handleClose(e, 1)}>Enter</MDButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  function getDates(start, stop) {
    let dateArray = new Array();
    let currentDate = start;
    while (currentDate <= stop) {
      currentDate.setDate(currentDate.getDate() + 1);
      dateArray.push(new Date(currentDate));
    }
    return dateArray;
  }

  async function fetchWriteOffs() {
    let localLocations = [];
    state.machines?.forEach((machine) => {
      if (machine.region.name == selectedRegion) {
        localLocations.push(machine);
      }
    });
    state.warehouses?.forEach((warehouse) => {
      if (warehouse.servicing == selectedRegion) {
        localLocations.push(warehouse);
      }
    });

    let wRecords = [];
    const requests = localLocations.map(async (machine) => {
      let x_dates = getDates(new Date(startDate), new Date(endDate));
      let localData = [];
      const localRequests = x_dates.map(async (item, index) => {
        let x_year = String(item.getFullYear());
        let x_month = parseInt(item.getMonth()) + 1;
        let x_day = String(item.getDate());
        if (parseInt(x_month) < 10) {
          x_month = "0" + String(x_month);
        }
        if (parseInt(x_day) < 10) {
          x_day = "0" + String(x_day);
        }
        console.log(x_month)
        if (machine.number == "Warehouse") {
          return await getDocs(
            collection(db, "Write-Offs", machine.name, x_year, x_month, x_day)
          ).then((response) => {
            response.forEach((doc) => {
              let id = doc.id;
              let data = doc.data();
              data.loc = machine.number + " - " + machine.name;
              localData.push({ id, ...data });
            });
          });
        } else {
          return await getDocs(
            collection(db, "Write-Offs", machine.number, x_year, x_month, x_day)
          ).then((response) => {
            response.forEach((doc) => {
              let id = doc.id;
              let data = doc.data();
              data.loc = machine.number + " - " + machine.name;
              localData.push({ id, ...data });
            });
          });
        }
      });
      return Promise.all(localRequests).then(() => {
        localData.map((item, index) => {
          wRecords.push(item);
        });
      });
    });
    return Promise.all(requests).then(() => {
      console.log(wRecords);
      setRecords((prev) => wRecords);
    });
  }

  function openDialog(e, index) {
    e.preventDefault();
    setEditIndex(index);
    handleClickOpen();
  }

  return (
    <>
      {currentUser ? (
        <DashboardLayout>
          <DashboardNavbar />
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={3}
                  mt={0}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Find Write Offs
                  </MDTypography>
                </MDBox>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel
                    sx={{
                      "margin-left": "30px",
                      "margin-right": "30px",
                    }}
                    id="warehouse-label"
                  >
                    Select Region
                  </InputLabel>
                  <Select
                    sx={textboxStyle}
                    labelId="warehouse-label"
                    id="warehouse-label"
                    label="Warehouse"
                    onChange={(e) =>
                      setSelectedRegion(state.regions[e.target.value].name)
                    }
                  >
                    {state.regions?.map((item, index) => (
                      <MenuItem value={index}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  id="sdate"
                  label="Start Date"
                  type="date"
                  defaultValue={Moment().format()}
                  sx={textboxStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <TextField
                  id="edate"
                  label="End Date"
                  type="date"
                  defaultValue={Moment().format()}
                  sx={textboxStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setEndDate(e.target.value)}
                />
                <MDButton
                  sx={textboxStyle}
                  align="center"
                  color="success"
                  variant="contained"
                  onClick={fetchWriteOffs}
                >
                  Search
                </MDButton>
                <MDBox
                  mx={2}
                  mt={0}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="white"
                  borderRadius="lg"
                ></MDBox>
              </Card>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Write Offs
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable_Generic
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={true}
                    showTotalEntries={true}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
          <Footer />
        </DashboardLayout>
      ) : (
        <HandleLogout />
      )}
      {renderDialog()}
    </>
  );
}
