import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import MDBox from "../../ui-components/MDBox";
import MDButton from "../../ui-components/MDButton";
import MDTypography from "../../ui-components/MDTypography";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import DataTable_Generic from "../Tables/DataTable_Generic";
import DataTable_Warehouses from "../Tables/DataTable_Warehouses";
import Moment from "moment";
import runSheetDataTable from "../data/runSheetRecords";
import unfinishedRunData from "../data/unfinishedRunRecords";
import { useDBManager } from "../../contexts/DBManager_Context";
import HandleLogout from "../HandleLogout";

export default function RunSheet_Manager() {
  const [dateValue, setDateValue] = useState(new Date());
  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };
  const navigate = useNavigate();
  const [selectedRegion, setSelectedRegion] = useState({});
  const [records, setRecords] = useState([]);
  const [recordIDs, setRecordIDs] = useState([]);
  let { columns, rows } = runSheetDataTable(
    records,
    recordIDs,
    openRunSheetPage
  );
  const { updateFields } = useDBManager();
  const { state } = useDBManager();
  const [runDate, setRunDate] = useState([]);
  const { currentUser } = useAuth();
  const [unfinishedRuns, setUnfinishedRuns] = useState([]);
  const [editIndex, setEditIndex] = useState("");
  let { w_columns, w_rows } = unfinishedRunData(unfinishedRuns, openDialog);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e, num) => {
    e.preventDefault();
    setOpen(false);
    if (num == 1) {
      let collRef = collection(
        db,
        unfinishedRuns[editIndex].path,
        unfinishedRuns[editIndex].id,
        "machines"
      );
      let querySnapshot = await getDocs(collRef);
      let ids = [];
      querySnapshot.forEach((doc) => {
        ids.push(doc.id);
      });

      return Promise.all(ids).then(() => {
        let y = ids;
        y.forEach((id) => {
          let ref = doc(
            db,
            unfinishedRuns[editIndex].path,
            unfinishedRuns[editIndex].id,
            "machines",
            id
          );
          deleteDoc(ref);
        });
        let docRef = doc(
          db,
          unfinishedRuns[editIndex].path,
          unfinishedRuns[editIndex].id
        );
        deleteDoc(docRef);
        fetchUnfinishedRuns();
      });
    }
  };

  useEffect(() => {
    updateFields(["regions"]);
  }, []);

  useEffect(() => {
    fetchUnfinishedRuns();
  }, [state]);

  function renderDialog() {
    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Delete Run?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              If the driver is in the middle of this run, they will not be able
              to progress.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={(e) => handleClose(e, 0)}>Cancel</MDButton>
            <MDButton onClick={(e) => handleClose(e, 1)}>Enter</MDButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  function openRunSheetPage(docID) {
    navigate(
      "/runsheets/" +
        selectedRegion +
        "-" +
        docID +
        "-" +
        runDate[0] +
        "-" +
        runDate[1] +
        "-" +
        runDate[2]
    );
  }

  async function fetchRunSheets() {
    let splitDate = dateValue.split("-");
    setRunDate(splitDate);
    let querySnapshot = await getDocs(
      collection(
        db,
        "Run Records",
        selectedRegion,
        splitDate[0],
        splitDate[1],
        splitDate[2]
      )
    );
    let pdata = [];
    let ids = [];
    querySnapshot.forEach((doc) => {
      pdata.push(doc.data());
      ids.push(doc.id);
    });

    return Promise.all(pdata, ids).then(() => {
      let x = pdata;
      let y = ids;
      setRecords((prev) => x);
      setRecordIDs((prev) => y);
    });
  }

  async function fetchUnfinishedRuns() {
    let querySnapshot = await getDocs(collection(db, "PreRun Data"));
    let pdata = [];
    querySnapshot.forEach((doc) => {
      let data = {};
      data = { id: doc.id, path: "PreRun Data", ...doc.data() };
      pdata.push(data);
    });

    querySnapshot = await getDocs(collection(db, "Run Data"));
    querySnapshot.forEach((doc) => {
      let data = {};
      data = { id: doc.id, path: "Run Data", ...doc.data() };
      pdata.push(data);
    });

    return Promise.all(pdata).then(() => {
      let x = pdata;
      setUnfinishedRuns((prev) => x);
    });
  }

  function openDialog(e, index) {
    e.preventDefault();
    setEditIndex(index);
    handleClickOpen();
  }

  return (
    <>
      {currentUser ? (
        <Container>
          <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={6}>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Runs In Progress
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable_Warehouses
                      table={{ w_columns, w_rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                    />
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={3}
                    mt={0}
                    py={2}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Find Run Sheets
                    </MDTypography>
                  </MDBox>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel
                      sx={{
                        "margin-left": "30px",
                        "margin-right": "30px",
                      }}
                      id="warehouse-label"
                    >
                      Select Region
                    </InputLabel>
                    <Select
                      sx={textboxStyle}
                      labelId="warehouse-label"
                      id="warehouse-label"
                      label="Warehouse"
                      onChange={(e) =>
                        setSelectedRegion(state.regions[e.target.value].name)
                      }
                    >
                      {state.regions.map((item, index) => (
                        <MenuItem value={index}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    sx={textboxStyle}
                    id="date"
                    label="Date"
                    type="date"
                    defaultValue={Moment().format()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setDateValue(e.target.value)}
                  />
                  <MDButton
                    sx={textboxStyle}
                    align="center"
                    color="success"
                    variant="contained"
                    onClick={fetchRunSheets}
                  >
                    Search
                  </MDButton>
                  <MDBox
                    mx={2}
                    mt={0}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="white"
                    borderRadius="lg"
                  ></MDBox>
                </Card>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Run Sheets
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable_Generic
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={true}
                      showTotalEntries={true}
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
            <Footer />
          </DashboardLayout>
        </Container>
      ) : (
        <HandleLogout />
      )}
      {renderDialog()}
    </>
  );
}
