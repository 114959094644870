import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  Checkbox,
  CircularProgress,
  TextField,
  Fab,
  Switch
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  getDocs,
  doc,
  addDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import BasicLayout from "../../layouts/BasicLayout";
import MDTypography from "../../ui-components/MDTypography";
import MDAlert from "../../ui-components/MDAlert";
import MDBox from "../../ui-components/MDBox";
import MDButton from "../../ui-components/MDButton";
import MDInput from "../../ui-components/MDInput";
import { useDBManager } from "../../contexts/DBManager_Context";
import { Add } from "@mui/icons-material";

export default function Run_Restock() {
  let date = new Date();
  const [month, setMonth] = useState(String(date.getMonth() + 1));
  const [day, setDay] = useState(String(date.getDate()));
  const [year] = useState(String(date.getFullYear()));
  let todaysDate = year + "-" + month + "-" + day;
  const { updateFields, state, handleWriteOffClickOpen, renderWriteOffDialog } =
    useDBManager();
  const navigate = useNavigate();
  const imageStyle = { width: "40px", height: "40px" };
  let { docID } = useParams();
  const [machineID, setMachineID] = useState("");
  const [runSheetID, setRunSheetID] = useState("");
  const [waiting, setWaiting] = useState(true);
  const [currentMachine, setCurrentMachine] = useState([]);
  const [runMachineID, setRunMachineID] = useState([]);
  const [runData, setRunData] = useState({});
  const [localRunData, setLocalRunData] = useState({});
  const [newPlanogram, setNewPlanogram] = useState({});
  const [needsForks, setNeedsForks] = useState(false);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [driverReqs, setDriverReqs] = useState([]);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [failedOpen, setFailedOpen] = React.useState(false);
  const handleSuccessClickOpen = () => {
    setSuccessOpen(true);
  };
  const handleSuccessClose = () => {
    setSuccessOpen(false);
  };
  const handleFailedClickOpen = () => {
    setFailedOpen(true);
  };
  const handleFailedClose = () => {
    setFailedOpen(false);
  };
  const [newQuantity, setNewQuantity] = useState(0);
  const [editIndex, setEditIndex] = useState("");
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (e, num) => {
    e.preventDefault();
    setOpen(false);
    if (num == 1) {
      callbackFunc_updateStocked()
    }
  };

  const callbackFunc_updateStocked = async (callback) => {
    let x = localRunData;
    x.stockedProducts[editIndex].quantity = parseInt(newQuantity);
    const docRef = doc(
      db,
      "Run Data",
      runSheetID,
      "machines",
      localRunData.id
    );
    updateDoc(docRef, x).then(async (response) => {
      callback();
    });
  };

  function openDialog(e, index) {
    e.preventDefault();
    setEditIndex(index);
    handleClickOpen();
  }

  async function waitForData() {
    await new Promise((r) => setTimeout(r, 3000)).then(() => {
      setWaiting(false);
      findPlanogram();
      fetchRunMachineID();
      fetchRunData();
    });
  }

  useEffect(() => {
    filterParams();
    updateFields([
      "users",
      "machines",
      "products",
      "reasons",
      "regions",
      "availableLocations",
    ]);
  }, []);

  useEffect(() => {
    state.regions?.forEach((item, index) => {
      if (item.name == currentMachine.region?.name) {
        setDriverReqs(item.checklist);
      }
    });
  }, [currentMachine, state]);

  useEffect(() => {
    let products = [];
    state.products?.forEach((item, index) => {
      if (item.active) {
        products.push(item);
      }
    });
    setAvailableProducts(products);
    waitForData();
  }, [state]);

  function filterParams() {
    let x = docID.split("-");
    setRunSheetID(x[0]);
    setMachineID(x[1]);
  }

  async function fetchRunData() {
    const docRef = doc(db, "Run Data", runSheetID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setRunData(docSnap.data());
    }
  }

  async function fetchRunMachineID() {
    const querySnapshot = await getDocs(
      collection(db, "Run Data", runSheetID, "machines")
    );
    const data = [];
    const IDs = [];
    querySnapshot.forEach((doc) => {
      let id = doc.id;
      data.push({ id, ...doc.data() });
      IDs.push(id);
    });
    data.forEach((item, index) => {
      if (item.machineID.id == machineID) {
        setLocalRunData(item);
        setRunMachineID(IDs[index]);
      }
    });
  }

  async function findPlanogram() {
    state.machines?.map(async (item, index) => {
      if (item.docID == machineID) {
        setCurrentMachine(item);
        setNeedsForks(item.needsForks ? item.needsForks : false);
        let querySnapshot = await getDocs(
          collection(db, "Machines", machineID, "planogram")
        );
        let pdata = [];
        querySnapshot.forEach((doc) => {
          pdata.push(doc.data());
        });
        return Promise.resolve(pdata).then(() => {
          let x = pdata[0];
          let clonedPlanogram = JSON.parse(JSON.stringify(x));
          setNewPlanogram(clonedPlanogram);
        });
      }
    });
  }

  function updatePlanogramProduct(event, index) {
    let y = newPlanogram;
    y.planogram.shelves[index].productCode = event.target.value;
    setNewPlanogram(y);
  }

  function updatePlanogramQuantity(event, index) {
    let y = newPlanogram;
    y.planogram.shelves[index].maxStock = event.target.value;
    setNewPlanogram(y);
  }


  function buildPLane(object, index) {
    return (
      <>
        <Grid item xs={1.66} xl={1.66}>
          <FormControl
            variant="standard"
            align="center"
            size="small"
            sx={"margin-bottom: 10px"}
          >
            <Select
              labelId="simple-select-label"
              id="simple-select"
              defaultValue={object.productCode}
              onChange={(e) => updatePlanogramProduct(e, index)}
            >
              {availableProducts.map((entry, pndex) => (
                <MenuItem value={entry.bud}>
                  <img style={imageStyle} src={entry.url} alt="" />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            align="center"
            sx={"margin-left: 10px"}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={object.maxStock}
              onChange={(e) => updatePlanogramQuantity(e, index)}
            >
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </>
    );
  }

  function buildPGrid() {
    let elements = [];
    newPlanogram.planogram?.shelves?.forEach((item, index) => {
      elements.push(buildPLane(item, index));
    });
    return (
      <>
        <Grid container spacing={0}>
          {elements.map((item, index) => {
            return <>{item}</>;
          })}
        </Grid>
      </>
    );
  }

  function renderPlanogram() {
    return (
      <>
        <Grid item xs={12}>
          <Card>
            <MDAlert color="error">
              <MDTypography justifycontent="center" variant="h6" color="white">
                Planogram
              </MDTypography>
            </MDAlert>
            <MDBox
              mx={2}
              mt={2}
              py={1}
              px={0}
              variant="gradient"
              bgColor="white"
              borderRadius="lg"
            >
              {buildPGrid()}
            </MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let canContinue = true;
    driverReqs.forEach((item, index) => {
      if (item.done == false) {
        canContinue = false;
      }
    });
    if (canContinue) {
      handleSuccessClickOpen();
    } else {
      handleFailedClickOpen();
    }
  }

  function handleContinueRun() {
    handleSuccessClose();
    let newInventory = runData.driverInventory;
    localRunData.stockedProducts.forEach((item, index) => {
      newInventory.forEach((entry, kndex) => {
        if (item.name == entry.name) {
          newInventory[kndex].quantity = entry.quantity - item.quantity;
        }
      });
    });

    updateDoc(doc(db, "Run Data", runSheetID), {
      driverInventory: newInventory,
    });

    updateDoc(doc(db, "Run Data", runSheetID, "machines", runMachineID), {
      driverPlanogram: newPlanogram.planogram,
      filled: true,
      filledAt: new Date(),
    });

    updateDoc(doc(db, "Machines", currentMachine.id), {
      lastFilled: todaysDate,
      needsForks: needsForks,
    });

    navigate("/run-overview/" + runSheetID);
  }

  function handleBackBtn(e) {
    e.preventDefault();
    navigate("/run-overview/" + runSheetID);
  }

  function changeCheck(e, item) {
    let x = driverReqs;
    for (let i = 0; i < driverReqs.length; i++) {
      if (item.text == x[i].text) {
        x[i].done = e.target.checked;
      }
    }
    setDriverReqs((prev) => [...x]);
  }

  function renderPPEntry(item, index) {
    let url = "";
    for (let i = 0; i < state.products.length; i++) {
      if (item.name == state.products[i].name) {
        url = state.products[i].url;
        break;
      }
    }

    let stockedProduct = {};
    let stockedIndex = 0;
    localRunData.stockedProducts?.forEach((ting, yndex) => {
      if (ting.name == item.name) {
        stockedProduct = ting;
        stockedIndex = yndex;
      }
    });

    return (
      <>
        <Grid item xs={1} xl={1} />
        <Grid item xs={2} xl={2}>
          <img align="center" style={imageStyle} src={url} alt="" />
        </Grid>
        <Grid item xs={8} xl={8}>
          <MDTypography sx={"margin-top: 5px"} variant="h6">
            {item.name}
          </MDTypography>
          <MDTypography align="left" variant="h6">
            Ordered: {item.quantity} - Picked:{" "}
            {localRunData.pickedProducts[index] == undefined
              ? 0
              : localRunData.pickedProducts[index].quantity}
          </MDTypography>
        </Grid>
        <Grid item xs={1} xl={1} />
        <Grid item xs={4} xl={4} />
        <Grid item xs={4} xl={4}>
          <MDButton
            variant="outlined"
            color={stockedProduct.quantity == 0 ? "primary" : "secondary"}
            onClick={(e) => openDialog(e, stockedIndex)}
          >
            Stocked: {stockedProduct.quantity}
          </MDButton>
        </Grid>
        <Grid item xs={4} xl={4} />
      </>
    );
  }

  function renderWaiting() {
    return (
      <>
        <Grid container justifyContent="center">
          <CircularProgress color="warning" />
        </Grid>
        <Grid container justifyContent="center">
          <MDTypography variant="h4" noWrap>
            Please wait
          </MDTypography>
        </Grid>
      </>
    );
  }

  function renderPickedProductData() {
    return (
      <>
        <Grid item xs={12}>
          <Card>
            <MDAlert color="info">
              <MDTypography justifycontent="center" variant="h6" color="white">
                Products
              </MDTypography>
            </MDAlert>
            <MDBox
              mx={2}
              mt={2}
              py={1}
              px={0}
              variant="gradient"
              bgColor="white"
              borderRadius="lg"
            >
              <Grid container spacing={0}>
                {localRunData.orderedProducts?.map((item, index) =>
                  renderPPEntry(item, index)
                )}
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  function renderChecklist() {
    return (
      <>
        <Grid item xs={12} xl={8}>
          <Card>
            <MDAlert color="dark">
              <MDTypography justifycontent="center" variant="h6" color="white">
                Checklist
              </MDTypography>
            </MDAlert>
            <MDBox
              mx={2}
              mt={2}
              py={1}
              px={0}
              variant="gradient"
              bgColor="white"
              borderRadius="lg"
            >
              <Grid container spacing={0}>
                <FormGroup>
                  {driverReqs?.map((item, index) => (
                    <>
                      <FormControlLabel
                        control={<Checkbox checked={item.done} />}
                        onChange={(e) => changeCheck(e, item)}
                        label={item.text}
                      />
                    </>
                  ))}
                </FormGroup>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  function renderDialog() {
    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Enter Stocked Quantity</DialogTitle>
          <DialogContent>
            <DialogContentText>
              How much of this flavor did you stock in this machine?
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="New Quantity"
              type="email"
              fullWidth
              variant="standard"
              onChange={(e) => setNewQuantity(parseInt(e.target.value))}
            />
          </DialogContent>
          <DialogActions>
            <MDButton onClick={(e) => handleClose(e, 0)}>Cancel</MDButton>
            <MDButton onClick={(e) => handleClose(e, 1)}>Enter</MDButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <BasicLayout>
        {waiting ? (
          renderWaiting()
        ) : (
          <MDBox mt={8}>
            <MDButton
              fullwidth={true}
              justifyContent="center"
              variant="text"
              onClick={(e) => handleBackBtn(e)}
            >
              <MDAlert color="info">
                <MDTypography
                  justifycontent="center"
                  variant="h6"
                  color="white"
                >
                  Back
                </MDTypography>
              </MDAlert>
            </MDButton>
            <Grid container justifyContent="center">
              <Grid container spacing={2} justifyContent="center">
                <Grid align="center" item xs={12}>
                  <Card>
                    <MDBox
                      mx={1}
                      mt={0}
                      py={2}
                      px={4}
                      variant="gradient"
                      bgColor="white"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h5" color="text">
                        {currentMachine.name}
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
                {renderPlanogram()}
                {renderPickedProductData()}
                <Grid align="center" item xs={12}>
                  <Card>
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch />}
                        label={
                          needsForks ? "Needs Forks" : "Doesn't Need Forks"
                        }
                        onChange={(e) => setNeedsForks(e.target.checked)}
                      />
                    </FormGroup>
                  </Card>
                </Grid>
                {renderChecklist()}
              </Grid>
              <MDButton
                justifyContent="center"
                variant="text"
                onClick={(e) => handleSubmit(e)}
              >
                <MDAlert color="error">
                  <MDTypography
                    justifycontent="center"
                    variant="h6"
                    color="white"
                  >
                    Submit Machine
                  </MDTypography>
                </MDAlert>
              </MDButton>
            </Grid>
          </MDBox>
        )}
        <Fab
          color="info"
          aria-label="add"
          sx={{ position: "absolute", bottom: 40, right: 20 }}
          onClick={handleWriteOffClickOpen}
        >
          <Add />
        </Fab>
        {renderWriteOffDialog()}
      </BasicLayout>
      <div>
        <Dialog
          open={successOpen}
          onClose={handleSuccessClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Submit Machine"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you ready to submit this machine? You will not be able to
              return to this screen once you submit.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleSuccessClose}>No</MDButton>
            <MDButton onClick={handleContinueRun} autoFocus>
              Yes
            </MDButton>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={failedOpen}
          onClose={handleFailedClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"You've missed a step"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please ensure all checks have been completed before submitting
              this machine.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleFailedClose} autoFocus>
              Return
            </MDButton>
          </DialogActions>
        </Dialog>
      </div>
      {renderDialog()}
    </>
  );
}
