import React, { useState, useEffect } from "react";
import { Container, Grid, Card, TextField } from "@mui/material";
import { CSVLink } from "react-csv";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { collection, getDocs } from "firebase/firestore";
import DataTable_Generic from "../Tables/DataTable_Generic";
import DataTable_Warehouses from "../Tables/DataTable_Warehouses";
import DataTable_MI from "../Tables/DataTable_MI";
import HandleLogout from "../HandleLogout";
import MDButton from "../../ui-components/MDButton";
import { useDBManager } from "../../contexts/DBManager_Context";
import Moment from "moment";
import inventoryPosition_data from "../reportData/inventoryPosition_data";
import inventoryPositionBoth_data from "../reportData/inventoryPositionBoth_data";

export default function InventoryPosition() {
  const { currentUser } = useAuth();
  const [dateValue, setDateValue] = useState(new Date());
  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };
  const { updateFields } = useDBManager();
  const { state } = useDBManager();
  const [selectedDate, setSelectedDate] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [w_columns, setWColumns] = useState([]);
  const [w_rows, setWRows] = useState([]);
  const [mi_columns, setMIColumns] = useState([]);
  const [mi_rows, setMIRows] = useState([]);
  const [warehouse_csvData, setWarehouseCsvData] = useState([]);
  const [machine_csvData, setMachineCsvData] = useState([]);
  const [both_csvData, setBothCsvData] = useState([]);

  useEffect(() => {
    updateFields(["machines", "warehouses", "products"]);
  }, []);

  async function fetchWarehouseRecords() {
    let splitDate = dateValue.split("-");
    setSelectedDate(splitDate);
    let year = splitDate[0];
    let month = String(splitDate[1]);
    let day = String(splitDate[2]);
    if (month[0] == "0") {
      month = month[1];
    }
    if (day[0] == "0") {
      day = day[1];
    }

    let warehouseData = [];
    const localRequests = state.warehouses?.map(async (warehouse, index) => {
      let localData = [];
      return getDocs(
        collection(
          db,
          "Stock",
          "Reports",
          "Daily Inventory Position",
          warehouse.name,
          year,
          month,
          day
        )
      ).then((response) => {
        response.forEach((doc) => {
          localData.push(doc.data());
        });
        localData.sort((a, b) => (a.name > b.name ? 1 : -1));
        warehouseData.push(localData);
      });
    });

    return Promise.all(localRequests).then(() => {
      let x = warehouseData;
      setColumns(
        inventoryPosition_data(x, state.products, state.warehouses, "warehouse")
          .columns
      );
      setRows(
        inventoryPosition_data(x, state.products, state.warehouses, "warehouse")
          .rows
      );
      prepareWarehouseDataForCSV(x);
    });
  }

  function renderWarehouseTable() {
    if (columns != undefined) {
      return (
        <>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Warehouse Inventory Position
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable_Generic
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                />
              </MDBox>
              <MDButton>
                <CSVLink filename={dateValue} data={warehouse_csvData}>
                  Download CSV
                </CSVLink>
                ;
              </MDButton>
            </Card>
          </Grid>
        </>
      );
    }
  }

  function prepareWarehouseDataForCSV(f) {
    let lRows = [];
    let b = [];
    b.push("Product");
    state.warehouses.map((warehouse, index) => {
      b.push(warehouse.name);
    });
    b.push("Total");
    lRows.push(b);

    state.products.map((item, pndex) => {
      let a = [];
      a.push(item.name);
      f.map((warehouse, index) => {
        let x = warehouse.filter((product) => product.name === item.name);
        let y = 0;
        x.forEach((product) => {
          y = y + product.quantity;
        });
        a.push(y);
      });
      let total = 0;
      a.forEach((item, index) => {
        if (index != 0) {
          total = total + parseInt(item);
        }
      });
      if (total != 0) {
        a.push(total);
        lRows.push(a);
      }
    });
    setWarehouseCsvData(lRows);
  }

  async function fetchMachineRecords() {
    let splitDate = dateValue.split("-");
    setSelectedDate(splitDate);
    let year = splitDate[0];
    let month = String(splitDate[1]);
    let day = String(splitDate[2]);
    if (month[0] == "0") {
      month = month[1];
    }
    if (day[0] == "0") {
      day = day[1];
    }

    let machineData = [];
    const localRequests = state.machines?.map(async (machine, index) => {
      let localData = [];
      return getDocs(
        collection(
          db,
          "Stock",
          "Reports",
          "Daily Inventory Position",
          machine.number,
          year,
          month,
          day
        )
      ).then((response) => {
        response.forEach((doc) => {
          localData.push(doc.data());
        });
        localData.sort((a, b) => (a.name > b.name ? 1 : -1));
        localData.forEach((item, index) => {
          machineData.push(item);
        });
      });
    });

    return Promise.all(localRequests).then(() => {
      let x = machineData;
      let processedDocs = [];
      x.forEach((item, index) => {
        processedDocs.push(assembleSMLabels(item));
      });

      setWColumns(
        inventoryPosition_data(
          processedDocs,
          state.products,
          state.machines,
          "machine"
        ).w_columns
      );
      setWRows(
        inventoryPosition_data(
          processedDocs,
          state.products,
          state.machines,
          "machine"
        ).w_rows
      );
      prepareMachineDataForCSV(processedDocs);
    });
  }

  function assembleSMLabels(p) {
    let h = {
      products: [],
      mapping: [],
      maxQuantities: [],
      totalMapping: 0,
      totalMax: 0,
    };

    p.shelves?.forEach((item, index) => {
      if (!h.products.includes(item.productCode)) {
        h.products.push(item.productCode);
        h.mapping.push(item.currentStock);
        h.maxQuantities.push(item.maxStock);
        h.totalMapping = h.totalMapping + item.currentStock;
        h.totalMax = h.totalMax + item.maxStock;
      } else {
        let x = h.mapping[h.products.indexOf(item.productCode)];
        x = x + item.currentStock;
        h.mapping[h.products.indexOf(item.productCode)] = x;

        let y = h.maxQuantities[h.products.indexOf(item.productCode)];
        y = y + item.maxStock;
        h.maxQuantities[h.products.indexOf(item.productCode)] = y;

        h.totalMapping = h.totalMapping + item.currentStock;
        h.totalMax = h.totalMax + item.maxStock;
      }
    });

    h.products.forEach((item, index) => {
      state.products.forEach((entry, kndex) => {
        if (
          item == entry.bud ||
          item == entry.altBudCode1 ||
          item == entry.altBudCode2 ||
          item == entry.altBudCode3
        ) {
          h.products[index] = entry.name;
        }
      });
    });
    return h;
  }

  function renderMachineTable() {
    if (w_columns != undefined) {
      return (
        <>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Machines Inventory Position
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable_Warehouses
                  table={{ w_columns, w_rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                />
              </MDBox>
              <MDButton>
                <CSVLink filename={dateValue} data={machine_csvData}>
                  Download CSV
                </CSVLink>
                ;
              </MDButton>
            </Card>
          </Grid>
        </>
      );
    }
  }

  function prepareMachineDataForCSV(f) {
    let lRows = [];
    let b = [];
    b.push("Product");
    state.machines.map((machine, index) => {
      b.push(machine.number + " - " + machine.name);
    });
    b.push("Total");
    lRows.push(b);

    state.products.map((item, pndex) => {
      let a = [];
      a.push(item.name);
      f.map((machine, index) => {
        let x = machine.products.filter((product) => product === item.name);
        let y = 0;
        machine.products.forEach((thing, tndex) => {
          if (thing === item.name) {
            y = y + machine.mapping[tndex];
          }
        });
        a.push(y);
      });
      let total = 0;
      a.forEach((item, index) => {
        if (index != 0) {
          total = total + parseInt(item);
        }
      });
      if (total != 0) {
        a.push(total);
        lRows.push(a);
      }
    });
    setMachineCsvData(lRows);
  }

  async function fetchBothRecords() {
    let splitDate = dateValue.split("-");
    setSelectedDate(splitDate);
    let year = splitDate[0];
    let month = String(splitDate[1]).substring(1);
    let day = String(splitDate[2]).substring(1);

    let warehouseData = [];
    const localRequests = state.warehouses?.map(async (warehouse, index) => {
      let localData = [];
      return getDocs(
        collection(
          db,
          "Stock",
          "Reports",
          "Daily Inventory Position",
          warehouse.name,
          year,
          month,
          day
        )
      ).then((response) => {
        response.forEach((doc) => {
          localData.push(doc.data());
        });
        localData.sort((a, b) => (a.name > b.name ? 1 : -1));
        warehouseData.push(localData);
      });
    });

    return Promise.all(localRequests).then(() => {
      let machineData = [];
      const localRequests2 = state.machines?.map(async (machine, index) => {
        let localData = [];
        return getDocs(
          collection(
            db,
            "Stock",
            "Reports",
            "Daily Inventory Position",
            machine.number,
            year,
            month,
            day
          )
        ).then((response) => {
          response.forEach((doc) => {
            localData.push(doc.data());
          });
          localData.sort((a, b) => (a.name > b.name ? 1 : -1));
          localData.forEach((item, index) => {
            machineData.push(item);
          });
        });
      });
      return Promise.all(localRequests2).then(() => {
        let x = warehouseData;
        let y = machineData;
        console.log(machineData);
        let processedDocs = [];
        y.forEach((item, index) => {
          processedDocs.push(assembleSMLabels(item));
        });
        setMIColumns(
          inventoryPositionBoth_data(
            x,
            processedDocs,
            state.products,
            state.warehouses,
            state.machines
          ).mi_columns
        );
        setMIRows(
          inventoryPositionBoth_data(
            x,
            processedDocs,
            state.products,
            state.warehouses,
            state.machines
          ).mi_rows
        );
        prepareBothDataForCSV(x, processedDocs);
      });
    });
  }

  function renderBothTable() {
    if (mi_columns != undefined) {
      return (
        <>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Combined Inventory Position
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable_MI
                  table={{ mi_columns, mi_rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                />
              </MDBox>
              <MDButton>
                <CSVLink filename={dateValue} data={both_csvData}>
                  Download CSV
                </CSVLink>
                ;
              </MDButton>
            </Card>
          </Grid>
        </>
      );
    }
  }

  function prepareBothDataForCSV(f, v) {
    let lRows = [];
    let b = [];
    b.push("Product");
    state.machines.map((machine, index) => {
      b.push(machine.number + " - " + machine.name);
    });
    state.warehouses.map((warehouse, index) => {
      b.push(warehouse.name);
    });
    b.push("Total");
    lRows.push(b);

    state.products.map((item, pndex) => {
      let a = [];
      a.push(item.name);
      v.map((machine, index) => {
        let x = machine.products.filter((product) => product === item.name);
        let y = 0;
        machine.products.forEach((thing, tndex) => {
          if (thing === item.name) {
            y = y + machine.mapping[tndex];
          }
        });
        a.push(y);
      });

      f.map((warehouse, index) => {
        let x = warehouse.filter((product) => product.name === item.name);
        let y = 0;
        x.forEach((product) => {
          y = y + product.quantity;
        });
        a.push(y);
      });

      let total = 0;
      a.forEach((item, index) => {
        if (index != 0) {
          total = total + parseInt(item);
        }
      });
      if (total != 0) {
        a.push(total);
        lRows.push(a);
      }
    });
    setBothCsvData(lRows);
  }

  const handleOnClick = (e, type) => {
    e.preventDefault();
    if (type == "warehouse") {
      fetchWarehouseRecords();
    } else if (type == "machine") {
      fetchMachineRecords();
    } else if (type == "both") {
      fetchBothRecords();
    }
  };

  return (
    <>
      {currentUser ? (
        <Container>
          <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={5}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={3}
                      mt={0}
                      py={2}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Customize Report
                      </MDTypography>
                    </MDBox>
                    <TextField
                      sx={textboxStyle}
                      id="date"
                      label="Date"
                      type="date"
                      defaultValue={Moment().format()}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setDateValue(e.target.value)}
                    />
                    <MDButton
                      sx={textboxStyle}
                      align="center"
                      color="success"
                      variant="contained"
                      onClick={(e) => handleOnClick(e, "warehouse")}
                    >
                      Warehouse Report
                    </MDButton>
                    <MDButton
                      sx={textboxStyle}
                      align="center"
                      color="success"
                      variant="contained"
                      onClick={(e) => handleOnClick(e, "machine")}
                    >
                      Machine Report
                    </MDButton>
                    <MDButton
                      sx={textboxStyle}
                      align="center"
                      color="success"
                      variant="contained"
                      onClick={(e) => handleOnClick(e, "both")}
                    >
                      Both
                    </MDButton>
                    <MDBox
                      mx={2}
                      mt={0}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="white"
                      borderRadius="lg"
                    ></MDBox>
                  </Card>
                </Grid>
                {renderWarehouseTable()}
                {renderMachineTable()}
                {renderBothTable()}
              </Grid>
            </MDBox>
            <Footer />
          </DashboardLayout>
        </Container>
      ) : (
        <HandleLogout />
      )}
    </>
  );
}
