import React, { useState, useEffect } from "react";
import {
  Card,
  FormControl,
  Grid,
  Checkbox,
  Select,
  InputLabel,
  FormControlLabel,
  Divider,
  MenuItem,
  TextField
} from "@mui/material";
import { useParams } from "react-router-dom";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { useDBManager } from "../../contexts/DBManager_Context";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDButton from "../../ui-components/MDButton";
import TimePicker from "react-time-picker";
import TimezoneSelect from "react-timezone-select";

export default function Run_Schedule_View() {
  const { docID } = useParams();
  const { updateFields } = useDBManager();
  const { state, fetchDocument } = useDBManager();
  const [runDoc, setRunDoc] = useState([]);

  const [newRun_Driver, setNewRun_Driver] = useState({});
  const [newRun_Machines, setNewRun_Machines] = useState([]);
  const [newRun_Warehouse, setNewRun_Warehouse] = useState({});
  const [newRun_Region, setNewRun_Region] = useState("");
  const [newRun_Day, setNewRun_Day] = useState("");

  const [availableMachines, setAvailableMachines] = useState([]);
  const [availableWarehouses, setAvailableWarehouses] = useState([]);
  const [availableDrivers, setAvailableDrivers] = useState([]);

  const [contextDependent, setContextDependent] = useState(false);

  const [startTime, setStartTime] = useState("10:00");
  const [timeValue, onChangeTime] = useState("10:00");
  const [timezone, setTimezone] = useState("America/New_York");

  const [refillThreshold, setRefillThreshold] = useState([]);

  const availableDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };

  useEffect(() => {
    updateFields(["regions", "warehouses", "machines", "users"]);
  }, []);

  useEffect(() => {
    fetchRunDoc();
  }, [state]);

  useEffect(() => {
    let x = [];
    state.machines?.forEach((machine) => {
      if (machine.region?.name == runDoc.region) {
        x.push(machine);
      }
    });
    setAvailableMachines(x);

    let y = [];
    state.users?.forEach((user) => {
      user.regions.forEach((reg, rndex) => {
        if (reg === runDoc.region) {
          y.push(user);
        }
      });
    });
    setAvailableDrivers(y);

    let z = [];
    state.warehouses?.forEach((warehouse) => {
      if (warehouse.servicing == runDoc.region?.name) {
        z.push(warehouse);
      }
    });
    setAvailableWarehouses(z);
  }, [runDoc]);

  async function fetchRunDoc() {
    fetchDocument(doc(db, "Scheduled Runs", docID)).then((data) => {
      setRunDoc(data);
      setNewRun_Driver(data.driver);
      setNewRun_Warehouse(data.warehouse);
      setNewRun_Region(data.region);
      setNewRun_Day(data.day);
      onChangeTime(data.time);
      setStartTime(data.startTime);
      setTimezone(data.timezone);
      setContextDependent(data.contextDependent);
      setRefillThreshold(data.refillThreshold);
    });
  }

  useEffect(() => {
    if (newRun_Region != "") {
      let machines = state.machines.filter(
        (machine) => machine.region.name == newRun_Region
      );
      setAvailableMachines(machines);

      let warehouses = state.warehouses.filter(
        (warehouses) => warehouses.servicing == newRun_Region
      );
      setAvailableWarehouses(warehouses);

      let drivers = state.users.filter((drivers) =>
        drivers.regions.includes(newRun_Region)
      );
      setAvailableDrivers(drivers);
    }
  }, [newRun_Region]);

  function dayOfTheWeek(day) {
    switch (day) {
      case 0:
        return "Monday";
      case 1:
        return "Tuesday";
      case 2:
        return "Wednesday";
      case 3:
        return "Thursday";
      case 4:
        return "Friday";
      case 5:
        return "Saturday";
      case 6:
        return "Sunday";
      default:
        return "Monday";
    }
  }

  const handleSelectContext = (e) => {
    setContextDependent((prev) => e.target.checked);
  };

  const changeRegion = (e) => {
    setNewRun_Machines([]);
    setNewRun_Region(e);
  };

  const handleSetChecked = (event, obj) => {
    let z = newRun_Machines;
    if (!newRun_Machines.includes(obj)) {
      z.push(obj);
    } else {
      z.splice(newRun_Machines.indexOf(obj), 1);
    }
    setNewRun_Machines((prev) => z);
  };

  const handleSetThreshold = (event, obj) => {
    let z = refillThreshold;
    if (refillThreshold.length == 0) {
      z.push({ threshold: event.target.value, machine: obj });
    } else {
      let exists = false;
      refillThreshold.forEach((element, index) => {
        if (element.machine == obj) {
          z.splice(index, 1, { threshold: event.target.value, machine: obj });
          exists = true;
        }
      });
      if (!exists) {
        z.push({ threshold: event.target.value, machine: obj });
      }
    }
    setRefillThreshold((prev) => z);
  };

  const onUpdateRun = () => {
    const docRef = doc(db, "Scheduled Runs", docID);
    updateDoc(docRef, {
      driver: newRun_Driver,
      machines: newRun_Machines,
      warehouse: newRun_Warehouse,
      region: newRun_Region,
      day: newRun_Day,
      time: timeValue,
      timezone: timezone,
      startTime: startTime,
      contextDependent: contextDependent,
      refillThreshold: refillThreshold,
    });
    alert("Run Updated");
    //Get run again
    fetchDocument(doc(db, "Scheduled Runs", docID)).then((data) => {
      setRunDoc(data);
      setNewRun_Driver(data.driver);
      setNewRun_Machines(data.machines);
      setNewRun_Warehouse(data.warehouse);
      newRun_Region(data.region);
      setNewRun_Day(data.day);
      onChangeTime(data.time);
      setStartTime(data.startTime)
      setTimezone(data.timezone);
      setContextDependent(data.contextDependent);
      setRefillThreshold(data.refillThreshold);
    });
  };

  function renderMachines() {
    if (!contextDependent) {
      return (
        <FormControl
          variant="standard"
          sx={{
            m: 1,
            minWidth: 120,
            "margin-left": "30px",
            "margin-right": "30px",
          }}
        >
          {availableMachines.map((item, index) => (
            <FormControlLabel
              label={item.number + " - " + item.name}
              control={
                <Checkbox
                  onChange={(e) =>
                    handleSetChecked(e, availableMachines[index])
                  }
                />
              }
            />
          ))}
        </FormControl>
      );
    }
  }

  
  function renderContextMachines() {
    if (contextDependent) {
      return (
        <>
          <FormControl>
            {availableMachines.map((item, index) => (
              <TextField
                sx={{
                  "margin-top": "20px",
                  "margin-left": "15px",
                  "margin-right": "15px",
                }}
                size="small"
                id="newNumber"
                variant="outlined"
                placeholder={`${item.number} - ${item.name} - Refill Threshold`}
                onChange={(e) => {
                  handleSetThreshold(e, availableMachines[index]);
                }}
              ></TextField>
            ))}
          </FormControl>
        </>
      );
    }
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={5}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <MDBox
                  mx={3}
                  mt={0}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h4" color="white">
                    {dayOfTheWeek(runDoc.day) + "s - " + runDoc.time}
                  </MDTypography>
                </MDBox>
                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                >
                  <InputLabel id="region-label">
                    Region - {newRun_Region}
                  </InputLabel>
                  <Select
                    labelId="region-label"
                    id="region-label"
                    label="Region"
                    value={newRun_Region}
                    onChange={(e) =>
                      changeRegion(state.regions[e.target.value].name)
                    }
                  >
                    {state.regions?.map((item, index) => (
                      <MenuItem value={index}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {renderMachines()}
                <Divider/>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ "margin-left": "30px" }}
                      onChange={(e) => handleSelectContext(e)}
                    />
                  }
                  label="Context-Dependent Machines in Run?"
                />

                {renderContextMachines()}

                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                >
                  <InputLabel id="warehouse-label">
                    Warehouse - {newRun_Warehouse.name}
                  </InputLabel>
                  <Select
                    labelId="warehouse-label"
                    id="warehouse-label"
                    label="Warehouse"
                    value={newRun_Warehouse.name}
                    onChange={(e) =>
                      setNewRun_Warehouse(availableWarehouses[e.target.value])
                    }
                  >
                    {availableWarehouses.map((item, index) => (
                      <MenuItem value={index}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                >
                  <InputLabel id="driver-label">
                    Driver - {runDoc.driver?.name}
                  </InputLabel>
                  <Select
                    labelId="driver-label"
                    id="driver-label"
                    label="Driver"
                    value={newRun_Driver.name}
                    onChange={(e) =>
                      setNewRun_Driver(availableDrivers[e.target.value])
                    }
                  >
                    {availableDrivers.map((item, index) => (
                      <MenuItem value={index}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                >
                  <InputLabel id="day-label1">
                    Day - {dayOfTheWeek(runDoc.day)}
                  </InputLabel>
                  <Select
                    labelId="day-label"
                    id="day-label"
                    label="Day"
                    onChange={(e) => setNewRun_Day(e.target.value)}
                  >
                    {availableDays.map((item, index) => (
                      <MenuItem value={index}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                >
                  <MDTypography variant="caption">
                    Run Start Time - Only select 15 minute increments
                  </MDTypography>
                  <TimePicker onChange={setStartTime} value={startTime} />
                </FormControl>

                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                >
                  <MDTypography variant="caption">
                    Time Run Is Created (Must Be Same Day But Before Start Time) - Only select 15 minute increments
                  </MDTypography>
                  <TimePicker onChange={onChangeTime} value={timeValue} />
                </FormControl>

                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                >
                  <MDTypography variant="caption">Timezone</MDTypography>
                  <TimezoneSelect value={timezone} onChange={setTimezone} />
                </FormControl>

                <MDButton
                  align="center"
                  sx={textboxStyle}
                  color="success"
                  variant="contained"
                  onClick={onUpdateRun}
                >
                  Update Run
                </MDButton>
                <MDBox
                  mx={2}
                  mt={0}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="white"
                  borderRadius="lg"
                ></MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}
