import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import {
  Grid,
  Card,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDButton from "../../ui-components/MDButton";
import MDAlert from "../../ui-components/MDAlert";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useDBManager } from "../../contexts/DBManager_Context";
import BasicLayout from "../../layouts/BasicLayout";
import Moment from "moment";
import DataTable_Generic from "../Tables/DataTable_Generic";
import DataTable_Warehouses from "../Tables/DataTable_Warehouses";
import DataTable_MI from "../Tables/DataTable_MI";
import openTicketData from "../data/openTickets";
import closedTicketData from "../data/closedTicketData";
import rejectedTicketData from "../data/rejectedTicketData";
import HandleLogout from "../HandleLogout";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function CS_Dashboard() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { updateFields } = useDBManager();
  const { state } = useDBManager();
  const { currentUser } = useAuth();
  let currentDate = new Date();
  const [startDate, setStartDate] = useState(0);
  let date = new Date();
  const [month] = useState(String(date.getMonth() + 1));
  const [year] = useState(String(date.getFullYear()));
  let { columns, rows } = openTicketData(
    state.openTickets,
    openDialog,
    openEdit
  );
  const [closedTickets, setClosedTickets] = useState([]);
  let { w_columns, w_rows } = closedTicketData(closedTickets);
  const [rejectedTickets, setRejectedTickets] = useState([]);
  let { mi_columns, mi_rows } = rejectedTicketData(rejectedTickets);
  const [dateValue, setDateValue] = useState(new Date());
  const [newMachine, setNewMachine] = useState("");
  const [newLastDigits, setNewLastDigits] = useState("");
  const [newAmount, setNewAmount] = useState("1");
  const [notes, setNotes] = useState("");
  const [updateIndex, setUpdateIndex] = useState("");
  const [editIndex, setEditIndex] = useState("");

  const [editDateValue, setEditDateValue] = useState(new Date());
  const [editMachine, setEditMachine] = useState("");
  const [editLastDigits, setEditLastDigits] = useState("");
  const [editAmount, setEditAmount] = useState("1");
  const [editNotes, setEditNotes] = useState("");

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenEdit = () => {
    setEditOpen(true);
  };

  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };

  useEffect(async () => {
    await updateFields(["machines", "users", "openTickets"]);
  }, []);

  const handleClose = async (e) => {
    e.preventDefault();
    setOpen(false);
    if (e.target.value == 1) {
      let docRef = collection(
        db,
        "Tickets",
        "Closed",
        state.openTickets[updateIndex].machine.number,
        year,
        month
      );
      let closedTicket = state.openTickets[updateIndex];
      closedTicket.ticketClosed = currentDate;
      addDoc(docRef, closedTicket);
      docRef = doc(db, "Refund Requests", state.openTickets[updateIndex].id);
      deleteDoc(docRef);
      updateFields(["openTickets"]);
    } else if (e.target.value == 2) {
      let docRef = collection(
        db,
        "Tickets",
        "Rejected",
        state.openTickets[updateIndex].machine.number,
        year,
        month
      );
      let rejectedTicket = state.openTickets[updateIndex];
      rejectedTicket.ticketClosed = currentDate;
      rejectedTicket.rejected = true;
      addDoc(docRef, rejectedTicket);
      addDoc(
        collection(db, "Tickets", "Notifications", "Notifications"),
        rejectedTicket
      );
      docRef = doc(db, "Refund Requests", state.openTickets[updateIndex].id);
      deleteDoc(docRef);
      updateFields(["openTickets"]);
    } else if (e.target.value == 3) {
      let docRef = doc(
        db,
        "Refund Requests",
        state.openTickets[updateIndex].id
      );
      deleteDoc(docRef);
      updateFields(["openTickets"]);
    }
  };

  const handleEditClose = async (e) => {
    setEditOpen(false);
    if (e == 1) {
      console.log(e);
      let docRef = doc(db, "Refund Requests", state.openTickets[editIndex].id);
      updateDoc(docRef, {
        lastDigits: editLastDigits,
        machine: editMachine,
        amount: editAmount,
        date: editDateValue,
        notes: editNotes,
      });
      updateFields(["openTickets"]);
    }
  };

  async function logoutFunc(e) {
    e.preventDefault();
    await logout();
    navigate("/login");
  }

  function renderOpenTickets() {
    return (
      <>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <MDBox
              mx={0}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="dark"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Open Tickets
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable_Generic
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={true}
                showTotalEntries={true}
                canSearch={true}
              />
            </MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  function renderNewTicket() {
    return (
      <>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <MDBox
              mx={0}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="dark"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Submit a new Ticket
              </MDTypography>
            </MDBox>
            <Grid container>
              <Grid item xs={1} />
              <Grid item xs={10}>
                <form onSubmit={(e) => onCreateTicket(e)}>
                  <TextField
                    sx={{
                      "margin-top": "20px",
                      "margin-left": "15px",
                      "margin-right": "15px",
                    }}
                    size="small"
                    id="newNumber"
                    variant="outlined"
                    placeholder={`Last 4 Digits of the Card`}
                    onChange={(e) => {
                      setNewLastDigits(e.target.value);
                    }}
                  ></TextField>
                  <FormControl
                    variant="standard"
                    sx={{
                      m: 1,
                      minWidth: 120,
                      "margin-left": "15px",
                      "margin-right": "15px",
                      "margin-top": "20px",
                    }}
                  >
                    <InputLabel id="product-label">
                      Machine - {newMachine.number + " - " + newMachine.name}
                    </InputLabel>
                    <Select
                      labelId="mach-label"
                      id="mach-label"
                      label="Machine"
                      value={newMachine.number + " - " + newMachine.name}
                      onChange={(e) =>
                        setNewMachine(state.machines[e.target.value])
                      }
                    >
                      {state.machines?.map((item, index) => (
                        <MenuItem value={index}>
                          {item.number + " - " + item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    align="center"
                    sx={{ "margin-left": "15px", "margin-top": "20px" }}
                  >
                    <InputLabel id="product-label">Number of Slices</InputLabel>
                    <Select
                      sx={{
                        "margin-top": "20px",
                        "margin-left": "30px",
                        "margin-right": "50px",
                      }}
                      size="small"
                      id="newAmount"
                      variant="outlined"
                      value={newAmount}
                      onChange={(e) => setNewAmount(e.target.value)}
                    >
                      <MenuItem value={"1"}>1</MenuItem>
                      <MenuItem value={"2"}>2</MenuItem>
                      <MenuItem value={"3"}>3</MenuItem>
                      <MenuItem value={"4"}>4</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ textboxStyle, "margin-top": "20px" }}
                    id="date"
                    label="Date"
                    type="date"
                    defaultValue={Moment().format()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setDateValue(e.target.value)}
                  />
                  <TextField
                    sx={{
                      "margin-top": "20px",
                      "margin-left": "15px",
                      "margin-right": "15px",
                    }}
                    size="small"
                    id="notes"
                    variant="outlined"
                    placeholder={`Notes`}
                    onChange={(e) => {
                      setNotes(e.target.value);
                    }}
                  ></TextField>
                  <MDButton
                    align="center"
                    sx={{
                      "margin-top": "20px",
                      "margin-left": "15px",
                      "margin-right": "15px",
                    }}
                    color="success"
                    variant="contained"
                    type="submit"
                  >
                    Create Ticket
                  </MDButton>
                </form>
              </Grid>
            </Grid>
            <MDBox
              mx={2}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="white"
              borderRadius="lg"
            ></MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  function renderClosedTickets() {
    return (
      <>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <MDBox
              mx={0}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="dark"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Closed Refund Tickets
              </MDTypography>
            </MDBox>
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["year", "month"]}
                label="Year and Month"
                minDate={dayjs("2023-01-01")}
                maxDate={dayjs("2030-12-31")}
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
            <MDBox py={3}>
              <MDButton
                color="info"
                variant="contained"
                onClick={(e) => fetchTicketsLastXDays(e, "CLOSED")}
                fullWidth
              >
                View Closed Tickets
              </MDButton>
            </MDBox>
            <MDBox pt={3}>
              <DataTable_Warehouses
                table={{ w_columns, w_rows }}
                isSorted={false}
                entriesPerPage={true}
                showTotalEntries={true}
              />
            </MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  function renderRejectedTickets() {
    return (
      <>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <MDBox
              mx={0}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="dark"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Rejected Refund Tickets
              </MDTypography>
            </MDBox>
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["year", "month"]}
                label="Year and Month"
                minDate={dayjs("2023-01-01")}
                maxDate={dayjs("2030-12-31")}
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
            <MDBox py={3}>
              <MDButton
                color="info"
                variant="contained"
                onClick={(e) => fetchTicketsLastXDays(e, "REJECTED")}
                fullWidth
              >
                View Rejected Tickets
              </MDButton>
            </MDBox>
            <MDBox pt={3}>
              <DataTable_MI
                table={{ mi_columns, mi_rows }}
                isSorted={false}
                entriesPerPage={true}
                showTotalEntries={true}
              />
            </MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  async function fetchTicketsLastXDays(e, type) {
    e.preventDefault();
    let adjustmentData = [];
    let sDate = startDate.$M + 1;
    let eDate = startDate.$y;
    if (type == "CLOSED") {
      const requests = state.machines.map(async (machine) => {
        return await getDocs(
          collection(
            db,
            "Tickets",
            "Closed",
            machine.number,
            String(eDate),
            String(sDate)
          )
        ).then((response) => {
          response.forEach((doc) => {
            adjustmentData.push(doc.data());
          });
        });
      });
      return Promise.all(requests).then(() => {
        setClosedTickets((prev) => adjustmentData);
      });
    } else if (type == "REJECTED") {
      const requests = state.machines.map(async (machine) => {
        return await getDocs(
          collection(
            db,
            "Tickets",
            "Rejected",
            machine.number,
            String(eDate),
            String(sDate)
          )
        ).then((response) => {
          response.forEach((doc) => {
            adjustmentData.push(doc.data());
          });
        });
      });
      return Promise.all(requests).then(() => {
        setRejectedTickets((prev) => adjustmentData);
      });
    }
  }

  async function onCreateTicket(e) {
    e.preventDefault();
    if (newMachine === "" || newLastDigits === "" || newAmount === "") {
      alert("Please fill out all required fields");
    } else {
      //Create new ticket
      const collectionRef = collection(db, "Refund Requests");
      await addDoc(collectionRef, {
        agent: state.currentUserData,
        machine: newMachine,
        amount: newAmount,
        date: dateValue,
        lastDigits: newLastDigits,
        ticketCreated: currentDate,
        notes: notes,
        rejected: false,
      });
      setNewMachine((prev) => "");
      setNewLastDigits((prev) => "");
      setNewAmount((prev) => "");
      setDateValue((prev) => "");
      setNotes((prev) => "");
      alert("Ticket Created");
      updateFields(["openTickets"]);
      e.target.reset();
    }
  }

  function openDialog(e, index) {
    e.preventDefault();
    setUpdateIndex((prev) => index);
    handleClickOpen();
  }

  function openEdit(e, index) {
    e.preventDefault();
    setEditIndex((prev) => index);
    setEditDateValue((prev) => state.openTickets[index].date);
    setEditMachine((prev) => state.openTickets[index].machine);
    setEditLastDigits((prev) => state.openTickets[index].lastDigits);
    setEditAmount((prev) => state.openTickets[index].amount);
    setEditNotes((prev) => state.openTickets[index].notes);
    handleClickOpenEdit();
  }

  function renderUpdateDialog() {
    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Update Ticket</DialogTitle>
          <DialogContent>
            <DialogContentText>
              What would you like to do with this ticket?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                onChange={(e) => handleClose(e)}
              >
                <MenuItem value={1}>Resolve</MenuItem>
                <MenuItem value={2}>Reject</MenuItem>
                <MenuItem value={3}>Delete</MenuItem>
              </Select>
            </FormControl>
            <MDButton onClick={(e) => handleClose(e)}>Close</MDButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  function renderEditDialog() {
    return (
      <>
        <Dialog open={editOpen} onClose={handleEditClose}>
          <DialogTitle>Edit Ticket</DialogTitle>
          <DialogContent>
            <DialogContentText>
              What would you like to do with this ticket?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <FormControl fullWidth>
              <InputLabel
                sx={{
                  "margin-top": "-30px",
                }}
                id="editdigits-label"
              >
                Last 4 Digits of the Card
              </InputLabel>
              <TextField
                sx={{
                  "margin-left": "15px",
                  "margin-right": "15px",
                }}
                size="small"
                id="editNumber"
                variant="outlined"
                placeholder={`Last 4 Digits of the Card`}
                value={editLastDigits}
                onChange={(e) => {
                  setEditLastDigits(e.target.value);
                }}
              ></TextField>
              <FormControl
                variant="standard"
                align="center"
                sx={{
                  "margin-top": "30px",
                  "margin-left": "15px",
                  "margin-right": "15px",
                }}
              >
                <InputLabel
                  sx={{
                    "margin-top": "-15px",
                  }}
                  id="product-label"
                >
                  Machine
                </InputLabel>
                <Select
                  size="small"
                  id="editmach"
                  variant="outlined"
                  value={editMachine.number}
                  onChange={(e) =>
                    setEditMachine(state.machines[e.target.value])
                  }
                >
                  {state.machines?.map((item, index) => (
                    <MenuItem value={index}>
                      {item.number + " - " + item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                align="center"
                sx={{
                  "margin-top": "30px",
                  "margin-left": "15px",
                  "margin-right": "15px",
                }}
              >
                <InputLabel
                  sx={{
                    "margin-top": "-15px",
                  }}
                  id="editSlices-label"
                >
                  Number of Slices
                </InputLabel>
                <Select
                  size="small"
                  id="editAmount"
                  variant="outlined"
                  value={editAmount}
                  onChange={(e) => setEditAmount(e.target.value)}
                >
                  <MenuItem value={"1"}>1</MenuItem>
                  <MenuItem value={"2"}>2</MenuItem>
                  <MenuItem value={"3"}>3</MenuItem>
                  <MenuItem value={"4"}>4</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{
                  textboxStyle,
                  "margin-top": "15px",
                  "margin-left": "15px",
                  "margin-right": "15px",
                }}
                id="date"
                label="Date"
                type="date"
                value={editDateValue}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setEditDateValue(e.target.value)}
              />
              <FormControl
                variant="standard"
                align="center"
                sx={{
                  "margin-top": "15px",
                  "margin-left": "15px",
                  "margin-right": "15px",
                }}
              >
                <InputLabel
                  sx={{
                    "margin-top": "-25px",
                  }}
                  id="editNotes-label"
                >
                  Notes
                </InputLabel>
                <TextField
                  sx={{
                    "margin-top": "10px",
                    "margin-bottom": "20px",
                  }}
                  size="small"
                  id="editnotes"
                  variant="outlined"
                  placeholder={`Notes`}
                  value={editNotes}
                  onChange={(e) => {
                    setEditNotes(e.target.value);
                  }}
                ></TextField>
              </FormControl>
              <MDButton
                variant="contained"
                color="warning"
                onClick={(e) => handleEditClose(1)}
              >
                Finish Edit
              </MDButton>
              <MDButton onClick={(e) => handleEditClose(0)}>Close</MDButton>
            </FormControl>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <>
      {currentUser ? (
        <BasicLayout>
          <MDBox pt={6} pb={5}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <MDBox
                    mx={0}
                    mt={0}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="dark"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      ERG Vending Suite - Customer Support Portal
                    </MDTypography>
                    <MDTypography variant="caption" color="white">
                      v{state.version}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
              {renderNewTicket()}
              {renderOpenTickets()}
              {renderClosedTickets()}
              {renderRejectedTickets()}
              <Grid item xs={12} md={12} lg={12}>
                <MDButton
                  justifyContent="center"
                  variant="text"
                  onClick={(e) => logoutFunc(e)}
                  fullWidth
                >
                  <MDAlert color="dark">
                    <MDTypography
                      justifycontent="center"
                      variant="h6"
                      color="white"
                    >
                      Log Out
                    </MDTypography>
                  </MDAlert>
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </BasicLayout>
      ) : (
        <HandleLogout />
      )}
      {renderUpdateDialog()}
      {renderEditDialog()}
    </>
  );
}
