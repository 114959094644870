import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { useParams } from "react-router-dom";
import { collection, getDocs, doc } from "firebase/firestore";
import {
  Grid,
  Container,
  Card,
  CardContent,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputLabel,
} from "@mui/material";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDButton from "../../ui-components/MDButton";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import { useDBManager } from "../../contexts/DBManager_Context";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

export default function Machine_View_ReplenishmentAnalysis() {
  const { docID } = useParams();
  const { updateFields } = useDBManager();
  const { state, fetchDocument, fetchCollection } = useDBManager();
  const [machine, setMachine] = useState({});
  const [plan, setPlan] = useState([]);
  const [planLabels, setPlanLabels] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date().setDate(currentDate.getDate() - 28)
  );
  const [realEndDate, setRealEndDate] = useState(new Date(endDate));
  const [dateArray, setDateArray] = useState([]);
  const [productDataset, setProductDataset] = useState([]);
  const [totalDataset, setTotalDataset] = useState([]);
  const [waiting, setWaiting] = useState(true);

  useEffect(() => {
    updateFields(["machines", "products"]);
    fetchDocument(doc(db, "Machines", docID)).then((data) => {
      setMachine(data);
    });
  }, []);

  useEffect(() => {
    fetchSalesLast30Days();
    fetchCollection(collection(db, "Machines", docID, "plans")).then((data) => {
      let pdata = data.sort(
        (a, b) => parseInt(b.requestDateTime) - parseInt(a.requestDateTime)
      );
      let x = pdata[0];
      assembleSMLabels(x);
      setPlan(x);
    });
  }, [machine]);

  async function fetchSalesLast30Days() {
    let x_dates = getDates(new Date(currentDate), new Date(realEndDate));
    let localData = [];
    const localRequests = x_dates.map(async (item, index) => {
      let x_year = String(item.getFullYear());
      let x_month = String(parseInt(item.getMonth()) + 1);
      let x_day = String(item.getDate());
      if (parseInt(x_month) < 10) {
        x_month = "0" + String(x_month);
      }
      if (parseInt(x_day) < 10) {
        x_day = "0" + String(x_day);
      }
      return await getDocs(
        collection(db, "Sales", machine.number, x_year, x_month, x_day)
      ).then((response) => {
        response.forEach((doc) => {
          localData.push(doc.data());
        });
      });
    });
    return Promise.all(localRequests).then(() => {
      processSalesByProduct(localData);
    });
  }

  function assembleSMLabels(p) {
    let h = {
      products: [],
      mapping: [],
      maxQuantities: [],
      totalMapping: 0,
      totalMax: 0,
    };

    p.shelves?.forEach((item, index) => {
      if (!h.products.includes(item.productCode)) {
        h.products.push(item.productCode);
        h.mapping.push(item.currentStock);
        h.maxQuantities.push(item.maxStock);
        h.totalMapping = h.totalMapping + item.currentStock;
        h.totalMax = h.totalMax + item.maxStock;
      } else {
        let x = h.mapping[h.products.indexOf(item.productCode)];
        x = x + item.currentStock;
        h.mapping[h.products.indexOf(item.productCode)] = x;

        let y = h.maxQuantities[h.products.indexOf(item.productCode)];
        y = y + item.maxStock;
        h.maxQuantities[h.products.indexOf(item.productCode)] = y;

        h.totalMapping = h.totalMapping + item.currentStock;
        h.totalMax = h.totalMax + item.maxStock;
      }
    });

    h.products.forEach((item, index) => {
      state.products.forEach((entry, kndex) => {
        if (
          item == entry.bud ||
          item == entry.altBudCode1 ||
          item == entry.altBudCode2 ||
          item == entry.altBudCode3
        ) {
          h.products[index] = entry.name;
        }
      });
    });
    setPlanLabels(h);
    console.log(h);
  }

  async function processSalesByProduct(sales) {
    let dataset = [];
    let local_totalDataset = [];
    let localDates = [];

    dateArray.forEach((date) => {
      localDates.push(convertDate(date));
    });
    localDates.sort((a, b) => (a > b ? 1 : -1));

    localDates.forEach((date) => {
      local_totalDataset.push({
        name: date,
        Sales: 0,
      });
    });

    sales.sort((a, b) => (a.dateTime < b.dateTime ? 1 : -1));

    sales.forEach((sale) => {
      let saleDate = sale.dateTime.slice(0, -9);
      let index = localDates.findIndex((x) => x === saleDate);
      let label = sale.productName;
      let productIndex = dataset.findIndex((x) => x.label === label);
      if (productIndex === -1) {
        dataset.push({
          label: sale.productName,
          data: makeDataArray(index),
          color: "info",
        });
      } else {
        if (
          dataset[productIndex].data[index] === undefined ||
          dataset[productIndex].data[index] === null ||
          isNaN(dataset[productIndex].data[index])
        ) {
          dataset[productIndex].data[index] = 1;
        } else {
          dataset[productIndex].data[index] =
            dataset[productIndex].data[index] + 1;
        }
      }

      local_totalDataset[index].Sales = local_totalDataset[index].Sales + 1;
    });
    setTotalDataset(local_totalDataset);
    let pArray = [];
    localDates.forEach((date, index) => {
      let obj = {
        name: date,
      };
      dataset.forEach((product) => {
        obj[product.label] = product.data[index];
      });
      pArray.push(obj);
    });
    setProductDataset(pArray);

    await new Promise((r) => setTimeout(r, 3000));
    setWaiting(false);
  }

  function makeDataArray(index) {
    let dataArray = new Array(dateArray.length).fill(0);
    dataArray[index] = 1;
    return dataArray;
  }

  function makeTotalDataArray(index) {
    let dataArray = new Array(dateArray.length).fill(0);
    dataArray[index] = 1;
    return dataArray;
  }

  function convertDate(date) {
    let year = String(date.getFullYear());
    let month = String(parseInt(date.getMonth()) + 1);
    let day = String(date.getDate());
    if (parseInt(month) < 10) {
      month = "0" + String(month);
    }
    if (parseInt(day) < 10) {
      day = "0" + String(day);
    }

    return year + "-" + month + "-" + day;
  }

  function getDates(start, stop) {
    let l_dateArray = new Array();
    let myDate = start;
    while (myDate >= stop) {
      myDate.setDate(myDate.getDate() - 1);
      l_dateArray.push(new Date(myDate));
    }
    setDateArray(l_dateArray);
    return l_dateArray;
  }

  function matchColors(entry) {
    let x = "";
    state.products.forEach((item, index) => {
      if (entry == item.name)
        if (
          item.name == entry ||
          item.altName1 == entry ||
          item.altName2 == entry ||
          item.altName3 == entry
        ) {
          x = item.color;
        }
    });
    return x;
  }

  const renderLine = (key, color) => (
    <Line
      type="monotone"
      dataKey={`${key}`}
      stroke={color}
      activeDot={{ r: 3 }}
    />
  );

  const renderArea = (key, color) => (
    <Area type="monotone" dataKey={key} stroke={color} fill={color} />
  );

  function renderProductChart() {
    if (!waiting) {
      let productArray = [];
      productDataset.forEach((date) => {
        Object.keys(date)
          .filter((item) => {
            return item != "name";
          })
          .map((key) => {
            if (key != "name" && !productArray.includes(key))
              productArray.push(key);
          });
      });
      return (
        <LineChart
          width={1200}
          height={500}
          data={productDataset}
          margin={{
            top: 20,
            right: 50,
            left: 10,
            bottom: 5,
          }}
          legendType="square"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {productArray.map((key) => {
            return renderLine(key, matchColors(key));
          })}
        </LineChart>
      );
    }
  }

  function renderTotalChart() {
    if (!waiting) {
      console.log(totalDataset);
      return (
        <AreaChart
          width={1200}
          height={400}
          data={totalDataset}
          margin={{
            top: 20,
            right: 50,
            left: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          {renderArea("Sales", "#3f3757")}
        </AreaChart>
      );
    }
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={3}
                mt={0}
                py={3}
                px={2}
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  {machine.number} - {machine.name}
                </MDTypography>
              </MDBox>
              <Grid item xs={12}>
                {renderProductChart()}
              </Grid>
              <Grid item xs={12}>
                {renderTotalChart()}
              </Grid>
              <MDBox
                mx={2}
                mt={0}
                py={3}
                px={2}
                variant="gradient"
                bgColor="white"
                borderRadius="lg"
              ></MDBox>
            </Card>
          </Grid>
        </Grid>
        <Footer />
      </DashboardLayout>
    </>
  );
}
