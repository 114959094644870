// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar";
import MDBadge from "../../ui-components/MDBadge";
import MDButton from "../../ui-components/MDButton";
import { useNavigate } from "react-router-dom";

export default function data(sheetData, openRunPage) {
  const Author = ({ driver }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {driver}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ machines }) => (
    <>
      <MDBox lineHeight={1} textAlign="left">
        {machines?.map((machine, index) => (
          <MDTypography
            display="block"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {machine.name}
          </MDTypography>
        ))}
      </MDBox>
    </>
  );

  const Details = ({ detail }) => (
    <>
      <MDBox lineHeight={1} textAlign="left">
        <MDTypography
          display="block"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {detail}
        </MDTypography>
      </MDBox>
    </>
  );

  function dayOfTheWeek(day) {
    switch (day) {
      case 0:
        return "Monday";
      case 1:
        return "Tuesday";
      case 2:
        return "Wednesday";
      case 3:
        return "Thursday";
      case 4:
        return "Friday";
      case 5:
        return "Saturday";
      case 6:
        return "Sunday";
    }
  }

  let rows = [];
  sheetData?.map((mach, index) => {
    rows.push({
      author: <Author driver={mach.driver.name} />,
      function: <Job machines={mach.machines} />,
      day: <Details detail={dayOfTheWeek(mach.day)} />,
      time: <Details detail={mach.startTime} />,
      action: (
        <MDButton onClick={(e) => openRunPage(mach.id)} size="small">
          View
        </MDButton>
      ),
    });
  });

  return {
    columns: [
      { Header: "Driver", accessor: "author", width: "45%", align: "left" },
      { Header: "Machines", accessor: "function", align: "left" },
      { Header: "Day", accessor: "day", align: "left" },
      { Header: "Start Time", accessor: "time", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows,
  };
}
