import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { useParams, useNavigate } from "react-router-dom";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import {
  Grid,
  Paper,
  Card,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  TextField,
  InputLabel,
} from "@mui/material";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDButton from "../../ui-components/MDButton";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import DataTable_Generic from "../Tables/DataTable_Generic";
import DataTable_Warehouses from "../Tables/DataTable_Warehouses";
import DataTable_MI from "../Tables/DataTable_MI";
import DataTable_WI from "../Tables/DataTable_WI";
import { Delete } from "@mui/icons-material";
import { storage } from "../../firebase";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import machineDataTable from "../data/machinesData";
import warehouseDataTable from "../data/warehousesData";
import inventoryMIDataTable from "../data/inventory_miData";
import inventoryWIDataTable from "../data/inventory_wiData";
import { useDBManager } from "../../contexts/DBManager_Context";

export default function Region_View() {
  const { docID } = useParams();
  const navigate = useNavigate();

  const { updateFields } = useDBManager();
  const { state, fetchDocument } = useDBManager();

  let date = new Date();
  let [month, day, year] = [
    String(date.getMonth() + 1),
    String(date.getDate()),
    String(date.getFullYear()),
  ];

  const [region, setRegion] = useState({});
  const [newName, setNewName] = useState("");
  const [newChecklist, setNewChecklist] = useState([]);
  const [newChecklistItem, setNewChecklistItem] = useState("");

  const [localMachines, setLocalMachines] = useState([]);
  const [localWarehouses, setLocalWarehouses] = useState([]);

  const [regionalMachineInventory, setRegionalMachineInventory] = useState([]);
  const [regionalWarehouseInventory, setRegionalWarehouseInventory] = useState(
    []
  );

  const [emailList, setEmailList] = useState([]);
  const [newEmailItem, setNewEmailItem] = useState("");

  let { w_columns, w_rows } = warehouseDataTable(
    localWarehouses,
    openWarehousePage
  );
  let { columns, rows } = machineDataTable(localMachines, openMachinePage);

  let { mi_columns, mi_rows } = inventoryMIDataTable(
    regionalMachineInventory,
    state.products
  );
  let { wi_columns, wi_rows } = inventoryWIDataTable(
    regionalWarehouseInventory,
    state.products
  );

  const [imageUpload, setImageUpload] = useState(null);
  const imageStyle = { width: "200px", height: "140px" };
  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };

  const checkListStyle = {
    "margin-top": "10px",
    "margin-left": "60px",
    "margin-right": "30px",
  };

  useEffect(() => {
    updateFields(["regions", "machines", "warehouses", "products"]);
    //Get region
    fetchDocument(doc(db, "Regions", docID)).then((data) => {
      setRegion(data);
      setNewName(data.name);
      setNewChecklist(data.checklist);
      setEmailList(data.emailList ? data.emailList : []);
    });
  }, []);

  useEffect(() => {
    getRegionalInventory();
  }, [region]);

  async function getRegionalInventory() {
    let myMachines = [];
    let myWarehouses = [];
    let machineData = [];
    let warehouseData = [];
    state.machines?.map(async (machine, index) => {
      if (machine.region.name == region.name) {
        myMachines.push(machine);
        let localData = [];
        if (machine.active) {
          return getDocs(
            collection(
              db,
              "Stock",
              "Reports",
              "Daily Inventory Position",
              machine.number,
              year,
              month,
              day
            )
          ).then((response) => {
            response.forEach((doc) => {
              localData.push(doc.data());
            });
            machineData.push(localData[0]);
          });
        }
      }
    });
    const localWRequests = state.warehouses?.map(async (warehouse, index) => {
      if (warehouse.servicing == region.name) {
        myWarehouses.push(warehouse);
        let localData = [];
        return getDocs(
          collection(
            db,
            "Stock",
            "Reports",
            "Daily Inventory Position",
            warehouse.name,
            year,
            month,
            day
          )
        ).then((response) => {
          response.forEach((doc) => {
            localData.push(doc.data());
          });
          warehouseData.push(localData);
        });
      }
    });

    return Promise.all(localWRequests).then(() => {
      setLocalMachines(myMachines);
      setLocalWarehouses(myWarehouses);

      let homo_machineData = [];
      machineData.forEach((machine) => {
        state.machines?.forEach((machItem) => {
          if (machine.machineNumber == machItem.number) {
            if (machItem.active) {
              let localInv = [];
              machine.shelves.forEach((lane) => {
                let exists = false;
                localInv.forEach((item, index) => {
                  if (item.productCode == lane.productCode) {
                    localInv[index].currentStock += parseInt(lane.currentStock);
                    exists = true;
                  }
                });
                if (!exists) {
                  localInv.push({
                    productCode: lane.productCode,
                    currentStock: parseInt(lane.currentStock),
                  });
                }
              });
              homo_machineData.push(localInv);
            }
          }
        });
      });
      let matchedInventoryData = [];
      homo_machineData.forEach((machine) => {
        machine.forEach((item) => {
          matchedInventoryData.push({
            name: matchNames(item.productCode),
            quantity: item.currentStock,
          });
        });
      });
      let regionalMInventory = [];
      matchedInventoryData.forEach((item) => {
        let exists = false;
        regionalMInventory.forEach((regItem, index) => {
          if (regItem.name == item.name) {
            let u =
              regionalMInventory[index].quantity + parseInt(item.quantity);
            regItem.quantity += parseInt(item.quantity);
            regionalMInventory[index].quantity = u;
            exists = true;
          }
        });
        if (!exists) {
          regionalMInventory.push(item);
        }
      });
      setRegionalMachineInventory(regionalMInventory);

      let regionalWInventory = [];
      warehouseData.forEach((warehouse) => {
        warehouse.forEach((item) => {
          let exists = false;
          regionalWInventory.forEach((localItem, index) => {
            if (localItem.name == item.name) {
              let u =
                regionalWInventory[index].quantity + parseInt(item.quantity);
              regionalWInventory[index].quantity = u;
              exists = true;
            }
          });
          if (!exists) {
            regionalWInventory.push(item);
          }
        });
      });
      setRegionalWarehouseInventory(regionalWInventory);
    });
  }

  function matchNames(entry) {
    let x = "";
    state.products?.forEach((item, index) => {
      if (item.bud == entry) {
        x = item.name;
      } else if (item.altBudCode1 == entry) {
        x = item.altName1;
      } else if (item.altBudCode2 == entry) {
        x = item.altName2;
      } else if (item.altBudCode3 == entry) {
        x = item.altName3;
      }
    });
    if (x == "") {
      return entry;
    } else {
      return x;
    }
  }

  function openMachinePage(docID) {
    console.log("/machines/" + docID.replace(/ /g, ""));
    navigate("/machines/" + docID);
  }

  function openWarehousePage(docID) {
    console.log("/warehouses/" + docID.replace(/ /g, ""));
    navigate("/warehouses/" + docID);
  }

  const onUpdateRegion = () => {
    //update region
    if (imageUpload) {
      const imageRef = ref(storage, `images/regions/${region.imageURL}`);
      deleteObject(imageRef).then(() => {
        let newImageRef = ref(storage, `images/regions/${region.name}`);
        uploadBytes(newImageRef, imageUpload).then((response) => {
          alert("Region Updated");
          getDownloadURL(response.ref).then((link) => {
            const productDocRef = doc(db, "Regions", docID);
            updateDoc(productDocRef, {
              name: newName,
              url: link,
              checklist: newChecklist,
              emailList: emailList,
            });
          });
        });
      });
    } else {
      const productDocRef = doc(db, "Regions", docID);
      updateDoc(productDocRef, {
        name: newName,
        url: region.imageURL,
        checklist: newChecklist,
        emailList: emailList,
      });
    }
    updateFields(["regions"]);
    alert("Region Updated");
  };

  function onDeleteFromChecklist(e, index) {
    e.preventDefault();
    let temp = newChecklist;
    temp.splice(index, 1);
    setNewChecklist((prev) => [...temp]);
  }

  function onAddToChecklist(e) {
    e.preventDefault();
    let temp = newChecklist;
    temp.push({ text: newChecklistItem, done: false });
    setNewChecklist((prev) => [...temp]);
    setNewChecklistItem((prev) => "");
  }

  function onDeleteEmailFromList(e, index) {
    e.preventDefault();
    let temp = emailList;
    temp.splice(index, 1);
    setEmailList((prev) => [...temp]);
  }

  function onAddEmailToList(e) {
    e.preventDefault();
    let temp = emailList;
    temp.push(newEmailItem);
    setEmailList((prev) => [...temp]);
    setNewEmailItem((prev) => "");
    const productDocRef = doc(db, "Regions", docID);
    updateDoc(productDocRef, {
      emailList: emailList,
    });
  }

  function renderMachineInfo() {
    return (
      <>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={3}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h5" color="white">
                {region.name}
              </MDTypography>
            </MDBox>
            <TextField
              align="center"
              sx={{
                "margin-top": "10px",
                "margin-left": "30px",
                "margin-right": "30px",
              }}
              size="small"
              id="newName"
              variant="outlined"
              placeholder={`Name - ${region.name}`}
              onChange={(e) => {
                setNewName(e.target.value);
              }}
            ></TextField>
            <InputLabel sx={textboxStyle}>
              <img style={imageStyle} src={region.imageURL} alt="" />
              <input
                type="file"
                onChange={(e) => {
                  setImageUpload(e.target.files[0]);
                }}
              />
            </InputLabel>
            <Paper elevation={3}>
              <List>
                <ListSubheader sx={checkListStyle}>
                  <MDTypography variant="h6">Checklist</MDTypography>
                </ListSubheader>
                {newChecklist.map((item, index) => (
                  <ListItem>
                    <IconButton
                      color="error"
                      onClick={(e) => {
                        onDeleteFromChecklist(e, index);
                      }}
                    >
                      <Delete />
                    </IconButton>
                    <MDTypography variant="caption">{item.text}</MDTypography>
                  </ListItem>
                ))}
              </List>
              <TextField
                align="center"
                sx={textboxStyle}
                size="small"
                id="newCheck"
                variant="outlined"
                placeholder={"New Checklist Item"}
                onChange={(e) => setNewChecklistItem(e.target.value)}
              ></TextField>
              <MDButton
                color="warning"
                variant="contained"
                size="small"
                sx={textboxStyle}
                onClick={onAddToChecklist}
              >
                Add to Checklist
              </MDButton>
            </Paper>
            <MDButton
              align="center"
              sx={{
                "margin-top": "10px",
                "margin-left": "30px",
                "margin-right": "30px",
              }}
              color="success"
              variant="contained"
              onClick={onUpdateRegion}
            >
              Update Region Info
            </MDButton>
            <MDBox
              mx={2}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="white"
              borderRadius="lg"
            ></MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  function renderLocations() {
    return (
      <>
        <Grid item xs={12} md={6} lg={6}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Machines
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable_Generic
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={true}
                showTotalEntries={true}
              />
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Warehouses
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable_Warehouses
                table={{ w_columns, w_rows }}
                isSorted={false}
                entriesPerPage={true}
                showTotalEntries={true}
              />
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Total Inventory for Machines In Region
              </MDTypography>
              <MDTypography variant="caption" color="white">
                Updated 00:00 on {month}-{day}-{year}
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable_MI
                table={{ mi_columns, mi_rows }}
                isSorted={false}
                entriesPerPage={true}
                showTotalEntries={true}
              />
            </MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  function renderInventories() {
    return (
      <>
        <Grid item xs={12} md={6} lg={6}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Total Inventory for Warehouses In Region
              </MDTypography>
              <MDTypography variant="caption" color="white">
                Updated 00:00 on {month}-{day}-{year}
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable_WI
                table={{ wi_columns, wi_rows }}
                isSorted={false}
                entriesPerPage={true}
                showTotalEntries={true}
              />
            </MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  function renderInactiveSub() {
    return (
      <>
        <Grid item xs={12} md={6} lg={6}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Subscriptions
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <Paper elevation={3}>
                <List>
                  <ListSubheader sx={checkListStyle}>
                    <MDTypography variant="h6">Email List</MDTypography>
                  </ListSubheader>
                  {emailList?.map((item, index) => (
                    <ListItem>
                      <IconButton
                        color="error"
                        onClick={(e) => {
                          onDeleteEmailFromList(e, index);
                        }}
                      >
                        <Delete />
                      </IconButton>
                      <MDTypography variant="caption">{item}</MDTypography>
                    </ListItem>
                  ))}
                </List>
                <TextField
                  align="center"
                  sx={textboxStyle}
                  size="small"
                  id="newCheck"
                  variant="outlined"
                  placeholder={"Email Address"}
                  onChange={(e) => setNewEmailItem(e.target.value)}
                ></TextField>
                <MDButton
                  color="warning"
                  variant="contained"
                  size="small"
                  sx={textboxStyle}
                  onClick={onAddEmailToList}
                >
                  Subscribe
                </MDButton>
              </Paper>
            </MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={5}>
          <Grid container spacing={6}>
            {renderLocations()}
            {renderInventories()}
            {renderMachineInfo()}
            {renderInactiveSub()}
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}
