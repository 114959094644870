import React, { useRef, useState } from "react";
import { Form, Alert, Button } from "react-bootstrap";
import { Grid, Card } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import MDBox from "../ui-components/MDBox";
import MDTypography from "../ui-components/MDTypography";
import MDInput from "../ui-components/MDInput";
import MDButton from "../ui-components/MDButton";
import BasicLayout from "../layouts/BasicLayout";

export default function Signup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const displayNameRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  let date = new Date();
  const [month, setMonth] = useState(String(date.getMonth() + 1));
  const [day, setDay] = useState(String(date.getDate()));
  const [year, setYear] = useState(String(date.getFullYear()));

  let todaysDate = year + "-" + month + "-" + day;

  async function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }
    try {
      setError("");
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
      onCreateUser(displayNameRef.current.value);
      navigate("/login");
    } catch {
      setError("Failed to create an account");
    }
    setLoading(false);
  }

  const onCreateUser = (name) => {
    //Create new user in database, not auth
    const usersCollectionRef = collection(db, "Users");
    addDoc(usersCollectionRef, {
      name: name,
      regions: [""],
      email: emailRef.current.value.toLowerCase(),
      admin: false,
      active: true,
      employed: todaysDate,
      customerSupport: false,
      role: "driver"
    });
  };

  return (
    <>
      <BasicLayout>
        <Grid container>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3} />
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card align="center">
              <MDBox
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Sign Up
                </MDTypography>
              </MDBox>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group id="email">
                  <Form.Label>Email - </Form.Label>
                  <Form.Control type="email" ref={emailRef} required />
                </Form.Group>
                <Form.Group id="password">
                  <Form.Label>Password - </Form.Label>
                  <Form.Control type="password" ref={passwordRef} required />
                </Form.Group>
                <Form.Group id="password-confirm">
                  <Form.Label>Confirm Password - </Form.Label>
                  <Form.Control
                    type="password"
                    ref={passwordConfirmRef}
                    required
                  />
                </Form.Group>
                <Form.Group id="display-name">
                  <Form.Label>Full Name - </Form.Label>
                  <Form.Control ref={displayNameRef} required />
                </Form.Group>
                <MDButton color="info" disabled={loading} className="w-100" type="submit">
                  Sign Up
                </MDButton>
              </Form>
              <MDBox mt={0} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Already have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/login"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Log In
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3} />
        </Grid>
      </BasicLayout>
    </>
  );
}
