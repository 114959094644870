// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "../../../../ui-components/MDBox";
import MDTypography from "../../../../ui-components/MDTypography";
import MDButton from "../../../../ui-components/MDButton";

function SimpleBlogCard({
  image,
  name,
  title,
  machines,
  action,
  width,
  height,
  customFunction,
  runID
}) {
  return (
    <Card>
      <MDBox position="relative" borderRadius="lg" mt={-1} mx={6} >
        <MDBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          shadow="sm"
          width={width}
          height={height}
          position="relative"
          zIndex={1}
        />
        <MDBox
          borderRadius="lg"
          shadow="md"
          width={width}
          height={height}
          position="absolute"
          left={0}
          top="3%"
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.84)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MDBox>
      <MDBox p={1} justifyContent="center" >
      <MDTypography
          display="inline"
          variant="h6"
          textTransform="capitalize"
          fontWeight="bold"
        >
          {name}
        </MDTypography>
        <br />
        <MDTypography
          display="inline"
          variant="h6"
          textTransform="capitalize"
          fontWeight="bold"
        >
          {title}
        </MDTypography>
        <br />
        <MDBox mt={2} mb={3}>
          {machines?.map((machine) => (
            <MDTypography variant="body2" component="p" color="text">
              {machine.name}
            </MDTypography>
          ))}
        </MDBox>
        {action.type === "external" ? (
          <MDButton color={action.color ? action.color : "dark"} onClick={(e) => customFunction(e, runID)}>
            {action.label}
          </MDButton>
        ) : (
          <MDButton color={action.color ? action.color : "dark"} onClick={(e) => customFunction(e, runID)}>
            {action.label}
          </MDButton>
        )}
      </MDBox>
    </Card>
  );
}

// Typechecking props for the SimpleBlogCard
SimpleBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default SimpleBlogCard;
