// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar";
import MDBadge from "../../ui-components/MDBadge";
import MDButton from "../../ui-components/MDButton";

export default function data(
  warehouseData,
  machineData,
  products,
  warehouses,
  machines
) {
  const Mapping = ({ mapping }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {mapping}
      </MDTypography>
      <MDTypography variant="caption"></MDTypography>
    </MDBox>
  );

  const Product = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption"></MDTypography>
    </MDBox>
  );

  let mi_rows = [];
  let mi_columns = [
    {
      Header: "Product",
      accessor: "product",
      width: "45%",
      align: "left",
    },
  ];

  products.map((item, pndex) => {
    let a = [];
    mi_rows.push({
      product: <Product title={item.name} />,
    });
    warehouseData.map((warehouse, index) => {
      let x = warehouse.filter((product) => product.name === item.name);
      let y = 0;
      x.forEach((product) => {
        y = y + product.quantity;
      });
      a.push(y);
    });
    a.forEach((entry, index) => {
      mi_rows[pndex][warehouses[index].name] = <Mapping mapping={entry} />;
    });
    a = [];
    machineData.map((machine, index) => {
      let x = machine.products.filter((product) => product === item.name);
      let y = 0;
      machine.products.forEach((thing, tndex) => {
        if (thing === item.name) {
          y = y + machine.mapping[tndex];
        }
      });
      a.push(y);
    });

    let total = 0;
    a.forEach((item, index) => {
      if (index != 0) {
        total = total + parseInt(item);
      }
    });
    if (total != 0) {
      a.forEach((entry, index) => {
        mi_rows[pndex][machines[index].name] = <Mapping mapping={entry} />;
      });
    }
  });

  machines.map((machine, index) => {
    mi_columns.push({
      Header: machine.number + " - " + machine.name,
      accessor: machine.name,
      align: "left",
    });
  });

  warehouses.map((warehouse, index) => {
    mi_columns.push({
      Header: warehouse.number + " - " + warehouse.name,
      accessor: warehouse.name,
      align: "left",
    });
  });

  return {
    mi_columns,
    mi_rows,
  };
}
