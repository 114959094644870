import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { db } from "../../firebase";
import { collection, addDoc, getDocs } from "firebase/firestore";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDButton from "../../ui-components/MDButton";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import DataTable_Generic from "../Tables/DataTable_Generic";
import DataTable_Warehouses from "../Tables/DataTable_Warehouses";
import DataTable_MI from "../Tables/DataTable_MI";
import machineDataTable from "../data/machinesData";
import warehouseDataTable from "../data/warehousesData";
import { useDBManager } from "../../contexts/DBManager_Context";
import { useAuth } from "../../contexts/AuthContext";
import inventoryMIDataTable from "../data/inventory_miData";
import HandleLogout from "../HandleLogout";

export default function Inventory_Manager() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  let [newMachineName, setNewMachineName] = useState("");
  let [newMachineID, setNewMachineID] = useState("");
  let [newMachineRegion, setNewMachineRegion] = useState([]);

  let [newWarehouseName, setNewWarehouseName] = useState("");
  let [newWarehouseRegion, setNewWarehouseRegion] = useState([]);

  let [totalInventory, setTotalInventory] = useState([]);

  let date = new Date();
  let [month, day, year] = [
    String(date.getMonth() + 1),
    String(date.getDate()),
    String(date.getFullYear()),
  ];

  const { updateFields } = useDBManager();
  const { state } = useDBManager();

  let { w_columns, w_rows } = warehouseDataTable(
    state.warehouses,
    openWarehousePage
  );
  let { mi_columns, mi_rows } = inventoryMIDataTable(
    totalInventory,
    state.products
  );
  let { columns, rows } = machineDataTable(state.machines, openMachinePage);

  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };

  useEffect(() => {
    updateFields(["machines", "warehouses", "regions", "products"]);
  }, []);

  useEffect(() => {
    getTotalInventory();
  }, [state]);

  function openMachinePage(docID) {
    navigate("/machines/" + docID);
  }

  function openWarehousePage(docID) {
    navigate("/warehouses/" + docID);
  }

  async function onCreateMachine() {
    //Create new machine
    const machinesCollectionRef = collection(db, "Machines");
    let newDoc = await addDoc(machinesCollectionRef, {
      name: newMachineName,
      number: newMachineID,
      region: newMachineRegion.name,
      active: false,
      merchantNum: "",
      ercID: "",
      ptid: "",
      refillThreshold: 50,
    });
    let newShelves = [];
    for (let i = 0; i < 49; i++) {
      newShelves.push({
        maxStock: 4,
        productCode: "bud_7",
      });
    }
    const machDocRef = collection(db, "Machines", newDoc.id, "planogram");
    await addDoc(machDocRef, {
      planogram: {
        shelves: newShelves,
      },
    });
    setNewMachineName("");
    setNewMachineID("");
    setNewMachineRegion("");
    alert("Machine Created");
    updateFields(["machines"]);
  }

  async function onCreateWarehouse() {
    //Create new machine
    const warehouseCollectionRef = collection(db, "Warehouses");
    let newDoc = await addDoc(warehouseCollectionRef, {
      name: newWarehouseName,
      number: "Warehouse",
      servicing: newWarehouseRegion.name,
    });
    let newShelves = [];
    for (let i = 0; i < 49; i++) {
      newShelves.push({
        maxStock: 4,
        productCode: "bud_7",
      });
    }
    const machDocRef = collection(db, "Machines", newDoc.id, "planogram");
    await addDoc(machDocRef, {
      planogram: {
        shelves: newShelves,
      },
    });
    setNewWarehouseName("");
    setNewWarehouseRegion("");
    alert("Warehouse Created");
    updateFields(["warehouses"]);
  }

  function matchNames(entry) {
    let x = "";
    state.products?.forEach((item, index) => {
      if (item.bud == entry) {
        x = item.name;
      } else if (item.altBudCode1 == entry) {
        x = item.name;
      } else if (item.altBudCode2 == entry) {
        x = item.name;
      } else if (item.altBudCode3 == entry) {
        x = item.name;
      }
    });
    if (x == "") {
      return entry;
    } else {
      return x;
    }
  }

  async function getTotalInventory() {
    let myMachines = [];
    let myWarehouses = [];
    let machineData = [];
    let warehouseData = [];
    state.machines?.forEach(async (machine, index) => {
      myMachines.push(machine);
      let localData = [];
      if (machine.active) {
        return getDocs(
          collection(
            db,
            "Stock",
            "Reports",
            "Daily Inventory Position",
            machine.number,
            year,
            month,
            day
          )
        ).then((response) => {
          response.forEach((doc) => {
            localData.push(doc.data());
          });
          machineData.push(localData[0]);
        });
      }
    });
    const localWRequests = state.warehouses?.forEach(async (warehouse, index) => {
      myWarehouses.push(warehouse);
      let localData = [];
      return getDocs(
        collection(
          db,
          "Stock",
          "Reports",
          "Daily Inventory Position",
          warehouse.name,
          year,
          month,
          day
        )
      ).then((response) => {
        response.forEach((doc) => {
          localData.push(doc.data());
        });
        warehouseData.push(localData);
      });
    });

    return Promise.all(localWRequests).then(() => {
      let homo_machineData = [];
      machineData.forEach((machine) => {
        let localInv = [];
        machine.shelves.forEach((lane) => {
          let exists = false;
          localInv.forEach((item, index) => {
            if (item.productCode == lane.productCode) {
              localInv[index].currentStock += parseInt(lane.currentStock);
              exists = true;
            }
          });
          if (!exists) {
            localInv.push({
              productCode: lane.productCode,
              currentStock: parseInt(lane.currentStock),
            });
          }
        });
        homo_machineData.push(localInv);
      });
      let matchedInventoryData = [];
      homo_machineData.forEach((machine) => {
        machine.forEach((item) => {
          matchedInventoryData.push({
            name: matchNames(item.productCode),
            quantity: item.currentStock,
          });
        });
      });
      let regionalMInventory = [];
      matchedInventoryData.forEach((item) => {
        let exists = false;
        regionalMInventory.forEach((regItem, index) => {
          if (regItem.name == item.name) {
            let u = regionalMInventory[index].quantity;
            u = u + parseInt(item.quantity);
            regItem.quantity += parseInt(item.quantity);
            regionalMInventory[index].quantity = u;
            exists = true;
          }
        });
        if (!exists) {
          regionalMInventory.push(item);
        }
      });
      //add regional inventory to warehouse inventory

      let regionalWInventory = [];
      warehouseData.forEach((warehouse) => {
        warehouse.forEach((item) => {
          let exists = false;
          regionalWInventory.forEach((localItem, index) => {
            if (localItem.name == item.name) {
              let u = regionalWInventory[index].quantity;
              u = u + parseInt(item.quantity);
              regionalWInventory[index].quantity = u;
              exists = true;
            }
          });
          if (!exists) {
            regionalWInventory.push(item);
          }
        });
      });
      let grandInventory = [];

      regionalWInventory.forEach((item, kndex) => {
        let exists = false;
        grandInventory.forEach((localItem, index) => {
          if (localItem.name == item.name) {
            let u = parseInt(localItem.quantity) + parseInt(item.quantity);
            grandInventory[index].quantity = u;
            exists = true;
          }
        });
        if (!exists) {
          grandInventory.push({
            name: item.name,
            quantity: item.quantity,
          });
        }
      });
      regionalMInventory.forEach((item, kndex) => {
        let exists = false;
        grandInventory.forEach((localItem, index) => {
          if (localItem.name == item.name) {
            let u = parseInt(localItem.quantity) + parseInt(item.quantity);
            grandInventory[index].quantity = u;
            exists = true;
          }
        });
        if (!exists) {
          grandInventory.push({
            name: item.name,
            quantity: item.quantity,
          });
        }
        setTotalInventory(grandInventory);
      });
    });
  }

  return (
    <>
      {currentUser ? (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox pt={6} pb={5}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6} lg={6}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Machines
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable_Generic
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={true}
                      showTotalEntries={true}
                    />
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Warehouses
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable_Warehouses
                      table={{ w_columns, w_rows }}
                      isSorted={false}
                      entriesPerPage={true}
                      showTotalEntries={true}
                    />
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Inventory Grand Total
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable_MI
                      table={{ mi_columns, mi_rows }}
                      isSorted={false}
                      entriesPerPage={true}
                      showTotalEntries={true}
                    />
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Card>
                  <MDBox
                    mx={3}
                    mt={0}
                    py={2}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Add New Machine
                    </MDTypography>
                  </MDBox>
                  <TextField
                    align="center"
                    sx={textboxStyle}
                    size="small"
                    id="newMachineName"
                    variant="outlined"
                    placeholder="Machine Name"
                    onChange={(e) => {
                      setNewMachineName(e.target.value);
                    }}
                  ></TextField>
                  <TextField
                    sx={textboxStyle}
                    size="small"
                    id="newMachineNumber"
                    variant="outlined"
                    placeholder="Number"
                    onChange={(e) => {
                      setNewMachineID(e.target.value);
                    }}
                  ></TextField>
                  <FormControl
                    variant="standard"
                    sx={{
                      m: 1,
                      minWidth: 120,
                      "margin-left": "30px",
                      "margin-right": "30px",
                    }}
                  >
                    <InputLabel id="product-label">Region</InputLabel>
                    <Select
                      labelId="region-label"
                      id="region-label"
                      label="Region"
                      value={newMachineRegion.name}
                      onChange={(e) =>
                        setNewMachineRegion(state.regions[e.target.value])
                      }
                    >
                      {state.regions?.map((item, index) => (
                        <MenuItem value={index}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <MDButton
                    align="center"
                    sx={textboxStyle}
                    color="success"
                    variant="contained"
                    onClick={onCreateMachine}
                  >
                    Create Machine
                  </MDButton>
                  <MDBox
                    mx={2}
                    mt={0}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="white"
                    borderRadius="lg"
                  ></MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Card>
                  <MDBox
                    mx={3}
                    mt={0}
                    py={2}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Add New Warehouse
                    </MDTypography>
                  </MDBox>
                  <TextField
                    align="center"
                    sx={textboxStyle}
                    size="small"
                    id="newWarehouseName"
                    variant="outlined"
                    placeholder="Warehouse Name"
                    onChange={(e) => {
                      setNewWarehouseName(e.target.value);
                    }}
                  ></TextField>
                  <FormControl
                    variant="standard"
                    sx={{
                      m: 1,
                      minWidth: 120,
                      "margin-left": "30px",
                      "margin-right": "30px",
                    }}
                  >
                    <InputLabel id="product-label">Region</InputLabel>
                    <Select
                      labelId="region1-label"
                      id="region1-label"
                      label="Region"
                      value={newWarehouseRegion.name}
                      onChange={(e) =>
                        setNewWarehouseRegion(state.regions[e.target.value])
                      }
                    >
                      {state.regions?.map((item, index) => (
                        <MenuItem value={index}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <MDButton
                    align="center"
                    sx={textboxStyle}
                    color="success"
                    variant="contained"
                    onClick={onCreateWarehouse}
                  >
                    Create Warehouse
                  </MDButton>
                  <MDBox
                    mx={2}
                    mt={0}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="white"
                    borderRadius="lg"
                  ></MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          <Footer />
        </DashboardLayout>
      ) : (
        <HandleLogout />
      )}
    </>
  );
}
