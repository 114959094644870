// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDBadge from "../../ui-components/MDBadge";
import MDButton from "../../ui-components/MDButton";
import { Card, Grid } from "@mui/material";
import inventoryDashData from "../data/inventoryDashData";
import DataTable_Generic from "../Tables/DataTable_Generic";

export default function data(machineObjects, products) {
  const imageStyle = { width: "50px", height: "50px" };

  const InvCard = ({ machine }) => (
    <Card
      display="flex"
      lineHeight={1}
      style={{ backgroundColor: machine.region.color }}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <MDBox ml={2} mr={2} pt={1} lineHeight={1}>
          <Grid item xs={5}>
            <MDBox ml={3} mr={3} justifyContent="center" lineHeight={1}>
              <Card>
                <MDTypography
                  ml={2}
                  pt={1}
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  verticalAlign="middle"
                >
                  {machine.number + " - " + machine.name}
                </MDTypography>
              </Card>
            </MDBox>
          </Grid>
          <Grid item xs={3}>
            <MDBox ml={3} mr={3} pt={1} justifyContent="center" lineHeight={1}>
              <Card>
                <MDTypography
                  ml={2}
                  pt={1}
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  verticalAlign="middle"
                >
                  {machine.region.name}
                </MDTypography>
              </Card>
            </MDBox>
          </Grid>
          <MDBox pt={1} ml={2} mr={2} pb={2}>
            <DataTable_Generic
              table={inventoryDashData(machine, products)}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
        </MDBox>
      </Grid>
    </Card>
  );

  let rows = [];
  machineObjects?.map((item, index) => {
    rows.push({
      card: <InvCard machine={item} />,
    });
  });

  return {
    columns: [
      { Header: "Machine", accessor: "card", width: "45%", align: "left" },
    ],
    rows,
  };
}
