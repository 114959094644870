import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  addDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import BasicLayout from "../../layouts/BasicLayout";
import MDTypography from "../../ui-components/MDTypography";
import MDAlert from "../../ui-components/MDAlert";
import MDBox from "../../ui-components/MDBox";
import MDInput from "../../ui-components/MDInput";
import MDButton from "../../ui-components/MDButton";
import { useDBManager } from "../../contexts/DBManager_Context";

export default function Transfer_DriverView() {
  const { docID } = useParams();
  let date = new Date();
  const [month, setMonth] = useState(String(date.getMonth() + 1));
  const [day, setDay] = useState(String(date.getDate()));
  const [year] = useState(String(date.getFullYear()));

  const { updateFields } = useDBManager();
  const { state } = useDBManager();

  const navigate = useNavigate();
  const imageStyle = { width: "40px", height: "40px" };
  const [transferDoc, setTransferDoc] = useState({});
  const [receivedProducts, setReceivedProducts] = useState([]);
  const [o_warehouseProducts, setO_WarehouseProducts] = useState([]);
  const [o_warehouseProductDocIDs, setO_WarehouseProductDocIDs] = useState([]);
  const [d_warehouseProducts, setD_WarehouseProducts] = useState([]);
  const [d_warehouseProductDocIDs, setD_WarehouseProductDocIDs] = useState([]);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const handleSuccessClickOpen = () => {
    setSuccessOpen(true);
  };
  const handleSuccessClose = () => {
    setSuccessOpen(false);
  };

  useEffect(() => {
    updateFields(["products", "users"]);
    let t_month = "";
    let t_day = "";
    if (parseInt(month) < 10) {
      t_month = "0" + String(month);
      setMonth(t_month);
    }
    if (parseInt(day) < 10) {
      t_day = "0" + String(day);
      setDay(t_day);
    }
  }, []);

  useEffect(() => {
    fetchTransfer();
  }, [state]);

  useEffect(() => {
    let x = [];
    JSON.parse(JSON.stringify(transferDoc)).products?.forEach((entry, kndex) => {
      x.push({
        name: entry.name,
        batch: entry.batch,
        quantity: 0,
        expiry: entry.expiry,
      });
    });
    setReceivedProducts(x);
    fetchO_WarehouseProducts();
    fetchD_WarehouseProducts();
  }, [transferDoc]);

  async function fetchO_WarehouseProducts() {
    let querySnapshot = await getDocs(
      collection(db, "Warehouses", transferDoc.originID, "products")
    );
    let pdata = [];
    let pids = [];
    querySnapshot.forEach((doc) => {
      pdata.push(doc.data());
      pids.push(doc.id);
    });
    return Promise.resolve(pdata, pids).then(() => {
      let x = pdata;
      let y = pids;
      setO_WarehouseProducts((prev) => x);
      setO_WarehouseProductDocIDs((prev) => y);
    });
  }

  async function fetchD_WarehouseProducts() {
    let querySnapshot = await getDocs(
      collection(db, "Warehouses", transferDoc.destinationID, "products")
    );
    let pdata = [];
    let pids = [];
    querySnapshot.forEach((doc) => {
      pdata.push(doc.data());
      pids.push(doc.id);
    });
    return Promise.resolve(pdata, pids).then(() => {
      let x = pdata;
      let y = pids;
      setD_WarehouseProducts((prev) => x);
      setD_WarehouseProductDocIDs((prev) => y);
    });
  }

  async function fetchTransfer() {
    let data = {};
    await getDoc(doc(db, "Transfer Data", docID)).then(
      (response) => {
        let id = response.id;
        data = { id, ...response.data() };
      }
    );

    return Promise.resolve(data).then(() => {
      let x = data;
      setTransferDoc((prev) => x);
    });
  }

  function handleReceivedChange(num, p_index) {
    let x = receivedProducts;
    x[p_index].quantity = parseInt(num);
    setReceivedProducts((prev) => x);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    handleSuccessClose();

    let collRef = collection(
      db,
      "Stock",
      "Transfers",
      "Records",
      transferDoc.origin.name,
      year,
      month,
      day
    );
    let x = transferDoc;
    x.receivedProducts = receivedProducts;
    addDoc(collRef, x);

    let y = d_warehouseProducts;
    let z = [];
    x.receivedProducts.forEach((item, index) => {
      let exists = false;
      d_warehouseProducts.forEach((entry, endex) => {
        if (entry.name == item.name && entry.batch == item.batch) {
          y[endex].quantity =
            parseInt(y[endex].quantity) + parseInt(item.quantity);
          exists = true;
        }
      });
      if (!exists) {
        z.push(item);
      }
    });

    y.forEach((item, index) => {
      let docRef = doc(
        db,
        "Warehouses",
        transferDoc.destinationID,
        "products",
        d_warehouseProductDocIDs[index]
      );
      updateDoc(docRef, item);
    });
    z.forEach((item, index) => {
      let colRef = collection(
        db,
        "Warehouses",
        transferDoc.destinationID,
        "products"
      );
      addDoc(colRef, item);
    });

    let a = o_warehouseProducts;
    x.receivedProducts.forEach((item, index) => {
      o_warehouseProducts.forEach((entry, endex) => {
        if (entry.name == item.name && entry.batch == item.batch) {
          a[endex].quantity =
            parseInt(a[endex].quantity) - parseInt(item.quantity);
        }
      });
    });

    a.forEach((item, index) => {
      let docRef = doc(
        db,
        "Warehouses",
        transferDoc.originID,
        "products",
        o_warehouseProductDocIDs[index]
      );
      updateDoc(docRef, item);
    });

    deleteDoc(doc(db, "Transfer Data", docID));
    navigate("/dashboard");
  }

  function matchCaseSizes(entry) {
    let x = "";
    state.products.forEach((item, index) => {
      if (entry.name == item.name)
        if (
          item.name == entry.name ||
          item.altName1 == entry.name ||
          item.altName2 == entry.name ||
          item.altName3 == entry.name
        ) {
          x =
            Math.round(
              (parseInt(entry.quantity) / parseInt(item.caseSize) +
                Number.EPSILON) *
                100
            ) / 100;
        }
    });
    return x;
  }

  function renderPEntry(item, index) {
    let url = "";
    for (let i = 0; i < state.products.length; i++) {
      if (item.name == state.products[i].name) {
        url = state.products[i].url;
        break;
      }
    }

    return (
      <>
        <Grid item xs={1} xl={1} />
        <Grid item xs={2} xl={2}>
          <img align="center" style={imageStyle} src={url} alt=""/>
        </Grid>
        <Grid item xs={8} xl={8}>
          <MDTypography sx={"margin-top: 15px"} variant="h6" noWrap>
            {item.name}
          </MDTypography>
          <MDTypography sx={"margin-top: 15px"} variant="button" noWrap>
            Batch - {item.batch}
          </MDTypography>
        </Grid>
        <Grid item xs={1} xl={1} />
        <Grid item xs={1} xl={1} />
        <Grid item xs={5} xl={5}>
          <MDTypography
            align="right"
            sx={"margin-top: 10px"}
            variant="h6"
            noWrap
          >
            Ordered:{" "}
            {item.quantity}
          </MDTypography>
          <MDTypography
            align="right"
            variant="h6"
            noWrap
          >
            {matchCaseSizes(item) + " cases"}
          </MDTypography>
        </Grid>
        <Grid item xs={1} xl={1} />
        <Grid item xs={4} xl={4}>
          <MDInput
            label="Received:"
            sx={"margin-top: 10px"}
            onChange={(e) => handleReceivedChange(e.target.value, index)}
          />
        </Grid>
        <Grid item xs={1} xl={1} />
      </>
    );
  }

  function renderOrderedProducts() {
    return (
      <>
        <Grid item xs={12}>
          <Card>
            <MDAlert color="info">
              <MDTypography variant="h6" color="white">
                P.O. # {transferDoc.POnumber}
              </MDTypography>
            </MDAlert>
            <MDTypography sx={"margin-left: 15px"} variant="h6" color="text">
              Appt. # {transferDoc.apptNum}
            </MDTypography>
            <MDTypography sx={"margin-left: 15px"} variant="h6" color="text">
              Date - {transferDoc.pickupDate}
            </MDTypography>
            <MDTypography sx={"margin-left: 15px"} variant="h6" color="text">
              Time - {transferDoc.pickupTime}
            </MDTypography>
            <MDTypography sx={"margin-left: 15px"} variant="h6" color="text">
              Pickup - {transferDoc.origin?.name}
            </MDTypography>
            <MDTypography sx={"margin-left: 15px"} variant="h6" color="text">
              DropOff - {transferDoc.destination?.name}
            </MDTypography>
            <MDBox
              mx={2}
              mt={2}
              py={1}
              px={0}
              variant="gradient"
              bgColor="white"
              borderRadius="lg"
            >
              <Grid container spacing={0}>
                {transferDoc.products?.map((item, index) =>
                  renderPEntry(item, index)
                )}
              </Grid>
            </MDBox>
            <MDButton
              justifyContent="center"
              variant="text"
              onClick={(e) => handleSuccessClickOpen(e)}
            >
              <MDAlert color="error">
                <MDTypography
                  justifycontent="center"
                  variant="h6"
                  color="white"
                >
                  Confirm Transfer
                </MDTypography>
              </MDAlert>
            </MDButton>
          </Card>
        </Grid>
      </>
    );
  }

  return (
    <>
      <BasicLayout>
        <Grid container spacing={2} justifyContent="center">
          {renderOrderedProducts()}
        </Grid>
      </BasicLayout>
      <div>
        <Dialog
          open={successOpen}
          onClose={handleSuccessClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Transfer"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you ready to submit this transfer? You will not be able to
              return to this screen once you submit.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleSuccessClose}>No</MDButton>
            <MDButton onClick={handleSubmit} autoFocus>
              Yes
            </MDButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
