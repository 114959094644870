// Material Dashboard 2 React components

import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar"
import MDBadge from "../../ui-components/MDBadge"

export default function data(productData, products) {
  const imageStyle = { width: '50px', height: '50px'}

  const Author = ({ name, url }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
        <img style={imageStyle} src={url} />
        <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
            </MDTypography>
        </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  function matchImages(entry) {
    let x = ""
    products.forEach((item, index) => {
      if (item.name == entry || item.altName1 == entry || item.altName2 == entry || item.altName3 == entry) {
        x = item.url
      }
    })
    return x
  }

  function matchNames(entry) {
    let x = ""
    products.forEach((item, index) => {
      if (item.bud == entry) {
        x = item.bud
      } else if (item.altBudCode1 == entry) {
        x = item.altName1
      } else if (item.altBudCode2 == entry) {
        x = item.altName2
      } else if (item.altBudCode3 == entry) {
        x = item.altName3
      }
    })
    if (x == "") {
        return entry
    } else {
        return x
    }
  }

  let w_rows = []
  productData.products?.map((entry, index) => {
    w_rows.push({
        author: <Author name={matchNames(entry)} url={matchImages(matchNames(entry))} />,
        function: <Job title={productData.maxQuantities[index]} description="" />,
    })
  })

  return {
    w_columns: [
      { Header: "Product", accessor: "author", width: "45%", align: "left" },
      { Header: "Quantity", accessor: "function", align: "left" },
    ],
    w_rows
  };
}
