import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  Checkbox,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  getDocs,
  doc,
  addDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import BasicLayout from "../../layouts/BasicLayout";
import MDTypography from "../../ui-components/MDTypography";
import MDAlert from "../../ui-components/MDAlert";
import MDBox from "../../ui-components/MDBox";
import MDButton from "../../ui-components/MDButton";
import MDInput from "../../ui-components/MDInput";
import { useDBManager } from "../../contexts/DBManager_Context";

export default function Driver_Planogram() {
  let { docID } = useParams();
  const navigate = useNavigate();
  const { updateFields } = useDBManager();
  const { state, fetchCollection } = useDBManager();
  const [planogram, setPlanogram] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentMachine, setCurrentMachine] = useState({});
  const imageStyle = { width: "40px", height: "40px" };

  useEffect(() => {
    updateFields(["users", "machines", "products"]);
  }, []);

  useEffect(() => {
    findPlanogram();
  }, [state]);

  function handleBackBtn(e) {
    e.preventDefault();
    navigate("/dashboard/");
  }

  async function findPlanogram() {
    state.machines?.map(async (item, index) => {
      if (item.docID == docID) {
        setCurrentMachine(item);
        let querySnapshot = await getDocs(
          collection(db, "Machines", docID, "planogram")
        );
        let pdata = [];
        querySnapshot.forEach((doc) => {
          pdata.push(doc.data());
        });
        return Promise.resolve(pdata).then(() => {
          let x = pdata[0];
          let clonedPlanogram = JSON.parse(JSON.stringify(x));
          setPlanogram(clonedPlanogram);
          console.log(clonedPlanogram);
        });
      }
    });
  }

  function renderPlanogram() {
    return (
      <>
        <Grid item xs={12}>
          <Card>
            <MDAlert color="dark">
              <MDTypography justifycontent="center" variant="h6" color="white">
                Planogram
              </MDTypography>
            </MDAlert>
            <MDBox
              mx={2}
              mt={2}
              py={1}
              px={0}
              variant="gradient"
              bgColor="white"
              borderRadius="lg"
            >
              {buildPGrid()}
            </MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  function matchBudWithProduct(bud) {
    let x = "";
    state.products.forEach((item, index) => {
      if (
        item.bud == bud ||
        item.altBudCode1 == bud ||
        item.altBudCode2 == bud ||
        item.altBudCode3 == bud
      ) {
        x = item.url;
      }
    });
    return x;
  }

  function buildPGrid() {
    let elements = [];
    planogram.planogram?.shelves?.forEach((item, index) => {
      elements.push(buildPLane(item, index));
    });
    return (
      <>
        <Grid container spacing={0}>
          {elements.map((item, index) => {
            return <>{item}</>;
          })}
        </Grid>
      </>
    );
  }

  function buildPLane(object, index) {
    let url = matchBudWithProduct(object.productCode);
    return (
      <>
        <Grid item xs={1.66} xl={1.66}>
          <img style={imageStyle} src={url} alt="" />
          <MDTypography variant="h6" sx={{"margin-left": "15px", "margin-top": "-5px"}}>
            {object.maxStock}
          </MDTypography>
        </Grid>
      </>
    );
  }

  return (
    <>
      <BasicLayout>
        <MDBox mt={8}>
          <MDButton
            fullwidth={true}
            justifyContent="center"
            variant="text"
            onClick={(e) => handleBackBtn(e)}
          >
            <MDAlert color="info">
              <MDTypography justifycontent="center" variant="h6" color="white">
                Back
              </MDTypography>
            </MDAlert>
          </MDButton>
          <Grid container justifyContent="center">
            <Grid container spacing={2} justifyContent="center">
              <Grid align="center" item xs={12}>
                <Card>
                  <MDBox
                    mx={1}
                    mt={0}
                    py={2}
                    px={4}
                    variant="gradient"
                    bgColor="white"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h5" color="text">
                      {currentMachine.name}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
              {renderPlanogram()}
            </Grid>
          </Grid>
        </MDBox>
      </BasicLayout>
    </>
  );
}
