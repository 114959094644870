import React, { useRef, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Grid, Card } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import MDBox from "../ui-components/MDBox";
import MDTypography from "../ui-components/MDTypography";
import MDInput from "../ui-components/MDInput";
import MDButton from "../ui-components/MDButton";
import BasicLayout from "../layouts/BasicLayout";

export default function ForgotPassword() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage("Check your inbox for a password reset email");
    } catch {
      setError("Failed to reset password");
    }
    setLoading(false);
  }

  return (
    <>
      <BasicLayout>
        <Grid container>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3} />
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card align="center">
              <MDBox
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Password Reset
                </MDTypography>
              </MDBox>
              {error && <Alert variant="danger">{error}</Alert>}
              {message && <Alert variant="success">{message}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group id="email">
                  <Form.Label>Email - </Form.Label>
                  <Form.Control type="email" ref={emailRef} required />
                </Form.Group>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDButton
                    color="info"
                    disabled={loading}
                    className="w-100"
                    type="submit"
                  >
                    Reset Password
                  </MDButton>
                </MDBox>
              </Form>
              <MDBox mt={0} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Back to{"  "}
                  <MDTypography
                    component={Link}
                    to="/login"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Login
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mt={-2} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Need an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/signup"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign Up
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3} />
        </Grid>
      </BasicLayout>
    </>
  );
}
