import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  TextField,
  InputLabel,
} from "@mui/material";
import { db } from "../../firebase";
import {
  collection,
  addDoc,
} from "firebase/firestore";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDButton from "../../ui-components/MDButton";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import { storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "../../contexts/AuthContext";
import { useDBManager } from "../../contexts/DBManager_Context";
import HandleLogout from "../HandleLogout";

export default function Region_Manager() {
  const navigate = useNavigate();
  const { updateFields } = useDBManager();
  const { state } = useDBManager();
  const { currentUser } = useAuth();
  const [newName, setNewName] = useState("");
  const [imageUpload, setImageUpload] = useState(null);
  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };

  useEffect(() => {
    updateFields(["regions"]);
  }, []);

  function openRegionPage(e, docID) {
    e.preventDefault();
    navigate("/regions/" + docID);
  }

  const onCreateRegion = () => {
    //Create new region
    if (newName != "" && imageUpload != null) {
      const imageRef = ref(storage, `images/regions/${newName}`);
      uploadBytes(imageRef, imageUpload).then((response) => {
        alert("Image uploaded");
        getDownloadURL(response.ref).then((link) => {
          const regionsColRef = collection(db, "Regions");
          addDoc(regionsColRef, {
            name: newName,
            imageURL: link,
            color: "",
            checklist: [
              { text: "Clean Glass Door", done: false },
              { text: "Clean Elevator", done: false },
              { text: "Clean Touch Screen", done: false },
              { text: "Restock Forks", done: false },
              { text: "Restock Cleaning Supplies", done: false },
            ],
          });
        });
        setNewName("");
        updateFields(["regions"]);
      });
    }
  };

  function renderRegionCards() {
    return (
      <>
        {state.regions?.map((item, index) => (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Card>
                <CardHeader subheader={item.name} />
                <CardMedia
                  component="img"
                  height="140"
                  image={item.imageURL}
                  alt=""
                />
                <CardContent>
                  <Grid item xs={12} md={12} lg={12}>
                    <MDBox mb={0}>
                      <MDButton
                        color="info"
                        variant="contained"
                        fullWidth
                        onClick={(e) => openRegionPage(e, item.id)}
                      >
                        <MDTypography
                          display="block"
                          variant="caption"
                          color="white"
                          fontWeight="medium"
                        >
                          View
                        </MDTypography>
                      </MDButton>
                    </MDBox>
                  </Grid>
                </CardContent>
              </Card>
            </MDBox>
          </Grid>
        ))}
      </>
    );
  }

  return (
    <>
      {currentUser ? (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox pt={6} pb={5}>
            <Grid container spacing={6}>
              {renderRegionCards()}
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={3}
                    mt={0}
                    py={2}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Add New Region
                    </MDTypography>
                  </MDBox>
                  <TextField
                    align="center"
                    sx={textboxStyle}
                    size="small"
                    id="newMachineName"
                    variant="outlined"
                    placeholder="Region Name"
                    onChange={(e) => {
                      setNewName(e.target.value);
                    }}
                  ></TextField>
                  <InputLabel sx={textboxStyle}>
                    <input
                      type="file"
                      onChange={(e) => {
                        setImageUpload(e.target.files[0]);
                      }}
                    />
                  </InputLabel>
                  <MDButton
                    align="center"
                    sx={textboxStyle}
                    color="success"
                    variant="contained"
                    onClick={onCreateRegion}
                  >
                    Create Region
                  </MDButton>
                  <MDBox
                    mx={2}
                    mt={0}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="white"
                    borderRadius="lg"
                  ></MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          <Footer />
        </DashboardLayout>
      ) : (
        <HandleLogout />
      )}
    </>
  );
}
