// Material Dashboard 2 React components

import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar";
import MDButton from "../../ui-components/MDButton";
import MDBadge from "../../ui-components/MDBadge";

export default function data(driverData, openUserPage) {
  const Author = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ regions, description }) =>
    regions.map((item, index) => {
      return (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography
            display="block"
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            {item}
          </MDTypography>
          <MDTypography variant="caption">{description}</MDTypography>
        </MDBox>
      );
    });

  let rows = [];
  driverData.map((driver, index) => {
    rows.push({
      author: <Author name={driver.name} email={driver.email} />,
      function: <Job regions={driver.regions} description="" />,
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent="active"
            color="success"
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      employed: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {driver.employed}
        </MDTypography>
      ),
      action: (
        <MDButton onClick={(e) => openUserPage(driver.id)} size="small">
          View
        </MDButton>
      ),
    });
  });

  return {
    columns: [
      { Header: "Name", accessor: "author", width: "45%", align: "left" },
      { Header: "region", accessor: "function", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "employed", accessor: "employed", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows,
  };
}
