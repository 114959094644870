// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar";
import MDBadge from "../../ui-components/MDBadge";
import MDButton from "../../ui-components/MDButton";
import { useNavigate } from "react-router-dom";

export default function data(sheetData, openDialog) {
  const Author = ({ driver }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {driver}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ machines }) => (
    <MDBox lineHeight={1} textAlign="left">
      {machines.map((machine, index) => (
        <MDTypography
          display="block"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {machine.name}
        </MDTypography>
      ))}
    </MDBox>
  );

  const Region = ({ region }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {region}
      </MDTypography>
    </MDBox>
  );

  const RunDate = ({ date }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {date}
      </MDTypography>
    </MDBox>
  );

  let w_rows = [];
  sheetData?.map((mach, index) => {
    w_rows.push({
      author: <Author driver={mach.driver.name} />,
      function: <Job machines={mach.machines} />,
      region: <Region region={mach.region} description="" />,
      runDate: <RunDate date={mach.runBeginDate} />,
      action: (
        <MDButton
          variant="caption"
          color="error"
          fontWeight="medium"
          onClick={(e) => openDialog(e, index)}
        >
          Delete
        </MDButton>
      ),
    });
  });

  return {
    w_columns: [
      { Header: "Driver", accessor: "author", width: "45%", align: "center" },
      { Header: "Machines", accessor: "function", align: "left" },
      { Header: "Region", accessor: "region", align: "center" },
      { Header: "Date", accessor: "runDate", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    w_rows,
  };
}
