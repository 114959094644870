import firebase from 'firebase/compat/app'
import "firebase/compat/auth"
import {getFirestore} from '@firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAnalytics } from "firebase/analytics";

const app = firebase.initializeApp({
    apiKey: "AIzaSyBhoMyfDx98mIrm_brf1Zm0MZTs7tjUTUA",
    authDomain: "ergvendingsuite.ca",
    projectId: "erg-app-dev",
    storageBucket: "erg-app-dev.appspot.com",
    messagingSenderId: "389918287574",
    appId: "1:389918287574:web:a53db3a285a8540b094b77"
})

export const db = getFirestore(app, {
     experimentalForceLongPolling: true,
     useFetchStreams: false
})
export const storage = getStorage(app)
export const auth = app.auth()
export const analytics = getAnalytics(app);
export default app