import { useAuth } from '../contexts/AuthContext'
import React, { useEffect } from 'react'
import DriverDashboard from './Driver/DriverDashboard'
import AdminDashboard from './Admin/AdminDashboard'
import { useDBManager } from "../contexts/DBManager_Context";
import CS_Dashboard from './CustomerSupport/CS_Dashboard';
import HandleLogout from './HandleLogout';

export default function Dashboard() {
    const { currentUser, logout } = useAuth();
    const { updateFields } = useDBManager();
    const { state } = useDBManager();

    useEffect(() => {
        console.log(state.currentUserData)
        updateFields(["users"]);
    },[])

    const renderDashboard = () => {
        if (state.currentUserData?.role == "driver") {
            return (
                <>
                    <DriverDashboard/>
                </>
            )
        } else if (state.currentUserData?.role == "admin") {
            return (
                <>
                    <AdminDashboard />
                </>
            )
        } else if (state.currentUserData?.role == "support") {
            return (
                <>
                    <CS_Dashboard />
                </>
            )
        }
    }

    return (
        <>
            {currentUser ? renderDashboard() : <HandleLogout />}
        </>
    )
}
