// Material Dashboard 2 React components

import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar"
import MDBadge from "../../ui-components/MDBadge"
import MDButton from "../../ui-components/MDButton";

export default function data(driverData, selectADriver) {
  const Author = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  let rows = []
  driverData.map((driver, index) => {
    rows.push({
        author: <Author name={driver.name} email={driver.email} />,
        function: <Job title={driver.region} description="" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
            <MDBox mb={0}>
                <MDButton color="error" variant="contained" fullWidth onClick={(e) => selectADriver(e, driver)}>
                    <MDTypography display="block" variant="caption" color="white" fontWeight="medium">
                        Next
                    </MDTypography>
                </MDButton>
            </MDBox>
        ),
    })
  })

  return {
    columns: [
      { Header: "Name", accessor: "author", width: "45%", align: "left" },
      { Header: "region", accessor: "function", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows
  };
}
