// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDBadge from "../../ui-components/MDBadge";
import MDButton from "../../ui-components/MDButton";

export default function data(ticketData) {
  const Author = ({ number, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {number}
        </MDTypography>
        <MDTypography variant="caption">{name}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ last4 }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {last4}
      </MDTypography>
    </MDBox>
  );

  const Merchant = ({ num }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {num}
      </MDTypography>
    </MDBox>
  );

  const Amount = ({ amount }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {amount}
      </MDTypography>
    </MDBox>
  );

  let mi_rows = [];
  ticketData?.map((mach, index) => {
    mi_rows.push({
      author: <Author number={mach.machine.number} name={mach.machine.name} />,
      merchant: <Merchant num={mach.machine.merchantNum} />,
      function: <Job last4={mach.lastDigits} />,
      amount: <Amount amount={mach.amount} />,
      date: <Amount amount={mach.date} />,
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent="rejected"
            color="warning"
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
    });
  });

  return {
    mi_columns: [
      { Header: "status", accessor: "status", align: "center" },
      { Header: "Machine", accessor: "author", width: "45%", align: "left" },
      { Header: "Merchant #", accessor: "merchant", width: "45%", align: "left" },
      { Header: "Last 4 Digits", accessor: "function", align: "left" },
      { Header: "Amount", accessor: "amount", align: "left" },
      { Header: "Date of Purchase", accessor: "date", align: "left" },
    ],
    mi_rows,
  };
}
