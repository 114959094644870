// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar"
import MDBadge from "../../ui-components/MDBadge"
import MDButton from "../../ui-components/MDButton";
import { useNavigate } from "react-router-dom"

export default function data(machineData, openMachinePage) {

  const Author = ({ number, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {number}
        </MDTypography>
        <MDTypography variant="caption">{name}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ region, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {region}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  let rows = []
  machineData?.map((mach, index) => {
    rows.push({
        author: <Author number={mach.number} name={mach.name} />,
        function: <Job region={mach.region.name} description="" />,
        status: mach.active == true ? (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent="active"
              color="success"
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ) : (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent="inactive"
                color="error"
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ),
        action: (
          <MDButton onClick={(e) => openMachinePage(mach.id)} size="small">View</MDButton>
        ),
    })
  })

  return {
    columns: [
      { Header: "ID", accessor: "author", width: "45%", align: "left" },
      { Header: "region", accessor: "function", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows
  };
}
