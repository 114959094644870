/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import Dashboard from "./components/Dashboard";
import React from "react";
import PrivateRoute from "./components//PrivateRoute";
import Inventory_Manager from "./components//Admin/Inventory_Manager";
import HandleLogout from "./components//HandleLogout";
import User_Manager from "./components//Admin/User_Manager";
import Product_Manager from "./components/Admin/Product_Manager";
import Run_Build from "./components/Admin/Run_Build";
import Report_Manager from "./components/Admin/Report_Manager";
import RunSheet_Manager from "./components/Admin/RunSheet_Manager";
import Transfers from "./components/Admin/Transfers";
import Region_Manager from "./components/Admin/Region_Manager";
import Admin_Run_Manager from "./components/Admin/Admin_Run_Manager";
import Run_Schedule from "./components/Admin/Run_Schedule";
import Ticket_Manager from "./components/Admin/Ticket_Manager";
import WriteOff_Manager from "./components/Admin/WriteOff_Manager";
import Inventory_Dashboard from "./components/Admin/Inventory_Dashboard";

// @mui icons
import {
  AccountBalance,
  Storage,
  DoubleArrow,
  AddCircleOutlineOutlined,
  AllInbox,
  Article,
  AddLocation,
  AccessTime,
  Logout,
  Bookmarks,
  Group,
  ThumbDown,
  Assessment,
  Ballot,
} from "@mui/icons-material";

const LocalRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <AccountBalance fontSize="small" />,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "My Runs/Transfers",
    key: "myRuns",
    icon: <Group fontSize="small" />,
    route: "/admin-runs",
    component: (
      <PrivateRoute>
        <Admin_Run_Manager />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "Inventory Dashboard",
    key: "inventorydash",
    icon: <Ballot fontSize="small" />,
    route: "/inventory-dashboard",
    component: (
      <PrivateRoute>
        <Inventory_Dashboard />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "Locations",
    key: "inventory",
    icon: <AllInbox fontSize="small" />,
    route: "/inventory-manager",
    component: (
      <PrivateRoute>
        <Inventory_Manager />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    icon: <Storage fontSize="small" />,
    route: "/products",
    component: (
      <PrivateRoute>
        <Product_Manager />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "Regions",
    key: "regions",
    icon: <AddLocation fontSize="small" />,
    route: "/regions",
    component: (
      <PrivateRoute>
        <Region_Manager />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Assessment fontSize="small" />,
    route: "/reports",
    component: (
      <PrivateRoute>
        <Report_Manager />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "Run Sheets",
    key: "runSheets",
    icon: <Article fontSize="small" />,
    route: "/run-sheets",
    component: (
      <PrivateRoute>
        <RunSheet_Manager />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "New Run",
    key: "runbuilder",
    icon: <AddCircleOutlineOutlined fontSize="small" />,
    route: "/run-builder",
    component: (
      <PrivateRoute>
        <Run_Build />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "New Transfer",
    key: "transfers",
    icon: <DoubleArrow fontSize="small" />,
    route: "/transfer",
    component: (
      <PrivateRoute>
        <Transfers />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "Schedule Run",
    key: "scheduleRun",
    icon: <AccessTime fontSize="small" />,
    route: "/run-scheduler",
    component: (
      <PrivateRoute>
        <Run_Schedule />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "Tickets",
    key: "tickets",
    icon: <Bookmarks fontSize="small" />,
    route: "/tickets",
    component: (
      <PrivateRoute>
        <Ticket_Manager />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Group fontSize="small" />,
    route: "/users",
    component: (
      <PrivateRoute>
        <User_Manager />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "Write Offs",
    key: "writeoffs",
    icon: <ThumbDown fontSize="small" />,
    route: "/write-offs",
    component: (
      <PrivateRoute>
        <WriteOff_Manager />
      </PrivateRoute>
    ),
  },
  {
    type: "collapse",
    name: "Log Out",
    key: "logout",
    icon: <Logout fontSize="small" />,
    route: "/logout",
    component: (
      <PrivateRoute>
        <HandleLogout />
      </PrivateRoute>
    ),
  },
];

export default LocalRoutes;
