import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  DialogTitle,
  TextField,
  DialogContentText,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  addDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";
import MDTypography from "../../ui-components/MDTypography";
import MDAlert from "../../ui-components/MDAlert";
import MDBox from "../../ui-components/MDBox";
import MDInput from "../../ui-components/MDInput";
import MDButton from "../../ui-components/MDButton";
import { useDBManager } from "../../contexts/DBManager_Context";
import Moment from "moment";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import { Parser } from "@json2csv/plainjs";

export default function AdminTest() {
  const { updateFields } = useDBManager();
  const { state, fetchCollection, fetchDocument } = useDBManager();

  let machinesInList = ["4349"];
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [csvData, setCSVData] = useState([]);
  const [dateArray, setDateArray] = useState([]);

  let a_href = `data:text/csv,`+ encodeURIComponent(csvData);

  async function dotheThing(e) {
    e.preventDefault();
    console.log(startDate + " - " + endDate);
    let adjustmentData = [];
    const requests = machinesInList.map(async (machine) => {
      let x_dates = getDates(new Date(startDate), new Date(endDate));
      setDateArray(x_dates)
      let localData = [];
      const localRequests = x_dates.map(async (item, index) => {
        let x_year = String(item.getFullYear());
        let x_month = String(parseInt(item.getMonth()) + 1);
        let x_day = String(item.getDate());
        if (parseInt(x_month) < 10) {
          x_month = "0" + String(x_month);
        }
        if (parseInt(x_day) < 10) {
          x_day = "0" + String(x_day);
        }
        return await getDocs(
          collection(db, "Sales", machine, x_year, x_month, x_day)
        ).then((response) => {
          response.forEach((doc) => {
            localData.push(doc.data());
          });
        });
      });
      return Promise.all(localRequests).then(() => {
        localData.map((item, index) => {
          let saleDate = String(item.dateTime.split(" ")[0]);
          let saleTime = item.dateTime.split(" ")[1];
          let o = item
          o.saleDate = new Date(saleDate).toLocaleDateString("en-US")
          o.saleTime = saleTime;
          adjustmentData.push(o);
        });
      });
    });
    return Promise.all(requests).then(() => {
      console.log(adjustmentData);
    });
  }

  function sanitizeDataByProduct(data) {
    let sanitizedData = []
    data.map((item, index) => {
      let exists = false
      sanitizedData.map((item2, index2) => {
        if (item2.saleDate == item.saleDate && item2.product == item.productName) {
          exists = true
          let t = item2
          t.saleCount = t.saleCount + 1
          sanitizedData[index2] = t
        }
      })
      if (!exists) {
        sanitizedData.push({saleDate: item.saleDate, product: item.productName, saleCount: 1})
      }
    })
    console.log(sanitizedData)
    return (sanitizedData)
  }

  function sanitizeDataByStore(data) {
    let sanitizedData = []
    data.map((item, index) => {
      let exists = false
      sanitizedData.map((item2, index2) => {
        if (item2.saleDate == item.saleDate) {
          exists = true
          let t = item2
          t.saleCount = t.saleCount + 1
          sanitizedData[index2] = t
        }
      })
      if (!exists) {
        sanitizedData.push({saleDate: item.saleDate, saleCount: 1})
      }
    })
    console.log(sanitizedData)
    return (sanitizedData)
  }

  function convertToCSV(myData) {
    try {
      const parser = new Parser();
      const csv = parser.parse(myData);
      console.log(csv);
      setCSVData(csv)
    } catch (err) {
      console.error(err);
    }
  }

  function getDates(start, stop) {
    let dateArray = new Array();
    let currentDate = start;
    while (currentDate <= stop) {
      currentDate.setDate(currentDate.getDate() + 1);
      dateArray.push(new Date(currentDate));
    }
    return dateArray;
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <TextField
            id="sdate"
            label="Start Date"
            type="date"
            defaultValue={Moment().format()}
            sx={{ m: 1, minWidth: 120 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <TextField
            id="edate"
            label="End Date"
            type="date"
            defaultValue={Moment().format()}
            sx={{ m: 1, minWidth: 120 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <MDButton onClick={(e) => dotheThing(e)}>Get data</MDButton>
          <a href={a_href} download="filename.csv">download</a>
        </MDBox>
      </DashboardLayout>
    </>
  );
}
