import React, { useState, useEffect } from "react";
import { Card, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { useDBManager } from "../../contexts/DBManager_Context";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import runsheet_vsData from "../data/runsheet_vsData";
import leftoverRunData from "../data/leftoverRunData";
import DataTable_Generic from "../Tables/DataTable_Generic";
import DataTable_Warehouses from "../Tables/DataTable_Warehouses";

export default function RunSheets_View() {
  const { docID } = useParams();

  const [runDoc, setRunDoc] = useState([]);
  const [machineDocs, setMachineDocs] = useState([]);
  const [year, setYear] = useState([]);
  const [month, setMonth] = useState([]);
  const [day, setDay] = useState([]);

  const { updateFields } = useDBManager();
  const { state } = useDBManager();
  const imageStyle = { width: "50px", height: "50px" };

  const [w_columns, setW_columns] = useState([]);
  const [w_rows, setW_rows] = useState([]);

  useEffect(() => {
    updateFields(["machines", "products"]);
  }, []);

  useEffect(() => {
    filterParams();
  },[state])

  function filterParams() {
    let x = docID.split("-");
    setYear(x[2]);
    setMonth(x[3]);
    setDay(x[4]);
    fetchRunSheet(x);
  }

  function matchBudWithProduct(bud) {
    let x = "";
    state.products.forEach((item, index) => {
      if (
        item.bud == bud ||
        item.altBudCode1 == bud ||
        item.altBudCode2 == bud ||
        item.altBudCode3 == bud
      ) {
        x = item.url;
      }
    });
    return x;
  }

  async function fetchRunSheet(x) {
    let docRef = await getDoc(
      doc(db, "Run Records", x[0], x[2], x[3], x[4], x[1])
    );
    let runData = docRef.data();
    setW_columns(leftoverRunData(
      runData.leftoverProducts,
      state.products
    ).w_columns)
    setW_rows(leftoverRunData(runData.leftoverProducts, state.products).w_rows)

    setRunDoc((prev) => runData);

    let querySnapshot = await getDocs(
      collection(db, "Run Records", x[0], x[2], x[3], x[4], x[1], "machines")
    );
    let pdata = [];
    querySnapshot.forEach((doc) => {
      pdata.push(doc.data());
    });

    return Promise.all(pdata).then(() => {
      let x = pdata;
      setMachineDocs((prev) => x);
    });
  }

  function buildSMLane(object) {
    let url = matchBudWithProduct(object.productCode);
    return (
      <>
        <Grid item xs={1} xl={1}>
          <img style={imageStyle} src={url} alt=""/>
          <MDTypography variant="h6" sx={"margin-left: 20px"}>
            {object.maxStock}
          </MDTypography>
        </Grid>
      </>
    );
  }

  function buildSMGrid(plan) {
    let elements = [];
    plan.shelves?.forEach((item, index) => {
      elements.push(buildSMLane(item));
    });
    return (
      <>
        <Grid container spacing={0}>
          {elements.map((item, index) => {
            if (
              index == 0 ||
              index == 7 ||
              index == 14 ||
              index == 21 ||
              index == 28 ||
              index == 35 ||
              index == 42
            ) {
              return (
                <>
                  <Grid item xs={2.5} xl={2.5} />
                  {item}
                </>
              );
            } else if (
              index == 6 ||
              index == 13 ||
              index == 20 ||
              index == 27 ||
              index == 34 ||
              index == 41 ||
              index == 48
            ) {
              return (
                <>
                  {item}
                  <Grid item xs={2.5} xl={2.5} />
                </>
              );
            } else {
              return <>{item}</>;
            }
          })}
        </Grid>
      </>
    );
  }

  function renderMachine(machine) {
    let productData = {
      orderedProducts: [],
      pickedProducts: [],
      stockedProducts: [],
    };
    machine.orderedProducts.forEach((item, index) => {
      let exists = false;
      productData.orderedProducts.forEach((entry, index) => {
        if (item.name == entry.name && item.batch == entry.batch) {
          exists = true;
          entry.quantity += item.quantity;
        }
      });
      if (!exists) {
        productData.orderedProducts.push({
          name: item.name,
          batch: item.batch,
          quantity: item.quantity,
        });
      }
    });

    machine.pickedProducts.forEach((item, index) => {
      let exists = false;
      productData.pickedProducts.forEach((entry, index) => {
        if (item.name == entry.name && item.batch == entry.batch) {
          exists = true;
          entry.quantity += item.quantity;
        }
      });
      if (!exists) {
        productData.pickedProducts.push({
          name: item.name,
          batch: item.batch,
          quantity: item.quantity,
        });
      }
    });

    machine.stockedProducts.forEach((item, index) => {
      let exists = false;
      productData.stockedProducts.forEach((entry, index) => {
        if (item.name == entry.name && item.batch == entry.batch) {
          exists = true;
          entry.quantity += item.quantity;
        }
      });
      if (!exists) {
        productData.stockedProducts.push({
          name: item.name,
          batch: item.batch,
          quantity: item.quantity,
        });
      }
    });

    productData.orderedProducts.sort((a, b) => (a.name > b.name ? 1 : -1));
    productData.pickedProducts.sort((a, b) => (a.name > b.name ? 1 : -1));
    productData.stockedProducts.sort((a, b) => (a.name > b.name ? 1 : -1));

    let { columns, rows } = runsheet_vsData(productData, state.products);

    return (
      <>
        <Grid item xs={12} md={6} lg={6}>
          <Card>
            <MDBox
              mx={3}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h5" color="white">
                {machine.machineID.number + " - " + machine.machineID.name}
              </MDTypography>
            </MDBox>
            <DataTable_Generic
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
            />

            <MDBox
              mx={2}
              mt={0}
              py={3}
              px={2}
              variant="gradient"
              bgColor="white"
              borderRadius="lg"
            ></MDBox>

            <MDBox mt={8}>
              {/* Stock Map */}
              <MDBox
                color="error"
                bgColor="light"
                variant="gradient"
                borderRadius="lg"
                shadow="lg"
                opacity={1}
                p={2}
              >
                <MDTypography align="center" variant="h5">
                  Stock Map After Replenishment
                </MDTypography>
                <br />
                <MDBox
                  color="error"
                  bgColor="white"
                  variant="gradient"
                  borderRadius="lg"
                  shadow="lg"
                  opacity={1}
                  p={2}
                >
                  {buildSMGrid(machine.driverPlanogram)}
                </MDBox>
              </MDBox>
              <MDBox
                mx={2}
                mt={0}
                py={3}
                px={2}
                variant="gradient"
                bgColor="white"
                borderRadius="lg"
              ></MDBox>
            </MDBox>
          </Card>
        </Grid>
      </>
    );
  }

  function renderLeftovers() {
    if (runDoc.leftoverProducts) {
      return (
        <DataTable_Warehouses
          table={{ w_columns, w_rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
        />
      );
    }
  }

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={5}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={3}
                  mt={0}
                  py={3}
                  px={2}
                  variant="primary"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h4" color="white">
                    {runDoc.driver?.name}
                  </MDTypography>
                  <MDTypography variant="h6" color="white">
                    {year + "-" + month + "-" + day}
                  </MDTypography>
                  <MDTypography variant="h6" color="white">
                    {"Start Time - " + (runDoc.startTime)}
                  </MDTypography>
                  <MDTypography variant="h6" color="white">
                    {"End Time - " + (runDoc.endTime)}
                  </MDTypography>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              {renderLeftovers()}
            </Grid>
            {machineDocs?.map((machine) => renderMachine(machine))}
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}
