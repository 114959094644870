import React, { useContext, useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs, getDoc, addDoc } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import MDButton from "../ui-components/MDButton";
import MDTypography from "../ui-components/MDTypography";
import MDInput from "../ui-components/MDInput";
import {
  Select,
  Grid,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  DialogTitle,
  DialogContentText,
} from "@mui/material";

const DBManager_Context = React.createContext();
const DBManager_UpdateContext = React.createContext();

export function useDBManager() {
  return useContext(DBManager_Context);
}

export function useDBManager_Update() {
  return useContext(DBManager_UpdateContext);
}

export default function DBManager_Provider({ children }) {
  const { currentUser } = useAuth();
  const [state, setState] = useState({
    currentUserData: [],
    machines: [],
    warehouses: [],
    users: [],
    regions: [],
    products: [],
    carriers: [],
    reasons: [],
    devlogs: [],
    openTickets: [],
    version: "1.4.2",
    availableLocations: [],
    availableProducts: [],
  });
  let date = new Date();
  const [month, setMonth] = useState(String(date.getMonth() + 1));
  const [day, setDay] = useState(String(date.getDate()));
  const [year] = useState(String(date.getFullYear()));
  let todaysDate = year + "-" + month + "-" + day;

  const imageStyle = { width: "40px", height: "40px" };

  const [writeOffReason, setWriteOffReason] = useState([]);
  const [writeOffProduct, setWriteOffProduct] = useState({});
  const [writeOffQuantity, setWriteOffQuantity] = useState(0);
  const [writeOffLocation, setWriteOffLocation] = useState({});
  const [writeOffOpen, setWriteOffOpen] = React.useState(false);

  const handleWriteOffClickOpen = () => {
    setWriteOffOpen(true);
  };
  const handleWriteOffClose = () => {
    setWriteOffReason([]);
    setWriteOffProduct({});
    setWriteOffQuantity(0);
    setWriteOffLocation({});
    setWriteOffOpen(false);
  };

  useEffect(() => {
    updateFields(["users"]);
    let t_month = "";
    let t_day = "";
    if (parseInt(month) < 10) {
      t_month = "0" + String(month);
      setMonth(t_month);
    }
    if (parseInt(day) < 10) {
      t_day = "0" + String(day);
      setDay(t_day);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("state", JSON.stringify(state));
  }, [state]);

  function updateFields(fields) {
    fields.forEach((field) => {
      switch (field) {
        case "regions":
          fetchCollection(collection(db, "Regions")).then((response) => {
            let x = response.sort(
              (a, b) => parseInt(a.name) - parseInt(b.name)
            );
            setState((prevState) => ({ ...prevState, regions: x }));
          });
          break;
        case "machines":
          fetchCollection(collection(db, "Machines")).then((response) => {
            let x = response.sort(
              (a, b) => parseInt(a.number) - parseInt(b.number)
            );
            setState((prevState) => ({ ...prevState, machines: x }));
          });
          break;
        case "warehouses":
          fetchCollection(collection(db, "Warehouses")).then((response) => {
            let x = response.sort(
              (a, b) => parseInt(a.region) - parseInt(b.region)
            );
            setState((prevState) => ({ ...prevState, warehouses: x }));
          });
          break;
        case "users":
          fetchCollection(collection(db, "Users")).then((response) => {
            // let x = response.sort(
            //   (a, b) => parseInt(a.region) - parseInt(b.region)
            // );
            setState((prevState) => ({ ...prevState, users: response }));
            response.forEach((user) => {
              if (user.email == currentUser.email) {
                let y = currentUser;
                y = user;
                setState((prevState) => ({
                  ...prevState,
                  currentUserData: y,
                }));
              }
            });
          });
          break;
        case "products":
          fetchCollection(collection(db, "Products")).then((response) => {
            let x = response.sort(
              (a, b) => parseInt(a.name) - parseInt(b.name)
            );
            setState((prevState) => ({ ...prevState, products: x }));
          });
          break;
        case "carriers":
          fetchCollection(collection(db, "Carriers")).then((response) => {
            let x = response.sort(
              (a, b) => parseInt(a.name) - parseInt(b.name)
            );
            setState((prevState) => ({ ...prevState, carriers: x }));
          });
          break;
        case "reasons":
          fetchCollection(
            collection(db, "Stock", "Write-Offs", "_Reasons")
          ).then((response) => {
            let x = response.sort(
              (a, b) => parseInt(a.text) - parseInt(b.text)
            );
            setState((prevState) => ({ ...prevState, reasons: x }));
          });
          break;
        case "devlogs":
          fetchCollection(collection(db, "Devlog")).then((response) => {
            let x = response.sort(function (a, b) {
              return b.version < a.version
                ? -1
                : b.version == a.version
                ? 0
                : 1;
            });
            setState((prevState) => ({ ...prevState, devlogs: x }));
          });
          break;
        case "openTickets":
          fetchCollection(collection(db, "Refund Requests")).then(
            (response) => {
              let x = response.sort(
                (a, b) => parseInt(a.machine.number) - parseInt(b.machine.number)
              );
              setState((prevState) => ({ ...prevState, openTickets: x }));
            }
          );
          break;
        case "availableLocations":
          fetchCollection(collection(db, "Users")).then((response) => {
            let x = response.sort(
              (a, b) => parseInt(a.region) - parseInt(b.region)
            );
            setState((prevState) => ({ ...prevState, users: x }));
            x.forEach((user) => {
              if (user.email == currentUser.email) {
                fetchCollection(collection(db, "Machines")).then((response) => {
                  let u = response.sort(
                    (a, b) => parseInt(a.number) - parseInt(b.number)
                  );
                  fetchCollection(collection(db, "Warehouses")).then(
                    (response) => {
                      let y = response.sort(
                        (a, b) => parseInt(a.name) - parseInt(b.name)
                      );
                      let z = [];
                      user.regions.forEach((region) => {
                        u.forEach((machine) => {
                          if (machine.region.name == region) {
                            z.push(machine);
                          }
                        });
                        y.forEach((warehouse) => {
                          if (warehouse.servicing == region) {
                            z.push(warehouse);
                            fetchCollection(
                              collection(
                                db,
                                "Warehouses",
                                warehouse.id,
                                "products"
                              )
                            ).then((response) => {
                              let p = response.sort(
                                (a, b) => parseInt(a.name) - parseInt(b.name)
                              );
                              let f = state.availableProducts;
                              if (f) {
                                p.forEach((product) => {
                                  if (!f.some((e) => e.name === product.name)) {
                                    f.push(product);
                                  }
                                });
                              } else {
                                f = p;
                              }
                              setState((prevState) => ({
                                ...prevState,
                                availableProducts: f,
                              }));
                            });
                          }
                        });
                      });
                      setState((prevState) => ({
                        ...prevState,
                        availableLocations: z,
                      }));
                    }
                  );
                });
              }
            });
          });
          break;
      }
    });
  }

  async function fetchCollection(path) {
    let data = [];
    await getDocs(path).then((response) => {
      response.forEach((doc) => {
        let id = doc.id;
        data.push({ id, ...doc.data() });
      });
    });
    return Promise.all(data);
  }

  async function fetchDocument(path) {
    let data = {};
    await getDoc(path).then((response) => {
      let id = response.id;
      data = { id, ...response.data() };
    });
    return data;
  }

  async function handleSubmitWriteOff() {
    let writeOffData = {
      product: writeOffProduct.name,
      quantity: parseInt(writeOffQuantity),
      location: writeOffLocation,
      timestamp: todaysDate,
      reason: writeOffReason,
      driver: state.currentUserData.name,
    };

    let collRef = "";
    if (writeOffLocation.number == "Warehouse") {
      collRef = collection(
        db,
        "Write-Offs",
        writeOffLocation.name,
        year,
        month,
        day
      );
    } else {
      collRef = collection(
        db,
        "Write-Offs",
        writeOffLocation.number,
        year,
        month,
        day
      );
    }
    addDoc(collRef, writeOffData);
    alert("Write Off Submitted");
    handleWriteOffClose();
  }

  function matchImages(entry) {
    let x = "";
    state.products.forEach((item, index) => {
      if (entry.name == item.name)
        if (
          item.name == entry.name ||
          item.altName1 == entry.name ||
          item.altName2 == entry.name ||
          item.altName3 == entry.name
        ) {
          x = item.url;
        }
    });
    return x;
  }

  function renderWriteOffDialog() {
    let products = [];
    state.products.forEach((product) => {
      if (product.active) {
        products.push(product);
      }
    });
    return (
      <>
        <div>
          <Dialog open={writeOffOpen} onClose={handleWriteOffClose}>
            <DialogTitle id="alert-dialog-title">Add Write Off</DialogTitle>
            <DialogContent>
              <FormControl
                variant="standard"
                align="center"
                size="small"
                sx={"margin-bottom: 10px"}
              >
                <MDTypography sx={"margin-top: 15px"} variant="h6" noWrap>
                  Location
                </MDTypography>
                <Select
                  labelId="loc-select-label"
                  id="loc-select"
                  defaultValue=""
                  onChange={(e) => setWriteOffLocation(e.target.value)}
                >
                  {state.availableLocations?.map((entry, pndex) => (
                    <MenuItem value={entry}>
                      <MDTypography sx={"margin-top: 15px"} variant="h6" noWrap>
                        {entry.name}
                      </MDTypography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <FormControl
                variant="standard"
                align="center"
                size="small"
                sx={"margin-bottom: 10px"}
              >
                <MDTypography sx={"margin-top: 15px"} variant="h6" noWrap>
                  Product
                </MDTypography>
                <Select
                  labelId="prod-select-label"
                  id="prod-select"
                  defaultValue=""
                  onChange={(e) => setWriteOffProduct(e.target.value)}
                >
                  {products?.map((entry, pndex) => (
                    <MenuItem value={entry}>
                      <img style={imageStyle} src={matchImages(entry)} alt="" />
                      <MDTypography sx={"margin-top: 15px"} variant="h6" noWrap>
                        {entry.name} - {entry.batch}
                      </MDTypography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid item xs={4} xl={4}>
                <MDInput
                  label="Quantity:"
                  onChange={(e) => setWriteOffQuantity(e.target.value)}
                />
              </Grid>
              <FormControl
                variant="standard"
                align="center"
                size="small"
                sx={"margin-bottom: 10px"}
              >
                <MDTypography sx={"margin-top: 15px"} variant="h6" noWrap>
                  Reason
                </MDTypography>
                <Select
                  labelId="reason-select-label"
                  id="reason-select"
                  defaultValue=""
                  onChange={(e) => setWriteOffReason(e.target.value)}
                >
                  {state.reasons?.map((entry, pndex) => (
                    <MenuItem value={entry.text}>
                      <MDTypography sx={"margin-top: 15px"} variant="h6" noWrap>
                        {entry.text}
                      </MDTypography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleWriteOffClose}>No</MDButton>
              <MDButton onClick={handleSubmitWriteOff} autoFocus>
                Yes
              </MDButton>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }

  return (
    <>
      <DBManager_Context.Provider
        value={{
          state,
          fetchCollection,
          fetchDocument,
          updateFields,
          handleWriteOffClickOpen,
          renderWriteOffDialog,
        }}
      >
        {children}
      </DBManager_Context.Provider>
    </>
  );
}
