import React, { useState, useEffect } from "react";
import {
  AccordionSummary,
  AccordionDetails,
  Grid,
  Accordion,
  CircularProgress,
  Fab,
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "../../ui-components/MDTypography";
import MDAlert from "../../ui-components/MDAlert";
import MDBox from "../../ui-components/MDBox";
import BasicLayout from "../../layouts/BasicLayout";
import MDButton from "../../ui-components/MDButton";
import { useDBManager } from "../../contexts/DBManager_Context";
import SimpleBlogCard from "../Cards/BlogCards/SimpleBlogCard";
import { Add } from "@mui/icons-material";

export default function DriverDashboard() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const {
    updateFields,
    state,
    fetchCollection,
    handleWriteOffClickOpen,
    renderWriteOffDialog,
  } = useDBManager();
  const [preRunItems, setPreRunItems] = useState([]);
  const [IPRunItems, setIPRunItems] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [localMachines, setLocalMachines] = useState([]);

  useEffect(() => {
    updateFields([
      "users",
      "reasons",
      "machines",
      "products",
      "availableLocations",
    ]);
  }, []);

  useEffect(() => {
    let g = [];
    state.machines.forEach((machine) => {
      state.currentUserData?.regions.forEach((region) => {
        if (machine.region.name == region) {
          g.push(machine);
        }
      });
    });
    setLocalMachines(g);
  }, [state]);

  useEffect(() => {
    //Pre run data
    let x = [];
    let y = [];
    let z = [];
    fetchCollection(collection(db, "PreRun Data")).then((data) => {
      data.forEach((item) => {
        state.currentUserData?.regions.forEach((region) => {
          if (
            item.driver.name === state.currentUserData.name &&
            item.region === region
          ) {
            x.push(item);
          }
        });
      });

      //IP run data
      fetchCollection(collection(db, "Run Data")).then((data) => {
        data.forEach((item) => {
          state.currentUserData?.regions.forEach((region) => {
            if (
              item.driver.name === state.currentUserData.name &&
              item.region === region
            ) {
              y.push(item);
            }
          });
        });

        //transfer data
        fetchCollection(collection(db, "Transfer Data")).then((data) => {
          data.forEach((item) => {
            state.currentUserData?.regions.forEach((region) => {
              if (
                item.origin.servicing === region ||
                item.destination.servicing === region ||
                item.origin.region === region ||
                item.destination.region === region
              ) {
                z.push(item);
              }
            });
          });

          setPreRunItems(x);
          setIPRunItems(y);
          setTransfers(z);
        });
      });
    });
  }, []);

  async function logoutFunc(e) {
    e.preventDefault();
    await logout();
    navigate("/login");
  }

  async function handleLocateMachine(e, item) {
    e.preventDefault();
    navigate(`/driver-planogram/${item.id}`);
  }

  async function handleStartRun(e, item) {
    e.preventDefault();
    setGenerating(true);
    generateRun(item);
  }

  async function handleStartTransfer(index) {
    navigate("/transfer-driver/" + transfers[index].id);
  }

  async function handleContinueRun(e) {
    e.preventDefault();
    if (IPRunItems[0].stage == 0) {
      navigate("/run-pick/" + IPRunItems[0].id);
    } else if (IPRunItems[0].stage == 1) {
      navigate("/run-overview/" + IPRunItems[0].id);
    }
  }

  async function readPlanogram(machID) {
    let querySnapshot = await getDocs(
      collection(db, "Machines", machID.id, "planogram")
    );
    let pdata = [];
    querySnapshot.forEach((doc) => {
      pdata.push(doc.data());
    });
    pdata = pdata.sort(
      (a, b) => parseInt(b.requestDateTime) - parseInt(a.requestDateTime)
    );

    return Promise.all(pdata).then(() => {
      //Set plan data
      let x = pdata[0];
      return assemblePLabels(x.planogram);
    });
  }

  async function generateRun(obj) {
    state.currentUserData.regions.forEach((region) => {
      if (obj.region == region) {
        let colRef = collection(db, "Run Data");
        addDoc(colRef, {
          driver: obj.driver,
          author: obj.author,
          region: region,
          warehouse: obj.warehouse,
          stage: 0,
          machineCount: obj.machineCount,
          startDate: new Date(),
          startTime: new Date().toLocaleTimeString(),
          runBeginDate: obj.runBeginDate,
          runBeginTime: obj.runBeginTime,
          machines: obj.machines,
        }).then(async (response) => {
          obj?.machines.forEach((element) => {
            compareSMandPL(element).then((response2) => {
              let orderedProducts = response2.ordered;
              let pickedProducts = response2.picked;
              let stockedProducts = response2.stocked;
              let machColRef = collection(
                db,
                "Run Data",
                response.id,
                "machines"
              );
              addDoc(machColRef, {
                machineID: element,
                orderedProducts: orderedProducts,
                filled: false,
                pickedProducts: pickedProducts,
                stockedProducts: stockedProducts,
              });
            });
          });
          await new Promise((r) => setTimeout(r, 5000));
          deleteDoc(doc(db, "PreRun Data", obj.id));
          navigate("/run-pick/" + response.id);
        });
      }
    });
  }

  async function compareSMandPL(element) {
    return readStockMap(element).then((SMresponse) => {
      return readPlanogram(element).then((Presponse) => {
        return findNeededProducts(SMresponse, Presponse);
      });
    });
  }

  async function findNeededProducts(a, b) {
    let x = { products: [], quantities: [] };
    a.products.forEach((item, index) => {
      b.products.forEach((entry, kndex) => {
        if (a.products.includes(entry) && b.maxQuantities[kndex] > 0) {
          if (item == entry) {
            if (b.maxQuantities[kndex] - a.mapping[index] > 0) {
              //The if statement that partitions product by batch
              x.products.push(entry);
              x.quantities.push(b.maxQuantities[kndex] - a.mapping[index]);
            }
          }
        } else {
          if (!x.products.includes(entry) && b.maxQuantities[kndex] > 0) {
            x.products.push(entry);
            x.quantities.push(parseInt(b.maxQuantities[kndex]));
          }
        }
      });
    });
    let z = { ordered: [], picked: [], stocked: [] };
    x.products.forEach((item, index) => {
      if (item != "Empty") {
        z.ordered.push({
          name: item,
          quantity: x.quantities[index],
        });
        z.picked.push({
          name: item,
          quantity: 0,
        });
        z.stocked.push({
          name: item,
          quantity: 0,
        });
      }
    });
    return z;
  }

  async function readStockMap(machID) {
    let querySnapshot = await getDocs(
      collection(db, "Machines", machID.id, "plans")
    );
    let pdata = [];
    querySnapshot.forEach((doc) => {
      pdata.push(doc.data());
    });
    pdata = pdata.sort(
      (a, b) => parseInt(b.requestDateTime) - parseInt(a.requestDateTime)
    );

    return Promise.all(pdata).then(() => {
      //Set plan data
      let x = pdata[0];
      return assembleSMLabels(x);
    });
  }

  function assembleSMLabels(p) {
    let h = {
      products: [],
      mapping: [],
      totalMapping: 0,
    };

    p.shelves?.forEach((item, index) => {
      if (!h.products.includes(item.productCode)) {
        h.products.push(item.productCode);
        h.mapping.push(item.currentStock);
        h.totalMapping = h.totalMapping + item.currentStock;
      } else {
        let x = h.mapping[h.products.indexOf(item.productCode)];
        x = x + item.currentStock;
        h.mapping[h.products.indexOf(item.productCode)] = x;

        h.totalMapping = h.totalMapping + item.currentStock;
      }
    });

    h.products.forEach((item, index) => {
      state.products.forEach((entry, kndex) => {
        if (
          item == entry.bud ||
          item == entry.altBudCode1 ||
          item == entry.altBudCode2 ||
          item == entry.altBudCode3
        ) {
          h.products[index] = entry.name;
        }
      });
    });
    return h;
  }

  function assemblePLabels(p) {
    let h = {
      products: [],
      maxQuantities: [],
      totalMax: 0,
    };

    p.shelves?.forEach((item, index) => {
      if (!h.products.includes(item.productCode)) {
        h.products.push(item.productCode);
        h.maxQuantities.push(item.maxStock);
        h.totalMax = h.totalMax + item.maxStock;
      } else {
        let y = h.maxQuantities[h.products.indexOf(item.productCode)];
        y = y + item.maxStock;
        h.maxQuantities[h.products.indexOf(item.productCode)] = y;

        h.totalMax = h.totalMax + item.maxStock;
      }
    });

    h.products?.forEach((item, index) => {
      state.products.forEach((entry, kndex) => {
        if (
          item == entry.bud ||
          item == entry.altBudCode1 ||
          item == entry.altBudCode2 ||
          item == entry.altBudCode3
        ) {
          h.products[index] = entry.name;
        }
      });
    });
    return h;
  }

  function renderTransfers() {
    if (transfers.length != 0) {
      return (
        <>
          <Grid container justifyContent="center">
            {transfers?.map((item, index) => (
              <MDButton
                justifyContent="center"
                variant="text"
                onClick={(e) => handleStartTransfer(index)}
              >
                <MDAlert color="error">
                  <MDTypography
                    justifycontent="center"
                    variant="h6"
                    color="white"
                  >
                    {item.origin.name} to {item.destination.name}
                  </MDTypography>
                </MDAlert>
              </MDButton>
            ))}
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid container justifyContent="center">
            <MDButton justifyContent="center" variant="text">
              <MDAlert color="info">
                <MDTypography
                  justifycontent="center"
                  variant="h6"
                  size="small"
                  color="white"
                >
                  No transfers available
                </MDTypography>
              </MDAlert>
            </MDButton>
          </Grid>
        </>
      );
    }
  }

  function renderRuns() {
    if (generating) {
      return (
        <>
          <Grid container justifyContent="center">
            <CircularProgress color="warning" />
          </Grid>
          <Grid container justifyContent="center">
            <MDTypography variant="h4" noWrap>
              Please wait
            </MDTypography>
          </Grid>
          <Grid container justifyContent="center">
            <MDTypography variant="h6" noWrap>
              Your run is being generated
            </MDTypography>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid container justifyContent="center" spacing={4}>
            {IPRunItems.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleBlogCard
                  image="https://firebasestorage.googleapis.com/v0/b/erg-app-dev.appspot.com/o/images%2Ferg_logo.png?alt=media&token=5a0aa679-2751-4dfc-86e3-90ee9afbcbe2"
                  name={item.name}
                  title={item.runBeginDate + " - " + item.runBeginTime}
                  machines={item.machines}
                  action={{
                    type: "internal",
                    route: "/somewhere",
                    color: "warning",
                    label: "Continue Run",
                  }}
                  width="201px"
                  height="222px"
                  customFunction={handleContinueRun}
                  runID={item}
                />
              </Grid>
            ))}
            {preRunItems.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <SimpleBlogCard
                  image="https://firebasestorage.googleapis.com/v0/b/erg-app-dev.appspot.com/o/images%2Ferg_logo.png?alt=media&token=5a0aa679-2751-4dfc-86e3-90ee9afbcbe2"
                  name={item.name}
                  title={item.runBeginDate + " - " + item.runBeginTime}
                  machines={item.machines}
                  action={{
                    type: "internal",
                    route: "/somewhere",
                    color: "error",
                    label: "Start Run",
                  }}
                  width="201px"
                  height="222px"
                  customFunction={handleStartRun}
                  runID={item}
                />
              </Grid>
            ))}
          </Grid>
        </>
      );
    }
  }

  function renderMachines() {
    if (localMachines?.length != 0) {
      return (
        <>
          <Grid container justifyContent="center">
            {localMachines?.map((item, index) => (
              <MDButton
                justifyContent="center"
                variant="text"
                onClick={(e) => handleLocateMachine(e, item)}
              >
                <MDAlert color="error">
                  <MDTypography
                    justifycontent="center"
                    variant="h6"
                    color="white"
                  >
                    {item.number} - {item.name}
                  </MDTypography>
                </MDAlert>
              </MDButton>
            ))}
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid container justifyContent="center">
            <MDButton justifyContent="center" variant="text">
              <MDAlert color="info">
                <MDTypography
                  justifycontent="center"
                  variant="h6"
                  size="small"
                  color="white"
                >
                  No machines available
                </MDTypography>
              </MDAlert>
            </MDButton>
          </Grid>
        </>
      );
    }
  }

  return (
    <>
      <BasicLayout>
        <MDBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            ERG Vending Suite
          </MDTypography>
          <MDTypography
            variant="caption"
            fontWeight="medium"
            color="white"
            mt={1}
          >
            {state.version}
          </MDTypography>
        </MDBox>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <MDTypography>Run Sheets</MDTypography>
          </AccordionSummary>
          <AccordionDetails>{renderRuns()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <MDTypography>Transfers</MDTypography>
          </AccordionSummary>
          <AccordionDetails>{renderTransfers()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <MDTypography>View Machines</MDTypography>
          </AccordionSummary>
          <AccordionDetails>{renderMachines()}</AccordionDetails>
        </Accordion>
        <Grid container justifyContent="center">
          <MDButton
            justifyContent="center"
            variant="text"
            size="small"
            onClick={(e) => logoutFunc(e)}
          >
            <MDAlert color="dark">
              <MDTypography justifycontent="center" variant="h6" color="white">
                Log Out
              </MDTypography>
            </MDAlert>
          </MDButton>
        </Grid>
        <Fab
          color="info"
          aria-label="add"
          sx={{ position: "absolute", bottom: 40, left: 20 }}
          onClick={handleWriteOffClickOpen}
        >
          <Add />
        </Fab>
        {renderWriteOffDialog()}
      </BasicLayout>
    </>
  );
}
