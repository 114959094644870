import React, { useState, useEffect } from 'react'
import { useAuth } from '../contexts/AuthContext'
import {useNavigate} from "react-router-dom"

export default function HandleLogout() {
    const [error, setError] = useState("")
    const {currentUser, logout} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        logMeOut()
    }, [])

    async function logMeOut() {
        setError("")
        try {
            await logout()
            navigate('/login')
        } catch {
            setError('Failed to log out')
        }
    }

  return (
    <div></div>
  )
}
