import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { BrowserRouter } from "react-router-dom";
// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "./contexts";

ReactDOM.render(
    <MaterialUIControllerProvider>
      <BrowserRouter>
          <App/>
      </BrowserRouter>
    </MaterialUIControllerProvider>,
  document.getElementById('root')
);