import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  Checkbox,
  FormControlLabel,
  InputLabel,
  FormControl,
  Divider,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import TimePicker from "react-time-picker";
import { collection, addDoc } from "firebase/firestore";
import { useDBManager } from "../../contexts/DBManager_Context";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import TimezoneSelect from "react-timezone-select";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import DataTable_Generic from "../Tables/DataTable_Generic";
import MDButton from "../../ui-components/MDButton";
import scheduledRunsData from "../data/scheduledRunData";
import HandleLogout from "../HandleLogout";

export default function Run_Schedule() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { updateFields } = useDBManager();
  const { state, fetchCollection } = useDBManager();

  const [scheduledRuns, setScheduledRuns] = useState([]);

  const [newRun_Driver, setNewRun_Driver] = useState({});
  const [newRun_Machines, setNewRun_Machines] = useState([]);
  const [newRun_Warehouse, setNewRun_Warehouse] = useState({});
  const [newRun_Region, setNewRun_Region] = useState({});
  const [newRun_Day, setNewRun_Day] = useState("");

  const [availableMachines, setAvailableMachines] = useState([]);
  const [availableWarehouses, setAvailableWarehouses] = useState([]);
  const [availableDrivers, setAvailableDrivers] = useState([]);

  const [contextDependent, setContextDependent] = useState(false);

  const [startTime, setStartTime] = useState("10:00");

  const [timeValue, onChangeTime] = useState("10:00");
  const [timezone, setTimezone] = useState("America/New_York");

  const [refillThreshold, setRefillThreshold] = useState([]);

  const availableDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  let { columns, rows } = scheduledRunsData(scheduledRuns, openRunPage);

  const textboxStyle = {
    "margin-top": "10px",
    "margin-left": "30px",
    "margin-right": "30px",
  };

  useEffect(() => {
    updateFields(["regions", "warehouses", "machines", "users"]);
    fetchCollection(collection(db, "Scheduled Runs")).then((data) => {
      setScheduledRuns(data);
    });
  }, []);

  useEffect(() => {
    if (newRun_Region) {
      let machines = state.machines.filter(
        (machine) => machine.region.name == newRun_Region.name
      );
      setAvailableMachines(machines);

      let warehouses = state.warehouses.filter(
        (warehouses) => warehouses.servicing == newRun_Region.name
      );
      setAvailableWarehouses(warehouses);

      let drivers = state.users.filter((drivers) =>
        drivers.regions.includes(newRun_Region.name)
      );
      setAvailableDrivers(drivers);
    }
  }, [newRun_Region]);

  async function onCreateRun() {
    //Create new run
    const collRef = collection(db, "Scheduled Runs");
    await addDoc(collRef, {
      author: state.currentUserData,
      driver: newRun_Driver,
      machines: newRun_Machines,
      warehouse: newRun_Warehouse,
      region: newRun_Region.name,
      day: newRun_Day,
      time: timeValue,
      timezone: timezone,
      machineCount: newRun_Machines.length,
      startTime: startTime,
      contextDependent: contextDependent,
      refillThreshold: refillThreshold,
    });
    setNewRun_Driver("");
    setNewRun_Machines([]);
    setNewRun_Region({});
    setNewRun_Warehouse({});
    setNewRun_Day("");
    onChangeTime("10:00");
    setStartTime("10:00");
    setContextDependent(false);
    setRefillThreshold([]);
    alert("Recurring Run Scheduled!");
    fetchCollection(collection(db, "Scheduled Runs")).then((data) => {
      setScheduledRuns(data);
    });
  }

  const handleSetChecked = (event, obj) => {
    let z = newRun_Machines;
    if (!newRun_Machines.includes(obj)) {
      z.push(obj);
    } else {
      z.splice(newRun_Machines.indexOf(obj), 1);
    }
    setNewRun_Machines((prev) => z);
  };

  const handleSetThreshold = (event, obj) => {
    let z = refillThreshold;
    if (refillThreshold.length == 0) {
      z.push({ threshold: event.target.value, machine: obj });
    } else {
      let exists = false;
      refillThreshold.forEach((element, index) => {
        if (element.machine == obj) {
          z.splice(index, 1, { threshold: event.target.value, machine: obj });
          exists = true;
        }
      });
      if (!exists) {
        z.push({ threshold: event.target.value, machine: obj });
      }
    }
    setRefillThreshold((prev) => z);
  };

  function openRunPage(docID) {
    navigate("/scheduled-runs/" + docID);
  }

  const changeRegion = (e) => {
    setNewRun_Machines([]);
    setNewRun_Region(e);
  };

  const handleSelectContext = (e) => {
    setContextDependent((prev) => e.target.checked);
  };

  function renderMachines() {
    if (!contextDependent) {
      return (
        <FormControl
          variant="standard"
          sx={{
            m: 1,
            minWidth: 120,
            "margin-left": "30px",
            "margin-right": "30px",
          }}
        >
          {availableMachines.map((item, index) => (
            <FormControlLabel
              label={item.number + " - " + item.name}
              control={
                <Checkbox
                  onChange={(e) =>
                    handleSetChecked(e, availableMachines[index])
                  }
                />
              }
            />
          ))}
        </FormControl>
      );
    }
  }

  function renderContextMachines() {
    if (contextDependent) {
      return (
        <>
          <FormControl>
            {availableMachines.map((item, index) => (
              <TextField
                sx={{
                  "margin-top": "20px",
                  "margin-left": "15px",
                  "margin-right": "15px",
                }}
                size="small"
                id="newNumber"
                variant="outlined"
                placeholder={`${item.number} - ${item.name} - Refill Threshold`}
                onChange={(e) => {
                  handleSetThreshold(e, availableMachines[index]);
                }}
              ></TextField>
            ))}
          </FormControl>
        </>
      );
    }
  }

  return (
    <>
      {currentUser ? (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox py={3}>
            <Grid item xs={12} md={6} lg={6}>
              <Card>
                <MDBox
                  mx={3}
                  mt={0}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Schedule a Recurring Run
                  </MDTypography>
                </MDBox>
                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                >
                  <InputLabel id="product-label">Region</InputLabel>
                  <Select
                    labelId="region-label"
                    id="region-label"
                    label="Region"
                    value={newRun_Region.name}
                    onChange={(e) =>
                      changeRegion(state.regions[e.target.value])
                    }
                  >
                    {state.regions.map((item, index) => (
                      <MenuItem value={index}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {renderMachines()}
                <Divider />
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ "margin-left": "30px" }}
                      onChange={(e) => handleSelectContext(e)}
                    />
                  }
                  label="Context-Dependent Machines in Run?"
                />
                {renderContextMachines()}
                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                >
                  <InputLabel id="warehouse-label">Warehouse</InputLabel>
                  <Select
                    labelId="warehouse-label"
                    id="warehouse-label"
                    label="Warehouse"
                    value={newRun_Warehouse.name}
                    onChange={(e) =>
                      setNewRun_Warehouse(availableWarehouses[e.target.value])
                    }
                  >
                    {availableWarehouses.map((item, index) => (
                      <MenuItem value={index}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                >
                  <InputLabel id="driver-label">Driver</InputLabel>
                  <Select
                    labelId="driver-label"
                    id="driver-label"
                    label="Driver"
                    value={newRun_Driver.name}
                    onChange={(e) =>
                      setNewRun_Driver(availableDrivers[e.target.value])
                    }
                  >
                    {availableDrivers.map((item, index) => (
                      <MenuItem value={index}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                >
                  <InputLabel id="day-label1">Day</InputLabel>
                  <Select
                    labelId="day-label"
                    id="day-label"
                    label="Day"
                    onChange={(e) => setNewRun_Day(e.target.value)}
                  >
                    {availableDays.map((item, index) => (
                      <MenuItem value={index}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                >
                  <MDTypography variant="caption">
                    Run Start Time - Only select 15 minute increments
                  </MDTypography>
                  <TimePicker onChange={setStartTime} value={startTime} />
                </FormControl>

                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                >
                  <MDTypography variant="caption">
                    Time Run Is Created (Must Be Same Day But Before Start Time)
                    - Only select 15 minute increments
                  </MDTypography>
                  <TimePicker onChange={onChangeTime} value={timeValue} />
                </FormControl>

                <FormControl
                  variant="standard"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                >
                  <MDTypography variant="caption">Timezone</MDTypography>
                  <TimezoneSelect value={timezone} onChange={setTimezone} />
                </FormControl>

                <MDButton
                  align="center"
                  sx={textboxStyle}
                  color="success"
                  variant="contained"
                  onClick={onCreateRun}
                >
                  Create Scheduled Run
                </MDButton>
                <MDBox
                  mx={2}
                  mt={0}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="white"
                  borderRadius="lg"
                ></MDBox>
              </Card>
            </Grid>
          </MDBox>

          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <MDBox
                mx={3}
                mt={0}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Scheduled Runs
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable_Generic
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                />
              </MDBox>
            </Card>
          </Grid>
          <Footer />
        </DashboardLayout>
      ) : (
        <HandleLogout />
      )}
    </>
  );
}
