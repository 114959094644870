// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar";
import MDBadge from "../../ui-components/MDBadge";
import MDButton from "../../ui-components/MDButton";
import { useNavigate } from "react-router-dom";

export default function data(data, products) {
  const imageStyle = { width: '50px', height: '50px'}

  function matchImages(entry) {
    let x = ""
    products.forEach((item, index) => {
      if (item.name == entry || item.altName1 == entry || item.altName2 == entry || item.altName3 == entry) {
        x = item.url
      }
    })
    return x
  }

  const Product = ({ number, name, url }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <img style={imageStyle} src={url} />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {number}
        </MDTypography>
        <MDTypography variant="caption">{name}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Reason = ({ region }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {region}
      </MDTypography>
    </MDBox>
  );

  let rows = [];
  data?.map((mach, index) => {
    rows.push({
      product: (
        <Product number={mach.product} name={"Batch - " + mach.batch} url={matchImages(mach.product)}/>
      ),
      reason: <Reason region={mach.reason}/>,
      quantity: <Reason region={mach.quantity} />,
      location: <Reason region={mach.loc} />,
      dateTime: <Reason region={mach.timestamp} />,
      driver: <Reason region={mach.driver.name} />,
    });
  });

  return {
    columns: [
      { Header: "Product", accessor: "product", width: "45%", align: "left" },
      { Header: "Reason", accessor: "reason", width: "45%", align: "left" },
      { Header: "Quantity", accessor: "quantity", width: "45%", align: "left" },
      { Header: "Location", accessor: "location", width: "45%", align: "left" },
      { Header: "Driver", accessor: "driver", width: "45%", align: "left" },
      { Header: "Timestamp", accessor: "dateTime", width: "45%", align: "left" },
    ],
    rows,
  };
}
