import React, { useState, useEffect } from "react";
import { useDBManager } from "../../contexts/DBManager_Context";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import Grid from "@mui/material/Grid";
import { Chart, registerables } from "chart.js";
import MDBox from "../../ui-components/MDBox";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import ComplexStatisticsCard from "../Cards/StatisticsCards/ComplexStatisticsCard";
import TimelineList from "../Timeline/TimelineList";
import TimelineItem from "../Timeline/TimelineItem";
import generalSaleList from "../data/dashboard_generalList";
import DataTable_Generic from "../Tables/DataTable_Generic";
import { Receipt } from "@mui/icons-material";

function Admin_Dashboard(user) {
  Chart.register(...registerables);

  let date = new Date();
  const [month, setMonth] = useState(String(date.getMonth() + 1));
  const [day, setDay] = useState(String(date.getDate()));
  const [year] = useState(String(date.getFullYear()));

  let y_date = new Date(new Date().setDate(new Date().getDate() - 1));
  const [y_month, setYMonth] = useState(String(y_date.getMonth() + 1));
  const [y_day, setYDay] = useState(String(y_date.getDate()));
  const [y_year] = useState(String(y_date.getFullYear()));

  const [salesToday, setSalesToday] = useState(0);
  const [salesYesterday, setSalesYesterday] = useState(0);

  const { updateFields } = useDBManager();
  const { state } = useDBManager();

  const [machines_sales_today, setMachinesSalesToday] = useState([]);

  let { columns, rows } = generalSaleList(machines_sales_today);

  useEffect(() => {
    let t_month = "";
    let t_day = "";
    if (parseInt(month) < 10) {
      t_month = "0" + String(month);
      setMonth(t_month);
    }
    if (parseInt(day) < 10) {
      t_day = "0" + String(day);
      setDay(t_day);
    }
    let s_month = "";
    let s_day = "";
    if (parseInt(y_month) < 10) {
      s_month = "0" + String(y_month);
      setYMonth(s_month);
    }
    if (parseInt(y_day) < 10) {
      s_day = "0" + String(y_day);
      setYDay(s_day);
    }

    updateFields(["devlogs", "machines", "products"]);
  }, []);

  useEffect(() => {
    getTodaysSales();
  }, [state]);

  async function getTodaysSales() {
    let salesData = [];
    const localRequests = state.machines?.map(async (machine, index) => {
      let localData = [];
      return getDocs(
        collection(db, "Sales", machine.number, year, month, day)
      ).then((response) => {
        response.forEach((doc) => {
          localData.push(doc.data());
        });
        if (localData.length > 0) {
          salesData.push(localData);
        } else {
          salesData.push([]);
        }
      });
    });
    return Promise.all(localRequests).then(() => {
      let allMachines = [];
      salesData.forEach((sales, index) => {
        if (sales.length > 0) {
          let x = sales;
          let sortedSales = x.sort(function (a, b) {
            return b.dateTime.localeCompare(a.dateTime);
          });
          allMachines.push({
            machine: sales[0].machineId + " - " + sales[0].machineName,
            status: state.machines[index].active,
            sales: sales.length,
            yesterdaySales: [],
            yesterdayRawSales: [],
            rawSales: sortedSales,
          });
        } else {
          allMachines.push({
            machine:
              state.machines[index].number + " - " + state.machines[index].name,
            status: state.machines[index].active,
            sales: 0,
            rawSales: [],
            yesterdaySales: 0,
            yesterdayRawSales: [],
          });
        }
      });
      getYesterdaysSales(allMachines);
    });
  }

  async function getYesterdaysSales(allMachines) {
    let salesData = [];
    const localRequests = state.machines?.map(async (machine, index) => {
      let localData = [];
      return getDocs(
        collection(db, "Sales", machine.number, y_year, y_month, y_day)
      ).then((response) => {
        response.forEach((doc) => {
          localData.push(doc.data());
        });
        salesData.push(localData);
      });
    });
    return Promise.all(localRequests).then(() => {
      let localMachines = allMachines;
      salesData.forEach((sales, index) => {
        if (sales.length > 0) {
          localMachines[index].yesterdaySales = sales.length;
          let x = sales;
          let sortedSales = x.sort(function (a, b) {
            return b.dateTime.localeCompare(a.dateTime);
          });
          localMachines[index].yesterdayRawSales = sortedSales;
        } else {
          localMachines[index].yesterdaySales = 0;
        }
      });
      calculateTotalSales(localMachines);
      setMachinesSalesToday(localMachines);
    });
  }

  function calculateTotalSales(localMachines) {
    let count = 0;
    localMachines.forEach((machine, index) => {
      count = count + machine.sales;
    });
    setSalesToday(count);
    count = 0;
    localMachines.forEach((machine, index) => {
      count = count + machine.yesterdaySales;
    });
    setSalesYesterday(count);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon={<Receipt />}
                title="Total Sales Today"
                count={salesToday}
                percentage={{
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon={<Receipt />}
                title="Total Sales Yesterday"
                count={salesYesterday}
                percentage={{
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <MDBox mb={1.5}>
              <DataTable_Generic
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={true}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <TimelineList title="Development Timeline">
              {state.devlogs?.map((item, index) => (
                <TimelineItem
                  color="info"
                  title={item.title}
                  dateTime={item.timestamp}
                  description={item.text}
                />
              ))}
            </TimelineList>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              {/* <ComplexStatisticsCard
                color="success"
                icon={<CreditScore />}
                title="Revenue Today"
                count={calculateRevenue(todaysRawSales)}
                percentage={{
                  color: calculateReturnOnRevenue(
                    todaysRawSales,
                    yesterdaysRawSales,
                    "color"
                  ),
                  // amount: calculateReturnOnRevenue(todaysRawSales, yesterdaysRawSales, "percentage"),
                  label: "Just Updated",
                }}
              /> */}
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={12}>
              <MDBox mb={3}>
                {/* <ReportsBarChart
                  color="info"
                  title="Top Selling Machines Today"
                  description=""
                  date="Just updated"
                  chart={todaysSalesData}
                /> */}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                {/* <PieChart
                  icon={<Cake />}
                  title="Sales Today by Product"
                  description="Analytics Insights"
                  chart={todaysProductData}
                /> */}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                {/* <PieChart
                  icon={<Cake />}
                  title="Sales Yesterday by Product"
                  description="Analytics Insights"
                  chart={yesterdaysProductData}
                /> */}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}></Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Admin_Dashboard;
