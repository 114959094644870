// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDBadge from "../../ui-components/MDBadge";
import MDButton from "../../ui-components/MDButton";

export default function data(machine, products) {
  const imageStyle = { width: "50px", height: "50px" };

  const Name = ({ name, url }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <img style={imageStyle} src={url} />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Mapping = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="center">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  function matchImages(entry) {
    let x = "";
    products.forEach((item, index) => {
      if (entry == item.name)
        if (
          item.name == entry ||
          item.altName1 == entry ||
          item.altName2 == entry ||
          item.altName3 == entry
        ) {
          x = item.url;
        }
    });
    return x;
  }

  function matchNames(entry) {
    let x = "";
    products.forEach((item, index) => {
      if (item.bud == entry) {
        x = item.name;
      } else if (item.altBudCode1 == entry) {
        x = item.name;
      } else if (item.altBudCode2 == entry) {
        x = item.name;
      } else if (item.altBudCode3 == entry) {
        x = item.name;
      }
    });
    if (x == "") {
      return entry;
    } else {
      return x;
    }
  }

  let rows = [];
  machine.products?.map((item, index) => {
    rows.push({
      name: (
        <Name name={matchNames(item)} url={matchImages(matchNames(item))} />
      ),
      mapping: <Mapping title={machine.mapping[index]} description="" />,
      maxQ: <Mapping title={machine.maxQuantities[index]} description="" />,
      needed: (
        <Mapping
          title={
            parseInt(machine.maxQuantities[index]) -
            parseInt(machine.mapping[index])
          }
          description=""
        />
      ),
    });
  });
  let totalMapping = 0;
  let totalMax = 0;
  let totalNeeded = 0;
  machine.mapping.forEach((item, index) => {
    totalMapping += parseInt(item);
  });
  machine.maxQuantities.forEach((item, index) => {
    totalMax += parseInt(item);
  });
  machine.maxQuantities.forEach((item, index) => {
    let u = parseInt(item) - parseInt(machine.mapping[index]);
    u < 0 ? (totalNeeded += 0) : (totalNeeded += u);
  });

  rows.push({
    name: <Mapping title={"Total"} description="" />,
    mapping: <Mapping title={totalMapping} description="" />,
    maxQ: <Mapping title={totalMax} description="" />,
    needed: <Mapping title={totalNeeded} description="" />,
  });

  return {
    columns: [
      { Header: "Product", accessor: "name", width: "45%", align: "left" },
      { Header: "Mapping", accessor: "mapping", align: "left" },
      { Header: "Max Quantity", accessor: "maxQ", align: "left" },
      { Header: "Needed", accessor: "needed", align: "left" },
    ],
    rows,
  };
}
