import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { useParams } from "react-router-dom";
import { collection, updateDoc, doc } from "firebase/firestore";
import {
  Grid,
  Container,
  Card,
  MenuItem,
  Select,
  FormControl,
  TextField,
  InputLabel,
} from "@mui/material";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDButton from "../../ui-components/MDButton";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import { useDBManager } from "../../contexts/DBManager_Context";
import { useNavigate } from "react-router-dom";
import DataTable_Generic from "../Tables/DataTable_Generic";
import machineDataTable from "../data/machineProductData";
import planogramDataTable from "../data/machinePlanogramData";
import DataTable_Warehouses from "../Tables/DataTable_Warehouses";
import TimezoneSelect from "react-timezone-select";
import TimePicker from "react-time-picker";

export default function Machine_View() {
  const { docID } = useParams();
  const { updateFields } = useDBManager();
  const { state, fetchDocument, fetchCollection } = useDBManager();
  const navigate = useNavigate();
  const [plan, setPlan] = useState([]);
  const [planLabels, setPlanLabels] = useState([]);
  const [machine, setMachine] = useState({});
  const [planogram, setPlanogram] = useState({});
  const [planogramDocID, setPlanogramDocID] = useState("");
  const [planogramLabels, setPlanogramLabels] = useState([]);

  const [newName, setNewName] = useState("");
  const [newNumber, setNewNumber] = useState("");
  const [newRegion, setNewRegion] = useState("");
  const [newActive, setNewActive] = useState(false);
  const [newMerchantNum, setNewMerchantNum] = useState("");
  const [newEcrID, setNewEcrID] = useState("");
  const [newPTID, setNewPTID] = useState("");
  const [timeOValue, setTimeOValue] = useState("10:00");
  const [timeCValue, setTimeCValue] = useState("20:00");
  const [timezone, setTimezone] = useState("America/New_York");
  const [availableProducts, setAvailableProducts] = useState([]);

  let { columns, rows } = machineDataTable(planLabels, state.products);
  let { w_columns, w_rows } = planogramDataTable(
    planogramLabels,
    state.products
  );

  const imageStyle = { width: "50px", height: "50px" };

  useEffect(() => {
    updateFields(["products", "regions"]);
    //Get machine
    fetchDocument(doc(db, "Machines", docID)).then((data) => {
      setMachine(data);
      setNewName(data.name);
      setNewNumber(data.number);
      setNewRegion(data.region);
      setNewActive(data.active);
      setNewMerchantNum(data.merchantNum);
      setNewEcrID(data.ecrID);
      setNewPTID(data.ptid);
      setTimeOValue(data.openTime);
      setTimeCValue(data.closeTime);
    });
  }, []);

  useEffect(() => {
    let products = [];
    state.products.forEach((item, index) => {
      if (item.active) {
        products.push(item);
      }
    });
    setAvailableProducts(products);
    //Get stock map
    fetchCollection(collection(db, "Machines", docID, "plans")).then((data) => {
      let pdata = data.sort(
        (a, b) => parseInt(b.requestDateTime) - parseInt(a.requestDateTime)
      );
      let x = pdata[0];
      assembleSMLabels(x);
      setPlan(x);
    });

    getPlanogram();
  }, [state]);

  function getPlanogram() {
    //Get planogram
    fetchCollection(collection(db, "Machines", docID, "planogram")).then(
      (data) => {
        let x = data[0].planogram;
        assemblePLabels(x);
        setPlanogram(x);
        setPlanogramDocID(data[0].id);
      }
    );
  }

  function matchBudWithProduct(bud) {
    let x = "";
    state.products.forEach((item, index) => {
      if (
        item.bud == bud ||
        item.altBudCode1 == bud ||
        item.altBudCode2 == bud ||
        item.altBudCode3 == bud
      ) {
        x = item.url;
      }
    });
    return x;
  }

  function assembleSMLabels(p) {
    let h = {
      products: [],
      mapping: [],
      maxQuantities: [],
      totalMapping: 0,
      totalMax: 0,
    };

    p.shelves?.forEach((item, index) => {
      if (!h.products.includes(item.productCode)) {
        h.products.push(item.productCode);
        h.mapping.push(item.currentStock);
        h.maxQuantities.push(item.maxStock);
        h.totalMapping = h.totalMapping + item.currentStock;
        h.totalMax = h.totalMax + item.maxStock;
      } else {
        let x = h.mapping[h.products.indexOf(item.productCode)];
        x = x + item.currentStock;
        h.mapping[h.products.indexOf(item.productCode)] = x;

        let y = h.maxQuantities[h.products.indexOf(item.productCode)];
        y = y + item.maxStock;
        h.maxQuantities[h.products.indexOf(item.productCode)] = y;

        h.totalMapping = h.totalMapping + item.currentStock;
        h.totalMax = h.totalMax + item.maxStock;
      }
    });

    h.products.forEach((item, index) => {
      state.products.forEach((entry, kndex) => {
        if (
          item == entry.bud ||
          item == entry.altBudCode1 ||
          item == entry.altBudCode2 ||
          item == entry.altBudCode3
        ) {
          h.products[index] = entry.name;
        }
      });
    });
    setPlanLabels(h);
  }

  function assemblePLabels(p) {
    let h = {
      products: [],
      maxQuantities: [],
      totalMax: 0,
    };

    p.shelves?.forEach((item, index) => {
      if (!h.products.includes(item.productCode)) {
        h.products.push(item.productCode);
        h.maxQuantities.push(item.maxStock);
        h.totalMax = h.totalMax + item.maxStock;
      } else {
        let y = h.maxQuantities[h.products.indexOf(item.productCode)];
        y = y + item.maxStock;
        h.maxQuantities[h.products.indexOf(item.productCode)] = y;

        h.totalMax = h.totalMax + item.maxStock;
      }
    });

    h.products?.forEach((item, index) => {
      state.products.forEach((entry, kndex) => {
        if (item == entry.bud) {
          h.products[index] = entry.name;
        }
      });
    });
    setPlanogramLabels(h);
  }

  const callbackFunc_updateMachine = async (callback) => {
    const machineDocRef = doc(db, "Machines", docID);
    updateDoc(machineDocRef, {
      name: newName,
      number: newNumber,
      region: newRegion,
      active: newActive,
      merchantNum: newMerchantNum,
      ecrID: newEcrID,
      ptid: newPTID,
      openTime: timeOValue,
      closeTime: timeCValue,
      timezone: timezone,
    }).then(async (response) => {
      alert("Machine Updated");
      //Get machine
      fetchDocument(doc(db, "Machines", docID)).then((data) => {
        setMachine(data);
        setNewName(data.name ? data.name : "");
        setNewNumber(data.number ? data.number : "");
        setNewRegion(data.region ? data.region : "");
        setNewActive(data.active ? data.active : false);
        setNewMerchantNum(data.merchantNum ? data.merchantNum : "");
        setNewEcrID(data.ecrID ? data.ecrID : "");
        setNewPTID(data.ptid ? data.ptid : "");
        setTimeOValue(data.openTime ? data.openTime : "");
        setTimeCValue(data.closeTime ? data.closeTime : "");
        setTimezone(data.timezone ? data.timezone : "");
      });
      callback();
    });
  };

  function openRAPage() {
    navigate("/machines/replenishmentanalysis/" + docID);
  }

  const callbackFunc_updateMaxStock = async (event, index, callback) => {
    let x = planogram;
    x.shelves[index].maxStock = event.target.value;
    setPlanogram(x);
    let docRef = doc(db, "Machines", docID, "planogram", planogramDocID);
    updateDoc(docRef, { planogram: x }).then(async (response) => {
      getPlanogram();
      callback();
    });
  };

  const callbackFunc_updateProduct = async (event, index, callback) => {
    let x = planogram;
    x.shelves[index].productCode = event.target.value;
    setPlanogram(x);
    let docRef = doc(db, "Machines", docID, "planogram", planogramDocID);
    updateDoc(docRef, { planogram: x }).then(async (response) => {
      getPlanogram();
      callback();
    });
  };

  function buildSMLane(object) {
    let url = matchBudWithProduct(object.productCode);
    return (
      <>
        <Grid item xs={1} xl={1}>
          <img style={imageStyle} src={url} alt="" />
          <MDTypography variant="h6" sx={"margin-left: 5px"}>
            {object.currentStock + " / " + object.maxStock}
          </MDTypography>
        </Grid>
      </>
    );
  }

  function buildPLane(object, index) {
    return (
      <>
        <Grid item xs={1} xl={1}>
          <FormControl
            variant="standard"
            align="center"
            size="small"
            sx={"margin-bottom: 10px"}
          >
            <Select
              labelId="simple-select-label"
              id="simple-select"
              defaultValue={object.productCode}
              onChange={(e) => callbackFunc_updateProduct(e, index)}
            >
              {availableProducts.map((entry, pndex) => (
                <MenuItem value={entry.bud}>
                  <img style={imageStyle} src={entry.url} alt="" />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            align="center"
            sx={"margin-left: 15px"}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={object.maxStock}
              onChange={(e) => callbackFunc_updateMaxStock(e, index)}
            >
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </>
    );
  }

  function buildSMGrid() {
    let planIndex = -1;
    let elements = [];
    plan.shelves?.forEach((item, index) => {
      elements.push(buildSMLane(item));
    });
    elements.reverse();
    return (
      <>
        <Grid container spacing={3}>
          {elements.map((item, index) => {
            if (
              (index % 7 === 0 && index != 0) ||
              index == elements.length - 1
            ) {
              planIndex = planIndex + 1;
              if (index == elements.length - 1) {
                return (
                  <>
                    {item}
                    <Grid item xs={2.5} xl={2.5}></Grid>
                  </>
                );
              }
              return (
                <>
                  <Grid item xs={5} xl={5}>
                    {planIndex < planLabels.products.length ? (
                      <Card></Card>
                    ) : null}
                  </Grid>
                  {item}
                </>
              );
            } else {
              return <>{item}</>;
            }
          })}
        </Grid>
      </>
    );
  }

  function buildPGrid() {
    let planIndex = -1;
    let elements = [];
    planogram.shelves?.forEach((item, index) => {
      elements.push(buildPLane(item, index));
    });
    return (
      <>
        <Grid container spacing={3}>
          {elements.map((item, index) => {
            if (
              (index % 7 === 0 && index != 0) ||
              index == elements.length - 1
            ) {
              planIndex = planIndex + 1;
              if (index == elements.length - 1) {
                return (
                  <>
                    {item}
                    <Grid item xs={5} xl={5}>
                    </Grid>
                  </>
                );
              } else {
              }
              return (
                <>
                  <Grid item xs={5} xl={5}>
                    {planIndex < planogramLabels.products.length ? (
                      <Card>
                      </Card>
                    ) : null}
                  </Grid>
                  {item}
                </>
              );
            } else {
              return <>{item}</>;
            }
          })}
        </Grid>
      </>
    );
  }

  return (
    <>
      <Container>
        <DashboardLayout>
          <DashboardNavbar />
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={3}
                mt={0}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  {machine.number} - {machine.name}
                </MDTypography>
              </MDBox>
              <TextField
                align="center"
                sx={{
                  "margin-top": "10px",
                  "margin-left": "30px",
                  "margin-right": "30px",
                }}
                size="small"
                id="newName"
                variant="outlined"
                placeholder={`Name - ${machine.name}`}
                onChange={(e) => {
                  setNewName(e.target.value);
                }}
              ></TextField>
              <TextField
                sx={{
                  "margin-top": "10px",
                  "margin-left": "30px",
                  "margin-right": "30px",
                }}
                size="small"
                id="newNumber"
                variant="outlined"
                placeholder={`Number - ${machine.number}`}
                onChange={(e) => {
                  setNewNumber(e.target.value);
                }}
              ></TextField>
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  minWidth: 120,
                  "margin-left": "30px",
                  "margin-right": "30px",
                }}
              >
                <InputLabel id="product-label">
                  Region - {machine.region?.name}
                </InputLabel>
                <Select
                  labelId="region-label"
                  id="region-label"
                  label="Region"
                  value={newRegion.name}
                  onChange={(e) => setNewRegion(state.regions[e.target.value])}
                >
                  {state.regions?.map((item, index) => (
                    <MenuItem value={index}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  minWidth: 120,
                  "margin-left": "30px",
                  "margin-right": "30px",
                }}
              >
                <InputLabel id="active-label">Active</InputLabel>
                <Select
                  labelId="active-label"
                  id="active-label"
                  label="Active"
                  value={newActive}
                  onChange={(e) => setNewActive(e.target.value)}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{
                  "margin-top": "10px",
                  "margin-left": "30px",
                  "margin-right": "30px",
                }}
                size="small"
                id="newMerchantNumber"
                variant="outlined"
                placeholder={`Merchant # - ${machine.merchantNum}`}
                onChange={(e) => {
                  setNewMerchantNum(e.target.value);
                }}
              ></TextField>
              <TextField
                sx={{
                  "margin-top": "10px",
                  "margin-left": "30px",
                  "margin-right": "30px",
                }}
                size="small"
                id="newERC"
                variant="outlined"
                placeholder={`ECR ID - ${machine.ecrID}`}
                onChange={(e) => {
                  setNewEcrID(e.target.value);
                }}
              ></TextField>
              <TextField
                sx={{
                  "margin-top": "10px",
                  "margin-left": "30px",
                  "margin-right": "30px",
                }}
                size="small"
                id="newptid"
                variant="outlined"
                placeholder={`PTID - ${machine.ptid}`}
                onChange={(e) => {
                  setNewPTID(e.target.value);
                }}
              ></TextField>
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  minWidth: 120,
                  "margin-left": "30px",
                  "margin-right": "30px",
                }}
              >
                <MDTypography variant="caption">Opens at</MDTypography>
                <TimePicker onChange={setTimeOValue} value={timeOValue} />
              </FormControl>
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  minWidth: 120,
                  "margin-left": "30px",
                  "margin-right": "30px",
                }}
              >
                <MDTypography variant="caption">Closes at</MDTypography>
                <TimePicker onChange={setTimeCValue} value={timeCValue} />
              </FormControl>
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  minWidth: 120,
                  "margin-left": "30px",
                  "margin-right": "30px",
                }}
              >
                <MDTypography variant="caption">Timezone</MDTypography>
                <TimezoneSelect value={timezone} onChange={setTimezone} />
              </FormControl>
              <MDButton
                align="center"
                sx={{
                  "margin-top": "10px",
                  "margin-left": "30px",
                  "margin-right": "30px",
                }}
                color="success"
                variant="contained"
                onClick={callbackFunc_updateMachine}
              >
                Update Machine Info
              </MDButton>
              <MDBox
                mx={2}
                mt={0}
                py={3}
                px={2}
                variant="gradient"
                bgColor="white"
                borderRadius="lg"
              ></MDBox>
            </Card>
          </Grid>

          <MDBox mt={8}>
            {/* Stock Map */}
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h5" color="white">
                    Stock Map
                  </MDTypography>
                  <MDTypography variant="h6" color="grey">
                    Last Updated {plan.requestDateTime}
                  </MDTypography>
                </MDBox>
                <MDBox
                  mx={2}
                  mt={0}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="white"
                  borderRadius="lg"
                >
                  {buildSMGrid()}
                </MDBox>
                <MDBox pt={3}>
                  <DataTable_Generic
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                  />
                </MDBox>
                <MDBox
                  mx={2}
                  mt={0}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                >
                  <MDTypography
                    variant="h6"
                    noWrap={true}
                    align="center"
                    color="white"
                  >
                    {"Total --- " +
                      planLabels.totalMapping +
                      " / " +
                      planLabels.totalMax}
                  </MDTypography>
                </MDBox>
                <MDBox
                  mx={2}
                  mt={0}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="white"
                  borderRadius="lg"
                ></MDBox>
              </Card>
            </Grid>
            <br />
            <br />
            {/* Planogram */}
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="error"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h5" color="white">
                    Planogram
                  </MDTypography>
                </MDBox>
                <MDBox
                  mx={2}
                  mt={0}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="white"
                  borderRadius="lg"
                >
                  {buildPGrid()}
                  <MDBox pt={3}>
                    <DataTable_Warehouses
                      table={{ w_columns, w_rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                    />
                  </MDBox>
                  <MDBox
                    mx={2}
                    mt={0}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                  >
                    <MDTypography
                      variant="h6"
                      noWrap={true}
                      align="center"
                      color="white"
                    >
                      {"Total --- " + planogramLabels.totalMax}
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    mx={2}
                    mt={0}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="white"
                    borderRadius="lg"
                  ></MDBox>
                </MDBox>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <MDButton
                  align="center"
                  sx={{
                    "margin-top": "10px",
                    "margin-left": "30px",
                    "margin-right": "30px",
                  }}
                  color="warning"
                  variant="contained"
                  onClick={openRAPage}
                >
                  Replenishment Analysis
                </MDButton>
              </Card>
            </Grid>
          </MDBox>
          <Footer />
        </DashboardLayout>
      </Container>
    </>
  );
}
