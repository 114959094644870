// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar"
import MDBadge from "../../ui-components/MDBadge"
import MDButton from "../../ui-components/MDButton";
import { useNavigate } from "react-router-dom"

export default function data(sheetData, docIDs, openRunSheetPage) {

  const Author = ({ driver }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {driver}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ stage }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {stage}
      </MDTypography>
    </MDBox>
  );

  let rows = []
  sheetData.map((mach, index) => {
    rows.push({
        author: <Author driver={mach.driver.name} />,
        function: <Job region={mach.stage} description="" />,
        action: (
          <MDButton onClick={(e) => openRunSheetPage(docIDs[index])} size="small">View</MDButton>
        ),
    })
  })

  return {
    columns: [
      { Header: "Driver", accessor: "author", width: "45%", align: "left" },
      { Header: "Stage", accessor: "function", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows
  };
}
