// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar";
import MDBadge from "../../ui-components/MDBadge";
import MDButton from "../../ui-components/MDButton";
import { useNavigate } from "react-router-dom";

export default function data(sheetData, products) {
  const imageStyle = { width: "50px", height: "50px" };

  function matchImages(entry) {
    let x = "";
    products.forEach((item, index) => {
      if (
        item.name == entry.name
      ) {
        x = item.url;
      }
    });
    return x;
  }

  const Author = ({ prod }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <img style={imageStyle} src={matchImages(prod)} />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {prod.name}
        </MDTypography>
        {/* <MDTypography variant="caption">Batch - {prod.batch}</MDTypography> */}
      </MDBox>
    </MDBox>
  );

  const Ordered = ({ text }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {text}
      </MDTypography>
    </MDBox>
  );

  const Picked = ({ text }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {text}
      </MDTypography>
    </MDBox>
  );

  const Stocked = ({ text }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {text}
      </MDTypography>
    </MDBox>
  );

  let rows = [];
  sheetData.orderedProducts.map((product, index) => {
    rows.push({
      author: <Author prod={sheetData.orderedProducts[index]} />,
      ordered: <Ordered text={sheetData.orderedProducts[index].quantity} />,
      picked: <Picked text={sheetData.pickedProducts[index].quantity} />,
      stocked: <Stocked text={sheetData.stockedProducts[index].quantity} />,
    });
  });

  return {
    columns: [
      { Header: "Products", accessor: "author", width: "45%", align: "left" },
      { Header: "Ordered", accessor: "ordered", align: "left" },
      { Header: "Picked", accessor: "picked", align: "left" },
      { Header: "Stocked", accessor: "stocked", align: "left" },
    ],
    rows,
  };
}
