// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar";
import MDBadge from "../../ui-components/MDBadge";
import MDButton from "../../ui-components/MDButton";

export default function data(machines, salesData, products) {
  const Mapping = ({ mapping }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {mapping}
      </MDTypography>
      <MDTypography variant="caption"></MDTypography>
    </MDBox>
  );

  const Product = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption"></MDTypography>
    </MDBox>
  );

  let totalProducts = [];
  let totalProductQuantities = [];
  let totalMachines = [];
  let totalMachineQuantities = [];
  let totalQ = 0
  //function that calculates how much of each product was sold
  salesData.map((machine, index) => {
    machine.products.forEach((thing, tndex) => {
      if (totalProducts.includes(thing) === false) {
        totalProducts.push(thing);
        totalProductQuantities.push(machine.quantities[tndex]);
      } else {
        totalProductQuantities[totalProducts.indexOf(thing)] +=
          machine.quantities[tndex];
      }
    });
  });
  //function that calculates how much each machine sold
  machines.forEach((machine, index) => {
    totalMachines.push(machine.name);
    totalMachineQuantities.push(0);
  });
  salesData.map((machine, index) => {
    machine.quantities.forEach((thing, tndex) => {
      totalMachineQuantities[index] += thing;
      totalQ += thing;
    });
  });
  totalMachines.push({name: "Total"});
  totalMachineQuantities.push(totalQ);

  let rows = [];
  let columns = [
    {
      Header: "Product",
      accessor: "product",
      width: "45%",
      align: "left",
    },
  ];
  products.map((item, pndex) => {
    let a = [];
    rows.push({
      product: <Product title={item} />,
    });
    salesData.map((machine, index) => {
      let y = 0;
      machine.products.forEach((thing, tndex) => {
        if (thing === item) {
          y = y + machine.quantities[tndex];
        }
      });
      a.push(y);
    });
    let total = 0;
    a.forEach((item, index) => {
      if (index != 0) {
        total = total + parseInt(item);
      }
    });
    if (total != 0) {
      a.forEach((entry, index) => {
        rows[pndex][machines[index].name] = <Mapping mapping={entry} />;
      });
    }
    totalProducts.forEach((entry, index) => {
      if (entry == item) {
        rows[pndex]["total"] = <Mapping mapping={totalProductQuantities[index]} />;
      }
    })
  });
  rows.push({
    product: <Product title="Total" />,
  })
  totalMachineQuantities.forEach((entry, index) => {
    rows[rows.length - 1][totalMachines[index]] = <Mapping mapping={entry} />;
  });
  rows[rows.length - 1]["total"] = <Mapping mapping={totalMachineQuantities[totalMachineQuantities.length - 1]} />;

  machines.map((machine, index) => {
    columns.push({
      Header: machine.number + " - " + machine.name,
      accessor: machine.name,
      align: "left",
    });
  });

  columns.push({
    Header: "Total",
    accessor: "total",
    align: "left",
  });

  return {
    columns,
    rows,
  };
}
