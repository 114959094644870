// Material Dashboard 2 React components

import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar";
import MDBadge from "../../ui-components/MDBadge";

export default function data(productData, products) {
  const imageStyle = { width: "50px", height: "50px" };

  const Author = ({ name, url, batch }) => (
    <>
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <img style={imageStyle} src={url} />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
          </MDTypography>
        </MDBox>
      </MDBox>
    </>
  );

  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
    </MDBox>
  );

  function matchImages(entry) {
    let x = "";
    products.forEach((item, index) => {
      if (entry.name == item.name)
        if (
          item.name == entry.name ||
          item.altName1 == entry.name ||
          item.altName2 == entry.name ||
          item.altName3 == entry.name
        ) {
          x = item.url;
        }
    });
    return x;
  }

  let rows = [];
  productData.map((entry, index) => {
    rows.push({
      author: (
        <Author
          name={entry.name}
          url={matchImages(entry)}
          batch={entry.batch}
        />
      ),
      function: <Job title={entry.quantity}/>,
      batch: <Job title={entry.batch}/>,
    });
  });

  return {
    columns: [
      { Header: "Product", accessor: "author", width: "45%", align: "left" },
      { Header: "Batch", accessor: "batch", align: "left" },
      { Header: "Quantity", accessor: "function", width: "25%", align: "left" },
    ],
    rows,
  };
}
