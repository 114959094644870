// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar";
import MDBadge from "../../ui-components/MDBadge";
import MDButton from "../../ui-components/MDButton";

export default function data(woData) {
  const Mapping = ({ mapping }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {mapping}
      </MDTypography>
      <MDTypography variant="caption"></MDTypography>
    </MDBox>
  );

  const Product = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption"></MDTypography>
    </MDBox>
  );

  console.log(woData);

  let totalProducts = [];
  let totalProductQuantities = [];
  let totalMachines = [];
  let totalMachineQuantities = [];
  let totalQ = 0;
  // //function that calculates how much of each product was sold
  woData.map((machine, index) => {
    machine.products.forEach((thing, tndex) => {
      if (totalProducts.includes(thing) === false) {
        totalProducts.push(thing);
        totalProductQuantities.push(machine.quantities[tndex]);
      } else {
        totalProductQuantities[totalProducts.indexOf(thing)] =
          totalProductQuantities[totalProducts.indexOf(thing)] +
          machine.quantities[tndex];
      }
    });
  });
  // //function that calculates how much each machine sold
  woData.forEach((machine, index) => {
    totalMachines.push(machine.location.name);
    totalMachineQuantities.push(0);
  });
  woData.map((machine, index) => {
    machine.quantities.forEach((thing, tndex) => {
      totalMachineQuantities[index] += thing;
      totalQ += thing;
    });
  });
  totalMachines.push({ name: "Total" });
  totalMachineQuantities.push(totalQ);

  let rows = [];
  let columns = [
    {
      Header: "Product",
      accessor: "product",
      width: "45%",
      align: "left",
    },
  ];

  let usedProducts = [];
  let csvData = [];
  csvData.push(["Product"]);
  woData.map((loc, wndex) => {
    csvData[0].push(loc.location.number + " - " + loc.location.name);
    columns.push({
      Header: loc.location.number + " - " + loc.location.name,
      accessor: loc.location.name,
      align: "left",
    });
    loc.products.map((product, index) => {
      if (!usedProducts.includes(product)) {
        csvData.push([product]);
        csvData[csvData.length - 1].push(loc.quantities[index]);
        rows.push({
          product: <Product title={product} />,
        });
        rows[rows.length - 1][loc.location.name] = loc.quantities[index];
        usedProducts.push(product);
      } else {
        csvData.forEach((entry, endex) => {
          if (entry[0] == product) {
            csvData[endex].push(loc.quantities[index]);
          }
        })
        rows[usedProducts.indexOf(product)][loc.location.name] =
          loc.quantities[index];
      }
      totalProducts.forEach((entry, tndex) => {
        if (entry == product) {
          rows[index]["total"] = (
            <Mapping mapping={totalProductQuantities[index]} />
          );
        }
      });
    });
  });
  rows.push({
    product: <Product title="Total" />,
  });
  csvData.push(["Total"]);
  columns.push({
    Header: "Total",
    accessor: "total",
    align: "left",
  });

  totalMachineQuantities.forEach((entry, index) => {
    csvData[csvData.length - 1].push(entry);
    rows[rows.length - 1][totalMachines[index]] = <Mapping mapping={entry} />;
  });
  rows[rows.length - 1]["total"] = (
    <Mapping
      mapping={totalMachineQuantities[totalMachineQuantities.length - 1]}
    />
  );
  return {
    columns,
    rows,
    csvData
  };
}
