// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDBadge from "../../ui-components/MDBadge";
import MDButton from "../../ui-components/MDButton";
import MDInput from "../../ui-components/MDInput";

export default function data(
  productData,
  allProducts,
  handleChangeTransferProduct
) {
  const imageStyle = { width: "50px", height: "50px" };

  const Author = ({ name, url, description }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <img style={imageStyle} src={url} />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{description}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
    </MDBox>
  );

  const CaseNumber = ({ num }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {num}
      </MDTypography>
    </MDBox>
  );

  function matchCaseSizes(entry) {
    let x = "";
    allProducts.forEach((item, index) => {
      if (entry.name == item.name)
        if (
          item.name == entry.name ||
          item.altName1 == entry.name ||
          item.altName2 == entry.name ||
          item.altName3 == entry.name
        ) {
          x =
            Math.round(
              (parseInt(entry.quantity) / parseInt(item.caseSize) +
                Number.EPSILON) *
                100
            ) / 100;
        }
    });
    return x;
  }

  function matchImages(entry) {
    let x = "";
    allProducts.forEach((item, index) => {
      if (entry.name == item.name)
        if (
          item.name == entry.name ||
          item.altName1 == entry.name ||
          item.altName2 == entry.name ||
          item.altName3 == entry.name
        ) {
          x = item.url;
        }
    });
    return x;
  }

  function matchTCSMatCode(entry) {
    let x = "";
    allProducts.forEach((item, index) => {
      if (entry.name == item.name)
        if (
          item.name == entry.name ||
          item.altName1 == entry.name ||
          item.altName2 == entry.name ||
          item.altName3 == entry.name
        ) {
          x = item.tcsMatCode;
        }
    });
    return x;
  }

  let rows = [];
  productData.map((item, index) => {
    rows.push({
      author: <Author name={item.name} url={matchImages(item)} description={matchTCSMatCode(item)}/>,
      function: <Job title={item.quantity} description="" />,
      cases: <CaseNumber num={matchCaseSizes(item)} description="" />,
      status: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item.batch}
        </MDTypography>
      ),
      employed: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item.expiry}
        </MDTypography>
      ),
      action: (
        <MDInput
          label=""
          onChange={(e) => handleChangeTransferProduct(e.target.value, item)}
        ></MDInput>
      ),
    });
  });

  return {
    columns: [
      { Header: "Name", accessor: "author", width: "45%", align: "left" },
      { Header: "Quantity", accessor: "function", align: "left" },
      { Header: "Cases", accessor: "cases", align: "left" },
      { Header: "Batch", accessor: "status", align: "center" },
      { Header: "Expiration Date", accessor: "employed", align: "center" },
      { Header: "Transfer Quantity", accessor: "action", align: "center" },
    ],
    rows,
  };
}
