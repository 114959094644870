import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import DashboardLayout from "../../LayoutContainers/DashboardLayout";
import DashboardNavbar from "../Navbars/DashboardNavbar";
import Footer from "../Footer";
import { useAuth } from "../../contexts/AuthContext";
import HandleLogout from "../HandleLogout";
import MDButton from "../../ui-components/MDButton";
export default function Report_Manager() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const reportTypes = [
    {
      name: "Inventory Position",
      url: "https://firebasestorage.googleapis.com/v0/b/erg-app-dev.appspot.com/o/images%2Freport_images%2F10897.jpg?alt=media&token=25788f9c-8759-41b5-91b0-a30657e3550d",
      linkText: "inventory-position",
    },
    {
      name: "Sales",
      url: "https://firebasestorage.googleapis.com/v0/b/erg-app-dev.appspot.com/o/images%2Freport_images%2Fvecteezy_bag-handbag-shopping-shop-business-logo-template-flat-color_18026709.jpg?alt=media&token=bc772c8c-8244-4ce0-a813-dd4082e6bb58",
      linkText: "sales",
    },
    {
      name: "Write Offs (Machines)",
      url: "  https://firebasestorage.googleapis.com/v0/b/erg-app-dev.appspot.com/o/images%2Freport_images%2Fvecteezy_thumbs-up-and-down-icon-eps-10_10737737.jpg?alt=media&token=2d55454e-41d7-43c9-be1f-54f68e8960a1",
      linkText: "writeoffs",
    },
    {
      name: "Write Offs (Region)",
      url: "  https://firebasestorage.googleapis.com/v0/b/erg-app-dev.appspot.com/o/images%2Freport_images%2Fvecteezy_thumbs-up-and-down-icon-eps-10_10737737.jpg?alt=media&token=2d55454e-41d7-43c9-be1f-54f68e8960a1",
      linkText: "writeoffs-region",
    },
  ];

  useEffect(() => {}, []);

  function openReportPage(e, reportType) {
    e.preventDefault();
    navigate("/reports/" + reportType.linkText);
  }

  function renderReportTypes() {
    return (
      <>
        {reportTypes.map((reportType) => (
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Card>
                <CardHeader subheader={reportType.name} />
                <CardMedia
                  component="img"
                  height="140"
                  image={reportType.url}
                  alt=""
                />
                <CardContent>
                  <Grid item xs={12} md={12} lg={12}>
                    <MDBox mb={0}>
                      <MDButton
                        color="info"
                        variant="contained"
                        fullWidth
                        onClick={(e) => openReportPage(e, reportType)}
                      >
                        <MDTypography
                          display="block"
                          variant="caption"
                          color="white"
                          fontWeight="medium"
                        >
                          View
                        </MDTypography>
                      </MDButton>
                    </MDBox>
                  </Grid>
                </CardContent>
              </Card>
            </MDBox>
          </Grid>
        ))}
      </>
    );
  }

  return (
    <>
      {currentUser ? (
        <Container>
          <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={5}>
              <Grid container spacing={6}>
                {renderReportTypes()}
              </Grid>
            </MDBox>
            <Footer />
          </DashboardLayout>
        </Container>
      ) : (
        <HandleLogout />
      )}
    </>
  );
}
