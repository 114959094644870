// Material Dashboard 2 React components
import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDAvatar from "../../ui-components/MDAvatar";
import MDBadge from "../../ui-components/MDBadge";
import MDButton from "../../ui-components/MDButton";
import { useNavigate } from "react-router-dom";

export default function data(salesData) {

  const Author = ({ number, sale }) => (
    <MDBox display="flex" alignItems="left" lineHeight={1}>
      {/* <MDAvatar name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" color={saleWithin2hours(sale)} variant="button" fontWeight="medium">
          {number}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ region }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {region}
      </MDTypography>
    </MDBox>
  );

  function saleWithin2hours(sale) {
    if (sale != null) {
      return("black")
    } else {
      return("warning")
    }
  }



  let rows = [];
  salesData?.map((mach, index) => {
    if (mach.rawSales.length > 0) {
      rows.push({
        author: <Author number={mach.machine} sale={mach.rawSales[0].dateTime}/>,
        lastSales: <Job region={mach.rawSales[0].dateTime} description="" />,
        function: <Job region={mach.sales} description="" />,
        ySales: <Job region={mach.yesterdaySales} description="" />,
        status:
          mach.status == true ? (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent="active"
                color="success"
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ) : (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent="inactive"
                color="error"
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ),
      });
    } else if (mach.yesterdayRawSales.length > 0) {
      rows.push({
        author: <Author number={mach.machine} />,
        lastSales: (
          <Job region={mach.yesterdayRawSales[0].dateTime} description="" />
        ),
        function: <Job region={mach.sales} description="" />,
        ySales: <Job region={mach.yesterdaySales} description="" />,
        status:
          mach.status == true ? (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent="active"
                color="success"
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ) : (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent="inactive"
                color="error"
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ),
      });
    } else {
      rows.push({
        author: <Author number={mach.machine} />,
        lastSales: (
          <Job region={""} description="" />
        ),
        function: <Job region={mach.sales} description="" />,
        ySales: <Job region={mach.yesterdaySales} description="" />,
        status:
          mach.status == true ? (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent="active"
                color="success"
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ) : (
            <MDBox ml={-1}>
              <MDBadge
                badgeContent="inactive"
                color="error"
                variant="gradient"
                size="sm"
              />
            </MDBox>
          ),
      });
    }
  });

  return {
    columns: [
      { Header: "status", accessor: "status", width: "5%", align: "left" },
      { Header: "Machine", accessor: "author", width: "25%", align: "left" },
      { Header: "Last Sale", accessor: "lastSales", align: "left" },
      { Header: "Sales Today", accessor: "function", align: "left" },
      { Header: "Sales Yesterday", accessor: "ySales", align: "left" },
    ],
    rows,
  };
}
