import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { Grid, Card } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import MDBox from "../ui-components/MDBox";
import MDTypography from "../ui-components/MDTypography";
import MDInput from "../ui-components/MDInput";
import MDButton from "../ui-components/MDButton";

// Authentication layout components
import BasicLayout from "../layouts/BasicLayout";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await login(email.toLowerCase(), password).then((response) => {
        console.log(response)
        navigate("/dashboard");
      });
    } catch (e) {
      console.log(e);
      setError("Failed to sign in");
      alert("Failed to sign in");
    }
    setLoading(false);
  }

  return (
    <>
      <BasicLayout>
        <Grid container>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3} />
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  ERG Vending Suite
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <Form onSubmit={handleSubmit}>
                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      label="Email"
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      label="Password"
                      fullWidth
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      type="submit"
                    >
                      sign in
                    </MDButton>
                  </MDBox>
                </Form>
                <MDBox mt={1} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Forgot your password?{" "}
                    <MDTypography
                      component={Link}
                      to="/forgot-password"
                      variant="button"
                      color="warning"
                      fontWeight="medium"
                      textGradient
                    >
                      Reset Password
                    </MDTypography>
                  </MDTypography>
                </MDBox>
                <MDBox mt={0} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Need an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/signup"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign up
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={3} xl={3} />
        </Grid>
      </BasicLayout>
    </>
  );
}
