// Material Dashboard 2 React components

import MDBox from "../../ui-components/MDBox";
import MDTypography from "../../ui-components/MDTypography";
import MDBadge from "../../ui-components/MDBadge"
import MDButton from "../../ui-components/MDButton";

export default function data(productData, openProductPage) {
  const imageStyle = { width: '50px', height: '50px'}

  const Author = ({ name, url }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
        <img style={imageStyle} src={url} />
        <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
            </MDTypography>
        </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  let rows = []
  productData.map((entry, index) => {
    rows.push({
        author: <Author name={entry.name} url={entry.url} />,
        function: <Job title={entry.bud} description="" />,
        action: (
          <MDButton onClick={(e) => openProductPage(entry.id)} size="small">View</MDButton>
        ),
    })
  })

  return {
    columns: [
      { Header: "Product", accessor: "author", width: "45%", align: "left" },
      { Header: "Bud Code", accessor: "function", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows
  };
}
